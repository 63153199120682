import React, { useContext } from 'react';

const { createContext, useState } = require('react');

export const CheckoutContext = createContext();

export const CheckoutDataProvider = ({ children }) => {
  const [extras, setExtras] = useState([]);
  const clearPreviousExtras = () => setExtras([]);
  return (
    <CheckoutContext.Provider value={{ extras, setExtras, clearPreviousExtras }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const withCheckoutData = Component => {
  const WrappedComponent = props => {
    const { extras, setExtras, clearPreviousExtras } = useContext(CheckoutContext);
    return (
      <Component
        {...props}
        clearPreviousExtras={clearPreviousExtras}
        extras={extras}
        setExtras={setExtras}
      />
    );
  };

  return WrappedComponent;
};
