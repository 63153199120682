import { React, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ExternalLink, ResponsiveImage } from '../../components';
import { formatMoney } from '../../util/currency';
import { createSlug } from '../../util/urlHelpers';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { AiFillStar } from 'react-icons/ai';
import { AiFillInstagram } from 'react-icons/ai';
import { AiFillFacebook } from 'react-icons/ai';
import { BsSnapchat } from 'react-icons/bs';
import { RiLinkedinFill } from 'react-icons/ri';
import { AiOutlineTwitter } from 'react-icons/ai';
import classNames from 'classnames';
import css from './ListingCard.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import CardMap from '../../containers/LandingPage/SectionFeaturedListing/CardMap';
import config from '../../config';
import moment from 'moment';
import Decimal from 'decimal.js';
import NoIGLinkPopup from '../../containers/NoIGLinkPopup/NoIGLinkPopup';


const { LatLng, Money } = sdkTypes;
function ProductCard({
  title,
  id,
  description,
  price,
  images,
  publicData,
  customImage,
  intl,
  authorName = 'Test Name',
  geolocation,
  listing,
  metadata,
  profileMetadata,
}) {
  const [showPopup, setShowPopup] = useState(false);

  // Function to toggle popup
  const togglePopup = () => setShowPopup(!showPopup);

  const history = useHistory();
  const instagram = profileMetadata?.instagram || null;
  const instagramLink = instagram ? 'https://instagram.com/' + String(instagram) : '';
  const { dob, gender, location, name } = publicData;
  const getTitle = title => (title.length > 30 ? title.substring(0, 19) + '...' : title);
  const geoLocation = new LatLng('34.083656', '74.797371');
  const formattedPrice = formatMoney(intl, new Money(1000, config.currency));
  const dateOfBirth = moment(dob, 'YYYY-MM-DD');
  const age = dateOfBirth
    ? parseInt(moment.duration(moment().diff(dateOfBirth)).asYears(), 10)
    : null;

  const handleClick = () => {
    history.push({
      pathname: `/l/${createSlug(title)}/${id}`,
    });
  };

  const stars = metadata?.avgRating;
  const avgRating = stars ? new Decimal(stars).div(100).toNumber() : null;
  return (
    <div className={css.product}>
      <div onClick={handleClick}>
        <div className={classNames(css.productImageWrapper, 'relative')}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={images[0]}
            variants={['default']}
            // customImage={customImage}
          />
        </div>
        <div className={css.container}>
          <div className={css.title}>
            {authorName?.toLowerCase()}, &nbsp;
            {age}
          </div>
        </div>
        <div className={css.genderContainer}>
          <p className={css.gender}>
            {gender?.toLowerCase()}
            &#xb7;
          </p>

          {avgRating && (
            <>
              <span className={css.age}> {avgRating}</span>
              <span>
                <AiFillStar className={css.star} />
              </span>
            </>
          )}
        </div>
        <div className={css.map}>
          <CardMap
            containerClassName={css.mapContainer}
            mapClassName={css.mapContainer}
            center={geolocation}
            circle={true}
            address={location?.address}
          />
        </div>
        <p className={css.text} title={location?.address}>
          Around <span className={css.address}>{getTitle(location?.address)} </span>
        </p>
      </div>
      <div className={css.iconContainerMain}>
        <div className={css.iconContainer}>
          {instagramLink ? (
            <span onClick={togglePopup}>
              <AiFillInstagram className={css.icon} />
            </span>
          ) : null}
          {/* <ExternalLink href="">
            <AiFillFacebook className={css.icon} />
          </ExternalLink>
          <ExternalLink href="">
            <BsSnapchat className={css.icon} />
          </ExternalLink>
          <ExternalLink href="">
            <RiLinkedinFill className={css.icon} />
          </ExternalLink>
          <ExternalLink href="">
            <AiOutlineTwitter className={css.icon} />
          </ExternalLink> */}
        </div>
        <BsFillArrowRightCircleFill className={css.largeIcon} onClick={handleClick} />
      </div>

      {showPopup && <NoIGLinkPopup closePopup={togglePopup} />}

    </div>
  );
}

export default injectIntl(ProductCard);
