import React from 'react';
import css from './NoIGLinkPopup.module.css'; // Update the CSS module import as well

export const NoIGLinkPopup = ({ closePopup }) => (
  <div className={css.popup}>
    <div className={css.popupInner}>
      <h2>Notice</h2>
      <p>
        In order to protect the privacy of our testers, we have removed direct links to Instagram accounts. 
        However, please be assured that this Instagram account was verified by the Loyalty-Test Team. 
        Feel free to ask for or exchange Instagram details after purchasing a package!
      </p>
      <button className={css.closeButton} onClick={closePopup}>Close</button>
    </div>
  </div>
);

export default NoIGLinkPopup;
