import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '../../components';
import css from './SearchResultsPanel.module.css';
import image1 from './testImage/1.png';
import image2 from './testImage/2.png';
import image3 from './testImage/3.png';
import image4 from './testImage/4.png';
import image5 from './testImage/5.png';
import image6 from './testImage/6.png';
const SearchResultsPanel = props => {
  const { className, rootClassName, listings, pagination, search, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const test = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
  ];
  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {listings.map(l => (
          <ListingCard
            // customImage={l}
            // className={css.listingCard}
            id={l?.id?.uuid}
            metadata={l?.attributes?.metadata}
            title={l?.attributes?.title}
            description={l?.attributes?.description}
            publicData={l?.attributes?.publicData}
            images={l?.images}
            geolocation={l?.attributes?.geolocation}
            price={l?.attributes?.price}
            authorName={l?.author?.attributes?.profile?.displayName}
            profileMetadata={l?.author?.attributes?.profile?.metadata}
            key={l?.id?.uuid}
            listing={l}
            // renderSizes={cardRenderSizes}
            // setActiveListing={setActiveListing}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
