export const professions = [
  { label: 'Not Selected', key: '', value: '' },

  { key: 'clergy', value: 'clergy', label: 'Clergy' },
  {
    key: 'english_language_and_literature_teachers_postsecondary',
    value: 'english_language_and_literature_teachers_postsecondary',
    label: 'English Language and Literature Teachers, Postsecondary',
  },
  { key: 'surgeons', value: 'surgeons', label: 'Surgeons' },
  {
    key: 'directors_religious_activities_and_education',
    value: 'directors_religious_activities_and_education',
    label: 'Directors, Religious Activities and Education',
  },
  {
    key: 'education_administrators_elementary_and_secondary_school',
    value: 'education_administrators_elementary_and_secondary_school',
    label: 'Education Administrators, Elementary and Secondary School',
  },
  { key: 'radiation_therapists', value: 'radiation_therapists', label: 'Radiation Therapists' },
  { key: 'chiropractors', value: 'chiropractors', label: 'Chiropractors' },
  { key: 'psychiatrists', value: 'psychiatrists', label: 'Psychiatrists' },
  { key: 'anesthesiologists', value: 'anesthesiologists', label: 'Anesthesiologists' },
  {
    key: 'rehabilitation_counselors',
    value: 'rehabilitation_counselors',
    label: 'Rehabilitation Counselors',
  },
  {
    key: 'occupational_therapists',
    value: 'occupational_therapists',
    label: 'Occupational Therapists',
  },
  {
    key: 'kindergarten_teachers_except_special_education',
    value: 'kindergarten_teachers_except_special_education',
    label: 'Kindergarten Teachers, Except Special Education',
  },
  { key: 'epidemiologists', value: 'epidemiologists', label: 'Epidemiologists' },
  {
    key: 'speech_language_pathologists',
    value: 'speech_language_pathologists',
    label: 'Speech-Language Pathologists',
  },
  { key: 'counselors_all_other', value: 'counselors_all_other', label: 'Counselors, All Other' },
  {
    key: 'family_and_general_practitioners',
    value: 'family_and_general_practitioners',
    label: 'Family and General Practitioners',
  },
  {
    key: 'medical_appliance_technicians',
    value: 'medical_appliance_technicians',
    label: 'Medical Appliance Technicians',
  },
  {
    key: 'first_line_supervisors_managers_of_police_and_detectives',
    value: 'first_line_supervisors_managers_of_police_and_detectives',
    label: 'First-Line Supervisors/Managers of Police and Detectives',
  },
  { key: 'physical_therapists', value: 'physical_therapists', label: 'Physical Therapists' },
  {
    key: 'education_administrators_preschool_and_child_care_center_program',
    value: 'education_administrators_preschool_and_child_care_center_program',
    label: 'Education Administrators, Preschool and Child Care Center/Program',
  },
  {
    key: 'physicians_and_surgeons_all_other',
    value: 'physicians_and_surgeons_all_other',
    label: 'Physicians and Surgeons, All Other',
  },
  {
    key: 'mental_health_counselors',
    value: 'mental_health_counselors',
    label: 'Mental Health Counselors',
  },
  { key: 'pediatricians_general', value: 'pediatricians_general', label: 'Pediatricians, General' },
  {
    key: 'clinical_counseling_and_school_psychologists',
    value: 'clinical_counseling_and_school_psychologists',
    label: 'Clinical, Counseling, and School Psychologists',
  },
  {
    key: 'music_directors_and_composers',
    value: 'music_directors_and_composers',
    label: 'Music Directors and Composers',
  },
  { key: 'fire_fighters', value: 'fire_fighters', label: 'Fire Fighters' },
  { key: 'audiologists', value: 'audiologists', label: 'Audiologists' },
  {
    key: 'health_diagnosing_and_treating_practitioners_all_other',
    value: 'health_diagnosing_and_treating_practitioners_all_other',
    label: 'Health Diagnosing and Treating Practitioners, All Other',
  },
  {
    key: 'water_and_liquid_waste_treatment_plant_and_system_operators',
    value: 'water_and_liquid_waste_treatment_plant_and_system_operators',
    label: 'Water and Liquid Waste Treatment Plant and System Operators',
  },
  { key: 'health_educators', value: 'health_educators', label: 'Health Educators' },
  { key: 'funeral_directors', value: 'funeral_directors', label: 'Funeral Directors' },
  {
    key: 'recreational_therapists',
    value: 'recreational_therapists',
    label: 'Recreational Therapists',
  },
  {
    key: 'occupational_therapist_assistants',
    value: 'occupational_therapist_assistants',
    label: 'Occupational Therapist Assistants',
  },
  {
    key: 'physical_therapist_assistants',
    value: 'physical_therapist_assistants',
    label: 'Physical Therapist Assistants',
  },
  { key: 'dentists_general', value: 'dentists_general', label: 'Dentists, General' },
  { key: 'optometrists', value: 'optometrists', label: 'Optometrists' },
  {
    key: 'marriage_and_family_therapists',
    value: 'marriage_and_family_therapists',
    label: 'Marriage and Family Therapists',
  },
  {
    key:
      'military_enlisted_tactical_operations_and_air_weapons_specialists_and_crew_members_all_other',
    value:
      'military_enlisted_tactical_operations_and_air_weapons_specialists_and_crew_members_all_other',
    label:
      'Military Enlisted Tactical Operations and Air/Weapons Specialists and Crew Members, All Other',
  },
  {
    key: 'physical_therapist_aides',
    value: 'physical_therapist_aides',
    label: 'Physical Therapist Aides',
  },
  {
    key: 'orthotists_and_prosthetists',
    value: 'orthotists_and_prosthetists',
    label: 'Orthotists and Prosthetists',
  },
  { key: 'veterinarians', value: 'veterinarians', label: 'Veterinarians' },
  {
    key: 'fitness_trainers_and_aerobics_instructors',
    value: 'fitness_trainers_and_aerobics_instructors',
    label: 'Fitness Trainers and Aerobics Instructors',
  },
  {
    key: 'police_fire_and_ambulance_dispatchers',
    value: 'police_fire_and_ambulance_dispatchers',
    label: 'Police, Fire, and Ambulance Dispatchers',
  },
  {
    key: 'first_line_supervisors_managers_of_fire_fighting_and_prevention_workers',
    value: 'first_line_supervisors_managers_of_fire_fighting_and_prevention_workers',
    label: 'First-Line Supervisors/Managers of Fire Fighting and Prevention Workers',
  },
  {
    key: 'child_family_and_school_social_workers',
    value: 'child_family_and_school_social_workers',
    label: 'Child, Family, and School Social Workers',
  },
  { key: 'recreation_workers', value: 'recreation_workers', label: 'Recreation Workers' },
  {
    key: 'substance_abuse_and_behavioral_disorder_counselors',
    value: 'substance_abuse_and_behavioral_disorder_counselors',
    label: 'Substance Abuse and Behavioral Disorder Counselors',
  },
  {
    key: 'medical_and_health_services_managers',
    value: 'medical_and_health_services_managers',
    label: 'Medical and Health Services Managers',
  },
  {
    key: 'secondary_school_teachers_except_special_and_vocational_education',
    value: 'secondary_school_teachers_except_special_and_vocational_education',
    label: 'Secondary School Teachers, Except Special and Vocational Education',
  },
  {
    key: 'middle_school_teachers_except_special_and_vocational_education',
    value: 'middle_school_teachers_except_special_and_vocational_education',
    label: 'Middle School Teachers, Except Special and Vocational Education',
  },
  { key: 'internists_general', value: 'internists_general', label: 'Internists, General' },
  {
    key: 'medical_scientists_except_epidemiologists',
    value: 'medical_scientists_except_epidemiologists',
    label: 'Medical Scientists, Except Epidemiologists',
  },
  { key: 'musicians_and_singers', value: 'musicians_and_singers', label: 'Musicians and Singers' },
  {
    key: 'personal_care_and_service_workers_all_other',
    value: 'personal_care_and_service_workers_all_other',
    label: 'Personal Care and Service Workers, All Other',
  },
  { key: 'massage_therapists', value: 'massage_therapists', label: 'Massage Therapists' },
  {
    key: 'surgical_technologists',
    value: 'surgical_technologists',
    label: 'Surgical Technologists',
  },
  { key: 'physician_assistants', value: 'physician_assistants', label: 'Physician Assistants' },
  {
    key: 'diagnostic_medical_sonographers',
    value: 'diagnostic_medical_sonographers',
    label: 'Diagnostic Medical Sonographers',
  },
  { key: 'podiatrists', value: 'podiatrists', label: 'Podiatrists' },
  {
    key: 'postsecondary_teachers_all_other',
    value: 'postsecondary_teachers_all_other',
    label: 'Postsecondary Teachers, All Other',
  },
  {
    key: 'veterinary_technologists_and_technicians',
    value: 'veterinary_technologists_and_technicians',
    label: 'Veterinary Technologists and Technicians',
  },
  {
    key: 'mental_health_and_substance_abuse_social_workers',
    value: 'mental_health_and_substance_abuse_social_workers',
    label: 'Mental Health and Substance Abuse Social Workers',
  },
  { key: 'embalmers', value: 'embalmers', label: 'Embalmers' },
  { key: 'teacher_assistants', value: 'teacher_assistants', label: 'Teacher Assistants' },
  {
    key: 'social_and_community_service_managers',
    value: 'social_and_community_service_managers',
    label: 'Social and Community Service Managers',
  },
  { key: 'biomedical_engineers', value: 'biomedical_engineers', label: 'Biomedical Engineers' },
  {
    key: 'nuclear_medicine_technologists',
    value: 'nuclear_medicine_technologists',
    label: 'Nuclear Medicine Technologists',
  },
  {
    key: 'art_drama_and_music_teachers_postsecondary',
    value: 'art_drama_and_music_teachers_postsecondary',
    label: 'Art, Drama, and Music Teachers, Postsecondary',
  },
  {
    key: 'teachers_and_instructors_all_other',
    value: 'teachers_and_instructors_all_other',
    label: 'Teachers and Instructors, All Other',
  },
  {
    key: 'medical_equipment_preparers',
    value: 'medical_equipment_preparers',
    label: 'Medical Equipment Preparers',
  },
  { key: 'foresters', value: 'foresters', label: 'Foresters' },
  {
    key: 'respiratory_therapists',
    value: 'respiratory_therapists',
    label: 'Respiratory Therapists',
  },
  {
    key: 'medical_and_clinical_laboratory_technologists',
    value: 'medical_and_clinical_laboratory_technologists',
    label: 'Medical and Clinical Laboratory Technologists',
  },
  {
    key: 'personal_and_home_care_aides',
    value: 'personal_and_home_care_aides',
    label: 'Personal and Home Care Aides',
  },
  {
    key: 'police_and_sheriff_s_patrol_officers',
    value: 'police_and_sheriff_s_patrol_officers',
    label: "Police and Sheriff's Patrol Officers",
  },
  {
    key: 'medical_and_public_health_social_workers',
    value: 'medical_and_public_health_social_workers',
    label: 'Medical and Public Health Social Workers',
  },
  {
    key: 'cartographers_and_photogrammetrists',
    value: 'cartographers_and_photogrammetrists',
    label: 'Cartographers and Photogrammetrists',
  },
  {
    key: 'rotary_drill_operators_oil_and_gas',
    value: 'rotary_drill_operators_oil_and_gas',
    label: 'Rotary Drill Operators, Oil and Gas',
  },
  {
    key: 'education_administrators_all_other',
    value: 'education_administrators_all_other',
    label: 'Education Administrators, All Other',
  },
  {
    key: 'health_technologists_and_technicians_all_other',
    value: 'health_technologists_and_technicians_all_other',
    label: 'Health Technologists and Technicians, All Other',
  },
  {
    key: 'educational_vocational_and_school_counselors',
    value: 'educational_vocational_and_school_counselors',
    label: 'Educational, Vocational, and School Counselors',
  },
  { key: 'registered_nurses', value: 'registered_nurses', label: 'Registered Nurses' },
  {
    key: 'emergency_management_specialists',
    value: 'emergency_management_specialists',
    label: 'Emergency Management Specialists',
  },
  {
    key: 'emergency_medical_technicians_and_paramedics',
    value: 'emergency_medical_technicians_and_paramedics',
    label: 'Emergency Medical Technicians and Paramedics',
  },
  {
    key: 'obstetricians_and_gynecologists',
    value: 'obstetricians_and_gynecologists',
    label: 'Obstetricians and Gynecologists',
  },
  {
    key: 'soil_and_plant_scientists',
    value: 'soil_and_plant_scientists',
    label: 'Soil and Plant Scientists',
  },
  { key: 'physicists', value: 'physicists', label: 'Physicists' },
  {
    key: 'nursing_instructors_and_teachers_postsecondary',
    value: 'nursing_instructors_and_teachers_postsecondary',
    label: 'Nursing Instructors and Teachers, Postsecondary',
  },
  {
    key: 'cardiovascular_technologists_and_technicians',
    value: 'cardiovascular_technologists_and_technicians',
    label: 'Cardiovascular Technologists and Technicians',
  },
  {
    key: 'detectives_and_criminal_investigators',
    value: 'detectives_and_criminal_investigators',
    label: 'Detectives and Criminal Investigators',
  },
  {
    key: 'self_enrichment_education_teachers',
    value: 'self_enrichment_education_teachers',
    label: 'Self-Enrichment Education Teachers',
  },
  {
    key: 'licensed_practical_and_licensed_vocational_nurses',
    value: 'licensed_practical_and_licensed_vocational_nurses',
    label: 'Licensed Practical and Licensed Vocational Nurses',
  },
  {
    key: 'dietitians_and_nutritionists',
    value: 'dietitians_and_nutritionists',
    label: 'Dietitians and Nutritionists',
  },
  { key: 'dental_hygienists', value: 'dental_hygienists', label: 'Dental Hygienists' },
  { key: 'residential_advisors', value: 'residential_advisors', label: 'Residential Advisors' },
  { key: 'pharmacists', value: 'pharmacists', label: 'Pharmacists' },
  { key: 'home_health_aides', value: 'home_health_aides', label: 'Home Health Aides' },
  { key: 'athletic_trainers', value: 'athletic_trainers', label: 'Athletic Trainers' },
  {
    key: 'radiologic_technologists_and_technicians',
    value: 'radiologic_technologists_and_technicians',
    label: 'Radiologic Technologists and Technicians',
  },
  { key: 'librarians', value: 'librarians', label: 'Librarians' },
  {
    key: 'natural_sciences_managers',
    value: 'natural_sciences_managers',
    label: 'Natural Sciences Managers',
  },
  {
    key: 'psychiatric_technicians',
    value: 'psychiatric_technicians',
    label: 'Psychiatric Technicians',
  },
  {
    key: 'education_administrators_postsecondary',
    value: 'education_administrators_postsecondary',
    label: 'Education Administrators, Postsecondary',
  },
  {
    key: 'graduate_teaching_assistants',
    value: 'graduate_teaching_assistants',
    label: 'Graduate Teaching Assistants',
  },
  {
    key: 'probation_officers_and_correctional_treatment_specialists',
    value: 'probation_officers_and_correctional_treatment_specialists',
    label: 'Probation Officers and Correctional Treatment Specialists',
  },
  {
    key: 'security_and_fire_alarm_systems_installers',
    value: 'security_and_fire_alarm_systems_installers',
    label: 'Security and Fire Alarm Systems Installers',
  },
  { key: 'sociologists', value: 'sociologists', label: 'Sociologists' },
  {
    key: 'occupational_health_and_safety_technicians',
    value: 'occupational_health_and_safety_technicians',
    label: 'Occupational Health and Safety Technicians',
  },
  {
    key: 'healthcare_practitioners_and_technical_workers_all_other',
    value: 'healthcare_practitioners_and_technical_workers_all_other',
    label: 'Healthcare Practitioners and Technical Workers, All Other',
  },
  {
    key: 'healthcare_support_workers_all_other',
    value: 'healthcare_support_workers_all_other',
    label: 'Healthcare Support Workers, All Other',
  },
  { key: 'medical_assistants', value: 'medical_assistants', label: 'Medical Assistants' },
  {
    key: 'interpreters_and_translators',
    value: 'interpreters_and_translators',
    label: 'Interpreters and Translators',
  },
  {
    key: 'medical_and_clinical_laboratory_technicians',
    value: 'medical_and_clinical_laboratory_technicians',
    label: 'Medical and Clinical Laboratory Technicians',
  },
  {
    key: 'health_and_safety_engineers_except_mining_safety_engineers_and_inspectors',
    value: 'health_and_safety_engineers_except_mining_safety_engineers_and_inspectors',
    label: 'Health and Safety Engineers, Except Mining Safety Engineers and Inspectors',
  },
  {
    key: 'medical_equipment_repairers',
    value: 'medical_equipment_repairers',
    label: 'Medical Equipment Repairers',
  },
  {
    key: 'nursing_aides_orderlies_and_attendants',
    value: 'nursing_aides_orderlies_and_attendants',
    label: 'Nursing Aides, Orderlies, and Attendants',
  },
  {
    key: 'zoologists_and_wildlife_biologists',
    value: 'zoologists_and_wildlife_biologists',
    label: 'Zoologists and Wildlife Biologists',
  },
  {
    key: 'farm_ranch_and_other_agricultural_managers',
    value: 'farm_ranch_and_other_agricultural_managers',
    label: 'Farm, Ranch, and Other Agricultural Managers',
  },
  { key: 'chief_executives', value: 'chief_executives', label: 'Chief Executives' },
  {
    key: 'helpers_pipelayers_plumbers_pipefitters_and_steamfitters',
    value: 'helpers_pipelayers_plumbers_pipefitters_and_steamfitters',
    label: 'Helpers--Pipelayers, Plumbers, Pipefitters, and Steamfitters',
  },
  {
    key: 'social_workers_all_other',
    value: 'social_workers_all_other',
    label: 'Social Workers, All Other',
  },
  {
    key: 'eligibility_interviewers_government_programs',
    value: 'eligibility_interviewers_government_programs',
    label: 'Eligibility Interviewers, Government Programs',
  },
  {
    key: 'vocational_education_teachers_postsecondary',
    value: 'vocational_education_teachers_postsecondary',
    label: 'Vocational Education Teachers, Postsecondary',
  },
  { key: 'dental_assistants', value: 'dental_assistants', label: 'Dental Assistants' },
  {
    key: 'plant_and_system_operators_all_other',
    value: 'plant_and_system_operators_all_other',
    label: 'Plant and System Operators, All Other',
  },
  {
    key: 'occupational_health_and_safety_specialists',
    value: 'occupational_health_and_safety_specialists',
    label: 'Occupational Health and Safety Specialists',
  },
  {
    key: 'preschool_teachers_except_special_education',
    value: 'preschool_teachers_except_special_education',
    label: 'Preschool Teachers, Except Special Education',
  },
  {
    key: 'forensic_science_technicians',
    value: 'forensic_science_technicians',
    label: 'Forensic Science Technicians',
  },
  { key: 'opticians_dispensing', value: 'opticians_dispensing', label: 'Opticians, Dispensing' },
  {
    key: 'nuclear_power_reactor_operators',
    value: 'nuclear_power_reactor_operators',
    label: 'Nuclear Power Reactor Operators',
  },
  {
    key: 'electrical_and_electronics_repairers_commercial_and_industrial_equipment',
    value: 'electrical_and_electronics_repairers_commercial_and_industrial_equipment',
    label: 'Electrical and Electronics Repairers, Commercial and Industrial Equipment',
  },
  {
    key: 'adult_literacy_remedial_education_and_ged_teachers_and_instructors',
    value: 'adult_literacy_remedial_education_and_ged_teachers_and_instructors',
    label: 'Adult Literacy, Remedial Education, and GED Teachers and Instructors',
  },
  { key: 'landscape_architects', value: 'landscape_architects', label: 'Landscape Architects' },
  {
    key: 'farmworkers_and_laborers_crop_nursery_and_greenhouse',
    value: 'farmworkers_and_laborers_crop_nursery_and_greenhouse',
    label: 'Farmworkers and Laborers, Crop, Nursery, and Greenhouse',
  },
  { key: 'medical_secretaries', value: 'medical_secretaries', label: 'Medical Secretaries' },
  {
    key: 'nonfarm_animal_caretakers',
    value: 'nonfarm_animal_caretakers',
    label: 'Nonfarm Animal Caretakers',
  },
  {
    key: 'environmental_engineers',
    value: 'environmental_engineers',
    label: 'Environmental Engineers',
  },
  { key: 'skin_care_specialists', value: 'skin_care_specialists', label: 'Skin Care Specialists' },
  { key: 'avionics_technicians', value: 'avionics_technicians', label: 'Avionics Technicians' },
  { key: 'microbiologists', value: 'microbiologists', label: 'Microbiologists' },
  {
    key: 'arbitrators_mediators_and_conciliators',
    value: 'arbitrators_mediators_and_conciliators',
    label: 'Arbitrators, Mediators, and Conciliators',
  },
  { key: 'statisticians', value: 'statisticians', label: 'Statisticians' },
  { key: 'power_plant_operators', value: 'power_plant_operators', label: 'Power Plant Operators' },
  { key: 'real_estate_brokers', value: 'real_estate_brokers', label: 'Real Estate Brokers' },
  {
    key: 'electrical_power_line_installers_and_repairers',
    value: 'electrical_power_line_installers_and_repairers',
    label: 'Electrical Power-Line Installers and Repairers',
  },
  {
    key: 'urban_and_regional_planners',
    value: 'urban_and_regional_planners',
    label: 'Urban and Regional Planners',
  },
  { key: 'coaches_and_scouts', value: 'coaches_and_scouts', label: 'Coaches and Scouts' },
  { key: 'pharmacy_technicians', value: 'pharmacy_technicians', label: 'Pharmacy Technicians' },
  { key: 'gas_plant_operators', value: 'gas_plant_operators', label: 'Gas Plant Operators' },
  { key: 'civil_engineers', value: 'civil_engineers', label: 'Civil Engineers' },
  {
    key: 'biological_technicians',
    value: 'biological_technicians',
    label: 'Biological Technicians',
  },
  {
    key: 'construction_and_building_inspectors',
    value: 'construction_and_building_inspectors',
    label: 'Construction and Building Inspectors',
  },
  { key: 'nuclear_engineers', value: 'nuclear_engineers', label: 'Nuclear Engineers' },
  {
    key: 'captains_mates_and_pilots_of_water_vessels',
    value: 'captains_mates_and_pilots_of_water_vessels',
    label: 'Captains, Mates, and Pilots of Water Vessels',
  },
  { key: 'actors', value: 'actors', label: 'Actors' },
  { key: 'petroleum_engineers', value: 'petroleum_engineers', label: 'Petroleum Engineers' },
  {
    key: 'instructional_coordinators',
    value: 'instructional_coordinators',
    label: 'Instructional Coordinators',
  },
  {
    key: 'first_line_supervisors_managers_of_correctional_officers',
    value: 'first_line_supervisors_managers_of_correctional_officers',
    label: 'First-Line Supervisors/Managers of Correctional Officers',
  },
  {
    key: 'aircraft_mechanics_and_service_technicians',
    value: 'aircraft_mechanics_and_service_technicians',
    label: 'Aircraft Mechanics and Service Technicians',
  },
  {
    key: 'helpers_installation_maintenance_and_repair_workers',
    value: 'helpers_installation_maintenance_and_repair_workers',
    label: 'Helpers--Installation, Maintenance, and Repair Workers',
  },
  { key: 'commercial_pilots', value: 'commercial_pilots', label: 'Commercial Pilots' },
  {
    key: 'public_relations_managers',
    value: 'public_relations_managers',
    label: 'Public Relations Managers',
  },
  {
    key: 'first_line_supervisors_managers_of_construction_trades_and_extraction_workers',
    value: 'first_line_supervisors_managers_of_construction_trades_and_extraction_workers',
    label: 'First-Line Supervisors/Managers of Construction Trades and Extraction Workers',
  },
  {
    key: 'environmental_scientists_and_specialists_including_health',
    value: 'environmental_scientists_and_specialists_including_health',
    label: 'Environmental Scientists and Specialists, Including Health',
  },
  {
    key: 'mechanical_engineering_technicians',
    value: 'mechanical_engineering_technicians',
    label: 'Mechanical Engineering Technicians',
  },
  {
    key: 'administrative_services_managers',
    value: 'administrative_services_managers',
    label: 'Administrative Services Managers',
  },
  { key: 'bus_drivers_school', value: 'bus_drivers_school', label: 'Bus Drivers, School' },
  {
    key: 'dental_laboratory_technicians',
    value: 'dental_laboratory_technicians',
    label: 'Dental Laboratory Technicians',
  },
  {
    key: 'interviewers_except_eligibility_and_loan',
    value: 'interviewers_except_eligibility_and_loan',
    label: 'Interviewers, Except Eligibility and Loan',
  },
  {
    key: 'biological_scientists_all_other',
    value: 'biological_scientists_all_other',
    label: 'Biological Scientists, All Other',
  },
  { key: 'aerospace_engineers', value: 'aerospace_engineers', label: 'Aerospace Engineers' },
  { key: 'pest_control_workers', value: 'pest_control_workers', label: 'Pest Control Workers' },
  {
    key: 'heating_air_conditioning_and_refrigeration_mechanics_and_installers',
    value: 'heating_air_conditioning_and_refrigeration_mechanics_and_installers',
    label: 'Heating, Air Conditioning, and Refrigeration Mechanics and Installers',
  },
  {
    key: 'postal_service_mail_carriers',
    value: 'postal_service_mail_carriers',
    label: 'Postal Service Mail Carriers',
  },
  {
    key: 'petroleum_pump_system_operators_refinery_operators_and_gaugers',
    value: 'petroleum_pump_system_operators_refinery_operators_and_gaugers',
    label: 'Petroleum Pump System Operators, Refinery Operators, and Gaugers',
  },
  {
    key: 'plumbers_pipefitters_and_steamfitters',
    value: 'plumbers_pipefitters_and_steamfitters',
    label: 'Plumbers, Pipefitters, and Steamfitters',
  },
  {
    key: 'first_line_supervisors_managers_of_housekeeping_and_janitorial_workers',
    value: 'first_line_supervisors_managers_of_housekeeping_and_janitorial_workers',
    label: 'First-Line Supervisors/Managers of Housekeeping and Janitorial Workers',
  },
  {
    key: 'civil_engineering_technicians',
    value: 'civil_engineering_technicians',
    label: 'Civil Engineering Technicians',
  },
  { key: 'historians', value: 'historians', label: 'Historians' },
  {
    key: 'compliance_officers_except_agriculture_construction_health_and_safety_and_transportation',
    value:
      'compliance_officers_except_agriculture_construction_health_and_safety_and_transportation',
    label:
      'Compliance Officers, Except Agriculture, Construction, Health and Safety, and Transportation',
  },
  {
    key: 'atmospheric_and_space_scientists',
    value: 'atmospheric_and_space_scientists',
    label: 'Atmospheric and Space Scientists',
  },
  {
    key: 'locksmiths_and_safe_repairers',
    value: 'locksmiths_and_safe_repairers',
    label: 'Locksmiths and Safe Repairers',
  },
  {
    key: 'ophthalmic_laboratory_technicians',
    value: 'ophthalmic_laboratory_technicians',
    label: 'Ophthalmic Laboratory Technicians',
  },
  {
    key: 'landscaping_and_groundskeeping_workers',
    value: 'landscaping_and_groundskeeping_workers',
    label: 'Landscaping and Groundskeeping Workers',
  },
  {
    key: 'electronics_engineers_except_computer',
    value: 'electronics_engineers_except_computer',
    label: 'Electronics Engineers, Except Computer',
  },
  { key: 'helpers_electricians', value: 'helpers_electricians', label: 'Helpers--Electricians' },
  { key: 'engineering_managers', value: 'engineering_managers', label: 'Engineering Managers' },
  {
    key: 'lifeguards_ski_patrol_and_other_recreational_protective_service_workers',
    value: 'lifeguards_ski_patrol_and_other_recreational_protective_service_workers',
    label: 'Lifeguards, Ski Patrol, and Other Recreational Protective Service Workers',
  },
  {
    key: 'biochemists_and_biophysicists',
    value: 'biochemists_and_biophysicists',
    label: 'Biochemists and Biophysicists',
  },
  {
    key: 'cement_masons_and_concrete_finishers',
    value: 'cement_masons_and_concrete_finishers',
    label: 'Cement Masons and Concrete Finishers',
  },
  { key: 'materials_scientists', value: 'materials_scientists', label: 'Materials Scientists' },
  {
    key: 'correctional_officers_and_jailers',
    value: 'correctional_officers_and_jailers',
    label: 'Correctional Officers and Jailers',
  },
  {
    key: 'social_and_human_service_assistants',
    value: 'social_and_human_service_assistants',
    label: 'Social and Human Service Assistants',
  },
  { key: 'loan_counselors', value: 'loan_counselors', label: 'Loan Counselors' },
  {
    key: 'human_resources_managers_all_other',
    value: 'human_resources_managers_all_other',
    label: 'Human Resources Managers, All Other',
  },
  { key: 'floral_designers', value: 'floral_designers', label: 'Floral Designers' },
  { key: 'chemical_engineers', value: 'chemical_engineers', label: 'Chemical Engineers' },
  {
    key:
      'separating_filtering_clarifying_precipitating_and_still_machine_setters_operators_and_tenders',
    value:
      'separating_filtering_clarifying_precipitating_and_still_machine_setters_operators_and_tenders',
    label:
      'Separating, Filtering, Clarifying, Precipitating, and Still Machine Setters, Operators, and Tenders',
  },
  {
    key: 'employment_recruitment_and_placement_specialists',
    value: 'employment_recruitment_and_placement_specialists',
    label: 'Employment, Recruitment, and Placement Specialists',
  },
  {
    key: 'private_detectives_and_investigators',
    value: 'private_detectives_and_investigators',
    label: 'Private Detectives and Investigators',
  },
  {
    key: 'training_and_development_managers',
    value: 'training_and_development_managers',
    label: 'Training and Development Managers',
  },
  { key: 'curators', value: 'curators', label: 'Curators' },
  {
    key: 'life_physical_and_social_science_technicians_all_other',
    value: 'life_physical_and_social_science_technicians_all_other',
    label: 'Life, Physical, and Social Science Technicians, All Other',
  },
  {
    key: 'environmental_science_and_protection_technicians_including_health',
    value: 'environmental_science_and_protection_technicians_including_health',
    label: 'Environmental Science and Protection Technicians, Including Health',
  },
  {
    key: 'farmworkers_farm_and_ranch_animals',
    value: 'farmworkers_farm_and_ranch_animals',
    label: 'Farmworkers, Farm and Ranch Animals',
  },
  { key: 'child_care_workers', value: 'child_care_workers', label: 'Child Care Workers' },
  {
    key: 'library_assistants_clerical',
    value: 'library_assistants_clerical',
    label: 'Library Assistants, Clerical',
  },
  {
    key: 'tree_trimmers_and_pruners',
    value: 'tree_trimmers_and_pruners',
    label: 'Tree Trimmers and Pruners',
  },
  { key: 'dietetic_technicians', value: 'dietetic_technicians', label: 'Dietetic Technicians' },
  {
    key: 'first_line_supervisors_managers_of_personal_service_workers',
    value: 'first_line_supervisors_managers_of_personal_service_workers',
    label: 'First-Line Supervisors/Managers of Personal Service Workers',
  },
  {
    key: 'engineering_technicians_except_drafters_all_other',
    value: 'engineering_technicians_except_drafters_all_other',
    label: 'Engineering Technicians, Except Drafters, All Other',
  },
  {
    key: 'telecommunications_line_installers_and_repairers',
    value: 'telecommunications_line_installers_and_repairers',
    label: 'Telecommunications Line Installers and Repairers',
  },
  {
    key: 'cooks_institution_and_cafeteria',
    value: 'cooks_institution_and_cafeteria',
    label: 'Cooks, Institution and Cafeteria',
  },
  {
    key: 'air_traffic_controllers',
    value: 'air_traffic_controllers',
    label: 'Air Traffic Controllers',
  },
  {
    key: 'medical_records_and_health_information_technicians',
    value: 'medical_records_and_health_information_technicians',
    label: 'Medical Records and Health Information Technicians',
  },
  {
    key: 'hairdressers_hairstylists_and_cosmetologists',
    value: 'hairdressers_hairstylists_and_cosmetologists',
    label: 'Hairdressers, Hairstylists, and Cosmetologists',
  },
  { key: 'carpenters', value: 'carpenters', label: 'Carpenters' },
  { key: 'electricians', value: 'electricians', label: 'Electricians' },
  { key: 'electrical_engineers', value: 'electrical_engineers', label: 'Electrical Engineers' },
  {
    key: 'stationary_engineers_and_boiler_operators',
    value: 'stationary_engineers_and_boiler_operators',
    label: 'Stationary Engineers and Boiler Operators',
  },
  {
    key: 'personal_financial_advisors',
    value: 'personal_financial_advisors',
    label: 'Personal Financial Advisors',
  },
  { key: 'construction_managers', value: 'construction_managers', label: 'Construction Managers' },
  {
    key: 'medical_transcriptionists',
    value: 'medical_transcriptionists',
    label: 'Medical Transcriptionists',
  },
  {
    key: 'general_and_operations_managers',
    value: 'general_and_operations_managers',
    label: 'General and Operations Managers',
  },
  {
    key: 'first_line_supervisors_managers_of_mechanics_installers_and_repairers',
    value: 'first_line_supervisors_managers_of_mechanics_installers_and_repairers',
    label: 'First-Line Supervisors/Managers of Mechanics, Installers, and Repairers',
  },
  {
    key: 'office_and_administrative_support_workers_all_other',
    value: 'office_and_administrative_support_workers_all_other',
    label: 'Office and Administrative Support Workers, All Other',
  },
  {
    key: 'brickmasons_and_blockmasons',
    value: 'brickmasons_and_blockmasons',
    label: 'Brickmasons and Blockmasons',
  },
  { key: 'carpet_installers', value: 'carpet_installers', label: 'Carpet Installers' },
  { key: 'hydrologists', value: 'hydrologists', label: 'Hydrologists' },
  {
    key: 'secretaries_except_legal_medical_and_executive',
    value: 'secretaries_except_legal_medical_and_executive',
    label: 'Secretaries, Except Legal, Medical, and Executive',
  },
  {
    key: 'operating_engineers_and_other_construction_equipment_operators',
    value: 'operating_engineers_and_other_construction_equipment_operators',
    label: 'Operating Engineers and Other Construction Equipment Operators',
  },
  {
    key: 'janitors_and_cleaners_except_maids_and_housekeeping_cleaners',
    value: 'janitors_and_cleaners_except_maids_and_housekeeping_cleaners',
    label: 'Janitors and Cleaners, Except Maids and Housekeeping Cleaners',
  },
  { key: 'budget_analysts', value: 'budget_analysts', label: 'Budget Analysts' },
  {
    key: 'crane_and_tower_operators',
    value: 'crane_and_tower_operators',
    label: 'Crane and Tower Operators',
  },
  {
    key: 'architects_except_landscape_and_naval',
    value: 'architects_except_landscape_and_naval',
    label: 'Architects, Except Landscape and Naval',
  },
  {
    key: 'training_and_development_specialists',
    value: 'training_and_development_specialists',
    label: 'Training and Development Specialists',
  },
  {
    key: 'couriers_and_messengers',
    value: 'couriers_and_messengers',
    label: 'Couriers and Messengers',
  },
  {
    key: 'switchboard_operators_including_answering_service',
    value: 'switchboard_operators_including_answering_service',
    label: 'Switchboard Operators, Including Answering Service',
  },
  {
    key: 'electro_mechanical_technicians',
    value: 'electro_mechanical_technicians',
    label: 'Electro-Mechanical Technicians',
  },
  {
    key: 'pesticide_handlers_sprayers_and_applicators_vegetation',
    value: 'pesticide_handlers_sprayers_and_applicators_vegetation',
    label: 'Pesticide Handlers, Sprayers, and Applicators, Vegetation',
  },
  {
    key: 'executive_secretaries_and_administrative_assistants',
    value: 'executive_secretaries_and_administrative_assistants',
    label: 'Executive Secretaries and Administrative Assistants',
  },
  {
    key: 'court_municipal_and_license_clerks',
    value: 'court_municipal_and_license_clerks',
    label: 'Court, Municipal, and License Clerks',
  },
  {
    key: 'telecommunications_equipment_installers_and_repairers_except_line_installers',
    value: 'telecommunications_equipment_installers_and_repairers_except_line_installers',
    label: 'Telecommunications Equipment Installers and Repairers, Except Line Installers',
  },
  {
    key: 'taxi_drivers_and_chauffeurs',
    value: 'taxi_drivers_and_chauffeurs',
    label: 'Taxi Drivers and Chauffeurs',
  },
  {
    key: 'reporters_and_correspondents',
    value: 'reporters_and_correspondents',
    label: 'Reporters and Correspondents',
  },
  { key: 'managers_all_other', value: 'managers_all_other', label: 'Managers, All Other' },
  {
    key: 'first_line_supervisors_managers_of_farming_fishing_and_forestry_workers',
    value: 'first_line_supervisors_managers_of_farming_fishing_and_forestry_workers',
    label: 'First-Line Supervisors/Managers of Farming, Fishing, and Forestry Workers',
  },
  {
    key: 'geoscientists_except_hydrologists_and_geographers',
    value: 'geoscientists_except_hydrologists_and_geographers',
    label: 'Geoscientists, Except Hydrologists and Geographers',
  },
  { key: 'surveyors', value: 'surveyors', label: 'Surveyors' },
  {
    key: 'compensation_and_benefits_managers',
    value: 'compensation_and_benefits_managers',
    label: 'Compensation and Benefits Managers',
  },
  {
    key: 'set_and_exhibit_designers',
    value: 'set_and_exhibit_designers',
    label: 'Set and Exhibit Designers',
  },
  {
    key: 'maids_and_housekeeping_cleaners',
    value: 'maids_and_housekeeping_cleaners',
    label: 'Maids and Housekeeping Cleaners',
  },
  {
    key: 'makeup_artists_theatrical_and_performance',
    value: 'makeup_artists_theatrical_and_performance',
    label: 'Makeup Artists, Theatrical and Performance',
  },
  {
    key: 'amusement_and_recreation_attendants',
    value: 'amusement_and_recreation_attendants',
    label: 'Amusement and Recreation Attendants',
  },
  {
    key: 'broadcast_news_analysts',
    value: 'broadcast_news_analysts',
    label: 'Broadcast News Analysts',
  },
  {
    key: 'inspectors_testers_sorters_samplers_and_weighers',
    value: 'inspectors_testers_sorters_samplers_and_weighers',
    label: 'Inspectors, Testers, Sorters, Samplers, and Weighers',
  },
  {
    key: 'first_line_supervisors_managers_of_office_and_administrative_support_workers',
    value: 'first_line_supervisors_managers_of_office_and_administrative_support_workers',
    label: 'First-Line Supervisors/Managers of Office and Administrative Support Workers',
  },
  { key: 'library_technicians', value: 'library_technicians', label: 'Library Technicians' },
  { key: 'interior_designers', value: 'interior_designers', label: 'Interior Designers' },
  {
    key: 'installation_maintenance_and_repair_workers_all_other',
    value: 'installation_maintenance_and_repair_workers_all_other',
    label: 'Installation, Maintenance, and Repair Workers, All Other',
  },
  {
    key: 'payroll_and_timekeeping_clerks',
    value: 'payroll_and_timekeeping_clerks',
    label: 'Payroll and Timekeeping Clerks',
  },
  {
    key: 'production_workers_all_other',
    value: 'production_workers_all_other',
    label: 'Production Workers, All Other',
  },
  { key: 'economists', value: 'economists', label: 'Economists' },
  {
    key: 'receptionists_and_information_clerks',
    value: 'receptionists_and_information_clerks',
    label: 'Receptionists and Information Clerks',
  },
  {
    key: 'computer_hardware_engineers',
    value: 'computer_hardware_engineers',
    label: 'Computer Hardware Engineers',
  },
  {
    key: 'drilling_and_boring_machine_tool_setters_operators_and_tenders_metal_and_plastic',
    value: 'drilling_and_boring_machine_tool_setters_operators_and_tenders_metal_and_plastic',
    label: 'Drilling and Boring Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
  },
  {
    key: 'network_systems_and_data_communications_analysts',
    value: 'network_systems_and_data_communications_analysts',
    label: 'Network Systems and Data Communications Analysts',
  },
  { key: 'loan_officers', value: 'loan_officers', label: 'Loan Officers' },
  { key: 'mechanical_drafters', value: 'mechanical_drafters', label: 'Mechanical Drafters' },
  { key: 'mechanical_engineers', value: 'mechanical_engineers', label: 'Mechanical Engineers' },
  {
    key: 'audio_and_video_equipment_technicians',
    value: 'audio_and_video_equipment_technicians',
    label: 'Audio and Video Equipment Technicians',
  },
  {
    key: 'marine_engineers_and_naval_architects',
    value: 'marine_engineers_and_naval_architects',
    label: 'Marine Engineers and Naval Architects',
  },
  {
    key: 'human_resources_assistants_except_payroll_and_timekeeping',
    value: 'human_resources_assistants_except_payroll_and_timekeeping',
    label: 'Human Resources Assistants, Except Payroll and Timekeeping',
  },
  {
    key: 'airline_pilots_copilots_and_flight_engineers',
    value: 'airline_pilots_copilots_and_flight_engineers',
    label: 'Airline Pilots, Copilots, and Flight Engineers',
  },
  { key: 'engineers_all_other', value: 'engineers_all_other', label: 'Engineers, All Other' },
  {
    key: 'protective_service_workers_all_other',
    value: 'protective_service_workers_all_other',
    label: 'Protective Service Workers, All Other',
  },
  { key: 'upholsterers', value: 'upholsterers', label: 'Upholsterers' },
  {
    key: 'financial_specialists_all_other',
    value: 'financial_specialists_all_other',
    label: 'Financial Specialists, All Other',
  },
  {
    key: 'first_line_supervisors_managers_of_landscaping_lawn_service_and_groundskeeping_workers',
    value: 'first_line_supervisors_managers_of_landscaping_lawn_service_and_groundskeeping_workers',
    label:
      'First-Line Supervisors/Managers of Landscaping, Lawn Service, and Groundskeeping Workers',
  },
  { key: 'furniture_finishers', value: 'furniture_finishers', label: 'Furniture Finishers' },
  {
    key: 'food_and_tobacco_roasting_baking_and_drying_machine_operators_and_tenders',
    value: 'food_and_tobacco_roasting_baking_and_drying_machine_operators_and_tenders',
    label: 'Food and Tobacco Roasting, Baking, and Drying Machine Operators and Tenders',
  },
  {
    key: 'food_scientists_and_technologists',
    value: 'food_scientists_and_technologists',
    label: 'Food Scientists and Technologists',
  },
  { key: 'sheet_metal_workers', value: 'sheet_metal_workers', label: 'Sheet Metal Workers' },
  {
    key: 'engine_and_other_machine_assemblers',
    value: 'engine_and_other_machine_assemblers',
    label: 'Engine and Other Machine Assemblers',
  },
  {
    key: 'bus_drivers_transit_and_intercity',
    value: 'bus_drivers_transit_and_intercity',
    label: 'Bus Drivers, Transit and Intercity',
  },
  { key: 'financial_examiners', value: 'financial_examiners', label: 'Financial Examiners' },
  {
    key: 'electrical_and_electronic_engineering_technicians',
    value: 'electrical_and_electronic_engineering_technicians',
    label: 'Electrical and Electronic Engineering Technicians',
  },
  {
    key: 'industrial_production_managers',
    value: 'industrial_production_managers',
    label: 'Industrial Production Managers',
  },
  {
    key: 'airfield_operations_specialists',
    value: 'airfield_operations_specialists',
    label: 'Airfield Operations Specialists',
  },
  { key: 'lodging_managers', value: 'lodging_managers', label: 'Lodging Managers' },
  { key: 'office_clerks_general', value: 'office_clerks_general', label: 'Office Clerks, General' },
  {
    key: 'public_relations_specialists',
    value: 'public_relations_specialists',
    label: 'Public Relations Specialists',
  },
  { key: 'law_clerks', value: 'law_clerks', label: 'Law Clerks' },
  {
    key: 'refuse_and_recyclable_material_collectors',
    value: 'refuse_and_recyclable_material_collectors',
    label: 'Refuse and Recyclable Material Collectors',
  },
  {
    key: 'truck_drivers_heavy_and_tractor_trailer',
    value: 'truck_drivers_heavy_and_tractor_trailer',
    label: 'Truck Drivers, Heavy and Tractor-Trailer',
  },
  {
    key: 'anthropologists_and_archeologists',
    value: 'anthropologists_and_archeologists',
    label: 'Anthropologists and Archeologists',
  },
  { key: 'glaziers', value: 'glaziers', label: 'Glaziers' },
  { key: 'archivists', value: 'archivists', label: 'Archivists' },
  {
    key: 'fine_artists_including_painters_sculptors_and_illustrators',
    value: 'fine_artists_including_painters_sculptors_and_illustrators',
    label: 'Fine Artists, Including Painters, Sculptors, and Illustrators',
  },
  {
    key: 'maintenance_workers_machinery',
    value: 'maintenance_workers_machinery',
    label: 'Maintenance Workers, Machinery',
  },
  {
    key: 'statistical_assistants',
    value: 'statistical_assistants',
    label: 'Statistical Assistants',
  },
  {
    key: 'compensation_benefits_and_job_analysis_specialists',
    value: 'compensation_benefits_and_job_analysis_specialists',
    label: 'Compensation, Benefits, and Job Analysis Specialists',
  },
  {
    key: 'bus_and_truck_mechanics_and_diesel_engine_specialists',
    value: 'bus_and_truck_mechanics_and_diesel_engine_specialists',
    label: 'Bus and Truck Mechanics and Diesel Engine Specialists',
  },
  { key: 'traffic_technicians', value: 'traffic_technicians', label: 'Traffic Technicians' },
  {
    key: 'legal_support_workers_all_other',
    value: 'legal_support_workers_all_other',
    label: 'Legal Support Workers, All Other',
  },
  {
    key: 'first_line_supervisors_managers_of_helpers_laborers_and_material_movers_hand',
    value: 'first_line_supervisors_managers_of_helpers_laborers_and_material_movers_hand',
    label: 'First-Line Supervisors/Managers of Helpers, Laborers, and Material Movers, Hand',
  },
  {
    key: 'surveying_and_mapping_technicians',
    value: 'surveying_and_mapping_technicians',
    label: 'Surveying and Mapping Technicians',
  },
  {
    key: 'radio_and_television_announcers',
    value: 'radio_and_television_announcers',
    label: 'Radio and Television Announcers',
  },
  {
    key: 'maintenance_and_repair_workers_general',
    value: 'maintenance_and_repair_workers_general',
    label: 'Maintenance and Repair Workers, General',
  },
  {
    key: 'word_processors_and_typists',
    value: 'word_processors_and_typists',
    label: 'Word Processors and Typists',
  },
  {
    key: 'packaging_and_filling_machine_operators_and_tenders',
    value: 'packaging_and_filling_machine_operators_and_tenders',
    label: 'Packaging and Filling Machine Operators and Tenders',
  },
  {
    key: 'billing_and_posting_clerks_and_machine_operators',
    value: 'billing_and_posting_clerks_and_machine_operators',
    label: 'Billing and Posting Clerks and Machine Operators',
  },
  { key: 'chemists', value: 'chemists', label: 'Chemists' },
  { key: 'materials_engineers', value: 'materials_engineers', label: 'Materials Engineers' },
  {
    key: 'meeting_and_convention_planners',
    value: 'meeting_and_convention_planners',
    label: 'Meeting and Convention Planners',
  },
  {
    key: 'computer_and_information_systems_managers',
    value: 'computer_and_information_systems_managers',
    label: 'Computer and Information Systems Managers',
  },
  {
    key: 'structural_metal_fabricators_and_fitters',
    value: 'structural_metal_fabricators_and_fitters',
    label: 'Structural Metal Fabricators and Fitters',
  },
  {
    key: 'first_line_supervisors_managers_of_production_and_operating_workers',
    value: 'first_line_supervisors_managers_of_production_and_operating_workers',
    label: 'First-Line Supervisors/Managers of Production and Operating Workers',
  },
  { key: 'chefs_and_head_cooks', value: 'chefs_and_head_cooks', label: 'Chefs and Head Cooks' },
  {
    key: 'database_administrators',
    value: 'database_administrators',
    label: 'Database Administrators',
  },
  { key: 'purchasing_managers', value: 'purchasing_managers', label: 'Purchasing Managers' },
  {
    key: 'construction_and_related_workers_all_other',
    value: 'construction_and_related_workers_all_other',
    label: 'Construction and Related Workers, All Other',
  },
  { key: 'security_guards', value: 'security_guards', label: 'Security Guards' },
  { key: 'data_entry_keyers', value: 'data_entry_keyers', label: 'Data Entry Keyers' },
  {
    key: 'electronic_home_entertainment_equipment_installers_and_repairers',
    value: 'electronic_home_entertainment_equipment_installers_and_repairers',
    label: 'Electronic Home Entertainment Equipment Installers and Repairers',
  },
  {
    key: 'business_operations_specialists_all_other',
    value: 'business_operations_specialists_all_other',
    label: 'Business Operations Specialists, All Other',
  },
  {
    key: 'architectural_and_civil_drafters',
    value: 'architectural_and_civil_drafters',
    label: 'Architectural and Civil Drafters',
  },
  {
    key: 'sales_and_related_workers_all_other',
    value: 'sales_and_related_workers_all_other',
    label: 'Sales and Related Workers, All Other',
  },
  {
    key: 'property_real_estate_and_community_association_managers',
    value: 'property_real_estate_and_community_association_managers',
    label: 'Property, Real Estate, and Community Association Managers',
  },
  { key: 'logisticians', value: 'logisticians', label: 'Logisticians' },
  {
    key: 'painting_coating_and_decorating_workers',
    value: 'painting_coating_and_decorating_workers',
    label: 'Painting, Coating, and Decorating Workers',
  },
  { key: 'industrial_engineers', value: 'industrial_engineers', label: 'Industrial Engineers' },
  {
    key: 'insurance_sales_agents',
    value: 'insurance_sales_agents',
    label: 'Insurance Sales Agents',
  },
  {
    key: 'structural_iron_and_steel_workers',
    value: 'structural_iron_and_steel_workers',
    label: 'Structural Iron and Steel Workers',
  },
  {
    key: 'chemical_equipment_operators_and_tenders',
    value: 'chemical_equipment_operators_and_tenders',
    label: 'Chemical Equipment Operators and Tenders',
  },
  {
    key: 'drywall_and_ceiling_tile_installers',
    value: 'drywall_and_ceiling_tile_installers',
    label: 'Drywall and Ceiling Tile Installers',
  },
  {
    key: 'industrial_engineering_technicians',
    value: 'industrial_engineering_technicians',
    label: 'Industrial Engineering Technicians',
  },
  {
    key:
      'first_line_supervisors_managers_of_transportation_and_material_moving_machine_and_vehicle_operators',
    value:
      'first_line_supervisors_managers_of_transportation_and_material_moving_machine_and_vehicle_operators',
    label:
      'First-Line Supervisors/Managers of Transportation and Material-Moving Machine and Vehicle Operators',
  },
  { key: 'chemical_technicians', value: 'chemical_technicians', label: 'Chemical Technicians' },
  { key: 'financial_managers', value: 'financial_managers', label: 'Financial Managers' },
  { key: 'broadcast_technicians', value: 'broadcast_technicians', label: 'Broadcast Technicians' },
  {
    key: 'social_scientists_and_related_workers_all_other',
    value: 'social_scientists_and_related_workers_all_other',
    label: 'Social Scientists and Related Workers, All Other',
  },
  {
    key: 'electrical_and_electronics_drafters',
    value: 'electrical_and_electronics_drafters',
    label: 'Electrical and Electronics Drafters',
  },
  {
    key: 'first_line_supervisors_managers_of_non_retail_sales_workers',
    value: 'first_line_supervisors_managers_of_non_retail_sales_workers',
    label: 'First-Line Supervisors/Managers of Non-Retail Sales Workers',
  },
  {
    key: 'bill_and_account_collectors',
    value: 'bill_and_account_collectors',
    label: 'Bill and Account Collectors',
  },
  {
    key: 'weighers_measurers_checkers_and_samplers_recordkeeping',
    value: 'weighers_measurers_checkers_and_samplers_recordkeeping',
    label: 'Weighers, Measurers, Checkers, and Samplers, Recordkeeping',
  },
  {
    key: 'transportation_storage_and_distribution_managers',
    value: 'transportation_storage_and_distribution_managers',
    label: 'Transportation, Storage, and Distribution Managers',
  },
  {
    key: 'painters_construction_and_maintenance',
    value: 'painters_construction_and_maintenance',
    label: 'Painters, Construction and Maintenance',
  },
  { key: 'concierges', value: 'concierges', label: 'Concierges' },
  { key: 'machinists', value: 'machinists', label: 'Machinists' },
  { key: 'sales_engineers', value: 'sales_engineers', label: 'Sales Engineers' },
  { key: 'telemarketers', value: 'telemarketers', label: 'Telemarketers' },
  {
    key: 'truck_drivers_light_or_delivery_services',
    value: 'truck_drivers_light_or_delivery_services',
    label: 'Truck Drivers, Light or Delivery Services',
  },
  { key: 'sales_managers', value: 'sales_managers', label: 'Sales Managers' },
  {
    key: 'network_and_computer_systems_administrators',
    value: 'network_and_computer_systems_administrators',
    label: 'Network and Computer Systems Administrators',
  },
  { key: 'construction_laborers', value: 'construction_laborers', label: 'Construction Laborers' },
  { key: 'bindery_workers', value: 'bindery_workers', label: 'Bindery Workers' },
  {
    key: 'computer_systems_analysts',
    value: 'computer_systems_analysts',
    label: 'Computer Systems Analysts',
  },
  {
    key: 'molding_coremaking_and_casting_machine_setters_operators_and_tenders_metal_and_plastic',
    value: 'molding_coremaking_and_casting_machine_setters_operators_and_tenders_metal_and_plastic',
    label:
      'Molding, Coremaking, and Casting Machine Setters, Operators, and Tenders, Metal and Plastic',
  },
  {
    key: 'computer_support_specialists',
    value: 'computer_support_specialists',
    label: 'Computer Support Specialists',
  },
  {
    key: 'mobile_heavy_equipment_mechanics_except_engines',
    value: 'mobile_heavy_equipment_mechanics_except_engines',
    label: 'Mobile Heavy Equipment Mechanics, Except Engines',
  },
  {
    key: 'paralegals_and_legal_assistants',
    value: 'paralegals_and_legal_assistants',
    label: 'Paralegals and Legal Assistants',
  },
  {
    key: 'tool_grinders_filers_and_sharpeners',
    value: 'tool_grinders_filers_and_sharpeners',
    label: 'Tool Grinders, Filers, and Sharpeners',
  },
  { key: 'bakers', value: 'bakers', label: 'Bakers' },
  {
    key: 'computer_and_information_scientists_research',
    value: 'computer_and_information_scientists_research',
    label: 'Computer and Information Scientists, Research',
  },
  {
    key: 'computer_controlled_machine_tool_operators_metal_and_plastic',
    value: 'computer_controlled_machine_tool_operators_metal_and_plastic',
    label: 'Computer-Controlled Machine Tool Operators, Metal and Plastic',
  },
  {
    key: 'real_estate_sales_agents',
    value: 'real_estate_sales_agents',
    label: 'Real Estate Sales Agents',
  },
  { key: 'cost_estimators', value: 'cost_estimators', label: 'Cost Estimators' },
  { key: 'management_analysts', value: 'management_analysts', label: 'Management Analysts' },
  {
    key: 'home_appliance_repairers',
    value: 'home_appliance_repairers',
    label: 'Home Appliance Repairers',
  },
  {
    key: 'commercial_and_industrial_designers',
    value: 'commercial_and_industrial_designers',
    label: 'Commercial and Industrial Designers',
  },
  {
    key: 'cargo_and_freight_agents',
    value: 'cargo_and_freight_agents',
    label: 'Cargo and Freight Agents',
  },
  { key: 'travel_agents', value: 'travel_agents', label: 'Travel Agents' },
  {
    key: 'audio_visual_collections_specialists',
    value: 'audio_visual_collections_specialists',
    label: 'Audio-Visual Collections Specialists',
  },
  {
    key: 'food_preparation_workers',
    value: 'food_preparation_workers',
    label: 'Food Preparation Workers',
  },
  {
    key: 'customer_service_representatives',
    value: 'customer_service_representatives',
    label: 'Customer Service Representatives',
  },
  {
    key: 'mail_clerks_and_mail_machine_operators_except_postal_service',
    value: 'mail_clerks_and_mail_machine_operators_except_postal_service',
    label: 'Mail Clerks and Mail Machine Operators, Except Postal Service',
  },
  { key: 'roofers', value: 'roofers', label: 'Roofers' },
  { key: 'marketing_managers', value: 'marketing_managers', label: 'Marketing Managers' },
  {
    key: 'dispatchers_except_police_fire_and_ambulance',
    value: 'dispatchers_except_police_fire_and_ambulance',
    label: 'Dispatchers, Except Police, Fire, and Ambulance',
  },
  {
    key: 'chemical_plant_and_system_operators',
    value: 'chemical_plant_and_system_operators',
    label: 'Chemical Plant and System Operators',
  },
  { key: 'computer_operators', value: 'computer_operators', label: 'Computer Operators' },
  {
    key: 'proofreaders_and_copy_markers',
    value: 'proofreaders_and_copy_markers',
    label: 'Proofreaders and Copy Markers',
  },
  {
    key: 'claims_adjusters_examiners_and_investigators',
    value: 'claims_adjusters_examiners_and_investigators',
    label: 'Claims Adjusters, Examiners, and Investigators',
  },
  {
    key: 'sewing_machine_operators',
    value: 'sewing_machine_operators',
    label: 'Sewing Machine Operators',
  },
  {
    key: 'computer_specialists_all_other',
    value: 'computer_specialists_all_other',
    label: 'Computer Specialists, All Other',
  },
  {
    key: 'purchasing_agents_except_wholesale_retail_and_farm_products',
    value: 'purchasing_agents_except_wholesale_retail_and_farm_products',
    label: 'Purchasing Agents, Except Wholesale, Retail, and Farm Products',
  },
  {
    key: 'producers_and_directors',
    value: 'producers_and_directors',
    label: 'Producers and Directors',
  },
  {
    key: 'tile_and_marble_setters',
    value: 'tile_and_marble_setters',
    label: 'Tile and Marble Setters',
  },
  {
    key: 'fabric_menders_except_garment',
    value: 'fabric_menders_except_garment',
    label: 'Fabric Menders, Except Garment',
  },
  { key: 'procurement_clerks', value: 'procurement_clerks', label: 'Procurement Clerks' },
  {
    key: 'welders_cutters_solderers_and_brazers',
    value: 'welders_cutters_solderers_and_brazers',
    label: 'Welders, Cutters, Solderers, and Brazers',
  },
  { key: 'file_clerks', value: 'file_clerks', label: 'File Clerks' },
  {
    key: 'hotel_motel_and_resort_desk_clerks',
    value: 'hotel_motel_and_resort_desk_clerks',
    label: 'Hotel, Motel, and Resort Desk Clerks',
  },
  {
    key: 'photographic_processing_machine_operators',
    value: 'photographic_processing_machine_operators',
    label: 'Photographic Processing Machine Operators',
  },
  {
    key: 'coating_painting_and_spraying_machine_setters_operators_and_tenders',
    value: 'coating_painting_and_spraying_machine_setters_operators_and_tenders',
    label: 'Coating, Painting, and Spraying Machine Setters, Operators, and Tenders',
  },
  { key: 'lawyers', value: 'lawyers', label: 'Lawyers' },
  { key: 'tool_and_die_makers', value: 'tool_and_die_makers', label: 'Tool and Die Makers' },
  {
    key: 'industrial_machinery_mechanics',
    value: 'industrial_machinery_mechanics',
    label: 'Industrial Machinery Mechanics',
  },
  {
    key: 'bookkeeping_accounting_and_auditing_clerks',
    value: 'bookkeeping_accounting_and_auditing_clerks',
    label: 'Bookkeeping, Accounting, and Auditing Clerks',
  },
  {
    key: 'butchers_and_meat_cutters',
    value: 'butchers_and_meat_cutters',
    label: 'Butchers and Meat Cutters',
  },
  {
    key: 'loan_interviewers_and_clerks',
    value: 'loan_interviewers_and_clerks',
    label: 'Loan Interviewers and Clerks',
  },
  { key: 'editors', value: 'editors', label: 'Editors' },
  {
    key: 'sound_engineering_technicians',
    value: 'sound_engineering_technicians',
    label: 'Sound Engineering Technicians',
  },
  { key: 'technical_writers', value: 'technical_writers', label: 'Technical Writers' },
  {
    key: 'mechanical_door_repairers',
    value: 'mechanical_door_repairers',
    label: 'Mechanical Door Repairers',
  },
  {
    key: 'electrical_and_electronic_equipment_assemblers',
    value: 'electrical_and_electronic_equipment_assemblers',
    label: 'Electrical and Electronic Equipment Assemblers',
  },
  { key: 'flight_attendants', value: 'flight_attendants', label: 'Flight Attendants' },
  {
    key: 'market_research_analysts',
    value: 'market_research_analysts',
    label: 'Market Research Analysts',
  },
  {
    key: 'computer_automated_teller_and_office_machine_repairers',
    value: 'computer_automated_teller_and_office_machine_repairers',
    label: 'Computer, Automated Teller, and Office Machine Repairers',
  },
  {
    key: 'appraisers_and_assessors_of_real_estate',
    value: 'appraisers_and_assessors_of_real_estate',
    label: 'Appraisers and Assessors of Real Estate',
  },
  {
    key: 'securities_commodities_and_financial_services_sales_agents',
    value: 'securities_commodities_and_financial_services_sales_agents',
    label: 'Securities, Commodities, and Financial Services Sales Agents',
  },
  { key: 'food_service_managers', value: 'food_service_managers', label: 'Food Service Managers' },
  {
    key: 'foundry_mold_and_coremakers',
    value: 'foundry_mold_and_coremakers',
    label: 'Foundry Mold and Coremakers',
  },
  {
    key: 'numerical_tool_and_process_control_programmers',
    value: 'numerical_tool_and_process_control_programmers',
    label: 'Numerical Tool and Process Control Programmers',
  },
  {
    key: 'production_planning_and_expediting_clerks',
    value: 'production_planning_and_expediting_clerks',
    label: 'Production, Planning, and Expediting Clerks',
  },
  {
    key: 'insurance_claims_and_policy_processing_clerks',
    value: 'insurance_claims_and_policy_processing_clerks',
    label: 'Insurance Claims and Policy Processing Clerks',
  },
  { key: 'photographers', value: 'photographers', label: 'Photographers' },
  {
    key: 'sales_representatives_services_all_other',
    value: 'sales_representatives_services_all_other',
    label: 'Sales Representatives, Services, All Other',
  },
  { key: 'legal_secretaries', value: 'legal_secretaries', label: 'Legal Secretaries' },
  { key: 'new_accounts_clerks', value: 'new_accounts_clerks', label: 'New Accounts Clerks' },
  { key: 'team_assemblers', value: 'team_assemblers', label: 'Team Assemblers' },
  {
    key: 'first_line_supervisors_managers_of_retail_sales_workers',
    value: 'first_line_supervisors_managers_of_retail_sales_workers',
    label: 'First-Line Supervisors/Managers of Retail Sales Workers',
  },
  {
    key: 'wholesale_and_retail_buyers_except_farm_products',
    value: 'wholesale_and_retail_buyers_except_farm_products',
    label: 'Wholesale and Retail Buyers, Except Farm Products',
  },
  { key: 'boilermakers', value: 'boilermakers', label: 'Boilermakers' },
  {
    key: 'railroad_conductors_and_yardmasters',
    value: 'railroad_conductors_and_yardmasters',
    label: 'Railroad Conductors and Yardmasters',
  },
  { key: 'financial_analysts', value: 'financial_analysts', label: 'Financial Analysts' },
  { key: 'desktop_publishers', value: 'desktop_publishers', label: 'Desktop Publishers' },
  {
    key: 'cutting_punching_and_press_machine_setters_operators_and_tenders_metal_and_plastic',
    value: 'cutting_punching_and_press_machine_setters_operators_and_tenders_metal_and_plastic',
    label:
      'Cutting, Punching, and Press Machine Setters, Operators, and Tenders, Metal and Plastic',
  },
  {
    key: 'geological_and_petroleum_technicians',
    value: 'geological_and_petroleum_technicians',
    label: 'Geological and Petroleum Technicians',
  },
  { key: 'order_clerks', value: 'order_clerks', label: 'Order Clerks' },
  {
    key: 'heat_treating_equipment_setters_operators_and_tenders_metal_and_plastic',
    value: 'heat_treating_equipment_setters_operators_and_tenders_metal_and_plastic',
    label: 'Heat Treating Equipment Setters, Operators, and Tenders, Metal and Plastic',
  },
  {
    key: 'sawing_machine_setters_operators_and_tenders_wood',
    value: 'sawing_machine_setters_operators_and_tenders_wood',
    label: 'Sawing Machine Setters, Operators, and Tenders, Wood',
  },
  {
    key: 'accountants_and_auditors',
    value: 'accountants_and_auditors',
    label: 'Accountants and Auditors',
  },
  {
    key: 'sales_representatives_wholesale_and_manufacturing_technical_and_scientific_products',
    value: 'sales_representatives_wholesale_and_manufacturing_technical_and_scientific_products',
    label: 'Sales Representatives, Wholesale and Manufacturing, Technical and Scientific Products',
  },
  { key: 'motorcycle_mechanics', value: 'motorcycle_mechanics', label: 'Motorcycle Mechanics' },
  {
    key: 'advertising_sales_agents',
    value: 'advertising_sales_agents',
    label: 'Advertising Sales Agents',
  },
  {
    key: 'tax_examiners_collectors_and_revenue_agents',
    value: 'tax_examiners_collectors_and_revenue_agents',
    label: 'Tax Examiners, Collectors, and Revenue Agents',
  },
  { key: 'millwrights', value: 'millwrights', label: 'Millwrights' },
  { key: 'slot_key_persons', value: 'slot_key_persons', label: 'Slot Key Persons' },
  { key: 'credit_analysts', value: 'credit_analysts', label: 'Credit Analysts' },
  { key: 'driver_sales_workers', value: 'driver_sales_workers', label: 'Driver/Sales Workers' },
  {
    key: 'first_line_supervisors_managers_of_food_preparation_and_serving_workers',
    value: 'first_line_supervisors_managers_of_food_preparation_and_serving_workers',
    label: 'First-Line Supervisors/Managers of Food Preparation and Serving Workers',
  },
  {
    key: 'laborers_and_freight_stock_and_material_movers_hand',
    value: 'laborers_and_freight_stock_and_material_movers_hand',
    label: 'Laborers and Freight, Stock, and Material Movers, Hand',
  },
  {
    key: 'cabinetmakers_and_bench_carpenters',
    value: 'cabinetmakers_and_bench_carpenters',
    label: 'Cabinetmakers and Bench Carpenters',
  },
  { key: 'parts_salespersons', value: 'parts_salespersons', label: 'Parts Salespersons' },
  {
    key: 'insurance_underwriters',
    value: 'insurance_underwriters',
    label: 'Insurance Underwriters',
  },
  {
    key: 'cleaners_of_vehicles_and_equipment',
    value: 'cleaners_of_vehicles_and_equipment',
    label: 'Cleaners of Vehicles and Equipment',
  },
  {
    key:
      'sales_representatives_wholesale_and_manufacturing_except_technical_and_scientific_products',
    value:
      'sales_representatives_wholesale_and_manufacturing_except_technical_and_scientific_products',
    label:
      'Sales Representatives, Wholesale and Manufacturing, Except Technical and Scientific Products',
  },
  { key: 'actuaries', value: 'actuaries', label: 'Actuaries' },
  {
    key: 'automotive_service_technicians_and_mechanics',
    value: 'automotive_service_technicians_and_mechanics',
    label: 'Automotive Service Technicians and Mechanics',
  },
  {
    key: 'operations_research_analysts',
    value: 'operations_research_analysts',
    label: 'Operations Research Analysts',
  },
  {
    key: 'stock_clerks_and_order_fillers',
    value: 'stock_clerks_and_order_fillers',
    label: 'Stock Clerks and Order Fillers',
  },
  {
    key: 'media_and_communication_workers_all_other',
    value: 'media_and_communication_workers_all_other',
    label: 'Media and Communication Workers, All Other',
  },
  { key: 'cooks_short_order', value: 'cooks_short_order', label: 'Cooks, Short Order' },
  { key: 'art_directors', value: 'art_directors', label: 'Art Directors' },
  { key: 'tax_preparers', value: 'tax_preparers', label: 'Tax Preparers' },
  { key: 'retail_salespersons', value: 'retail_salespersons', label: 'Retail Salespersons' },
  {
    key: 'automotive_body_and_related_repairers',
    value: 'automotive_body_and_related_repairers',
    label: 'Automotive Body and Related Repairers',
  },
  {
    key: 'merchandise_displayers_and_window_trimmers',
    value: 'merchandise_displayers_and_window_trimmers',
    label: 'Merchandise Displayers and Window Trimmers',
  },
  {
    key: 'helpers_production_workers',
    value: 'helpers_production_workers',
    label: 'Helpers--Production Workers',
  },
  {
    key: 'laundry_and_dry_cleaning_workers',
    value: 'laundry_and_dry_cleaning_workers',
    label: 'Laundry and Dry-Cleaning Workers',
  },
  {
    key: 'multiple_machine_tool_setters_operators_and_tenders_metal_and_plastic',
    value: 'multiple_machine_tool_setters_operators_and_tenders_metal_and_plastic',
    label: 'Multiple Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
  },
  {
    key: 'packers_and_packagers_hand',
    value: 'packers_and_packagers_hand',
    label: 'Packers and Packagers, Hand',
  },
  { key: 'graphic_designers', value: 'graphic_designers', label: 'Graphic Designers' },
  { key: 'cashiers', value: 'cashiers', label: 'Cashiers' },
  { key: 'cooks_restaurant', value: 'cooks_restaurant', label: 'Cooks, Restaurant' },
  {
    key: 'hosts_and_hostesses_restaurant_lounge_and_coffee_shop',
    value: 'hosts_and_hostesses_restaurant_lounge_and_coffee_shop',
    label: 'Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop',
  },
  {
    key: 'combined_food_preparation_and_serving_workers_including_fast_food',
    value: 'combined_food_preparation_and_serving_workers_including_fast_food',
    label: 'Combined Food Preparation and Serving Workers, Including Fast Food',
  },
  { key: 'tellers', value: 'tellers', label: 'Tellers' },
  {
    key: 'baggage_porters_and_bellhops',
    value: 'baggage_porters_and_bellhops',
    label: 'Baggage Porters and Bellhops',
  },
  {
    key: 'dining_room_and_cafeteria_attendants_and_bartender_helpers',
    value: 'dining_room_and_cafeteria_attendants_and_bartender_helpers',
    label: 'Dining Room and Cafeteria Attendants and Bartender Helpers',
  },
  {
    key: 'counter_attendants_cafeteria_food_concession_and_coffee_shop',
    value: 'counter_attendants_cafeteria_food_concession_and_coffee_shop',
    label: 'Counter Attendants, Cafeteria, Food Concession, and Coffee Shop',
  },
  {
    key: 'shipping_receiving_and_traffic_clerks',
    value: 'shipping_receiving_and_traffic_clerks',
    label: 'Shipping, Receiving, and Traffic Clerks',
  },
  {
    key: 'insurance_appraisers_auto_damage',
    value: 'insurance_appraisers_auto_damage',
    label: 'Insurance Appraisers, Auto Damage',
  },
  {
    key: 'outdoor_power_equipment_and_other_small_engine_mechanics',
    value: 'outdoor_power_equipment_and_other_small_engine_mechanics',
    label: 'Outdoor Power Equipment and Other Small Engine Mechanics',
  },
  {
    key: 'lathe_and_turning_machine_tool_setters_operators_and_tenders_metal_and_plastic',
    value: 'lathe_and_turning_machine_tool_setters_operators_and_tenders_metal_and_plastic',
    label: 'Lathe and Turning Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
  },
  { key: 'bartenders', value: 'bartenders', label: 'Bartenders' },
  {
    key: 'model_makers_metal_and_plastic',
    value: 'model_makers_metal_and_plastic',
    label: 'Model Makers, Metal and Plastic',
  },
  {
    key: 'reservation_and_transportation_ticket_agents_and_travel_clerks',
    value: 'reservation_and_transportation_ticket_agents_and_travel_clerks',
    label: 'Reservation and Transportation Ticket Agents and Travel Clerks',
  },
  {
    key: 'waiters_and_waitresses',
    value: 'waiters_and_waitresses',
    label: 'Waiters and Waitresses',
  },
  { key: 'writers_and_authors', value: 'writers_and_authors', label: 'Writers and Authors' },
  {
    key: 'advertising_and_promotions_managers',
    value: 'advertising_and_promotions_managers',
    label: 'Advertising and Promotions Managers',
  },
  {
    key: 'computer_software_engineers_applications',
    value: 'computer_software_engineers_applications',
    label: 'Computer Software Engineers, Applications',
  },
  {
    key: 'film_and_video_editors',
    value: 'film_and_video_editors',
    label: 'Film and Video Editors',
  },
  {
    key: 'industrial_truck_and_tractor_operators',
    value: 'industrial_truck_and_tractor_operators',
    label: 'Industrial Truck and Tractor Operators',
  },
  { key: 'dishwashers', value: 'dishwashers', label: 'Dishwashers' },
  { key: 'motorboat_mechanics', value: 'motorboat_mechanics', label: 'Motorboat Mechanics' },
  {
    key: 'multi_media_artists_and_animators',
    value: 'multi_media_artists_and_animators',
    label: 'Multi-Media Artists and Animators',
  },
  { key: 'cooks_fast_food', value: 'cooks_fast_food', label: 'Cooks, Fast Food' },
  {
    key: 'jewelers_and_precious_stone_and_metal_workers',
    value: 'jewelers_and_precious_stone_and_metal_workers',
    label: 'Jewelers and Precious Stone and Metal Workers',
  },
  {
    key: 'pourers_and_casters_metal',
    value: 'pourers_and_casters_metal',
    label: 'Pourers and Casters, Metal',
  },
  { key: 'brokerage_clerks', value: 'brokerage_clerks', label: 'Brokerage Clerks' },
  {
    key: 'printing_machine_operators',
    value: 'printing_machine_operators',
    label: 'Printing Machine Operators',
  },
  { key: 'job_printers', value: 'job_printers', label: 'Job Printers' },
  { key: 'fashion_designers', value: 'fashion_designers', label: 'Fashion Designers' },
  {
    key: 'purchasing_agents_and_buyers_farm_products',
    value: 'purchasing_agents_and_buyers_farm_products',
    label: 'Purchasing Agents and Buyers, Farm Products',
  },
  {
    key: 'crushing_grinding_and_polishing_machine_setters_operators_and_tenders',
    value: 'crushing_grinding_and_polishing_machine_setters_operators_and_tenders',
    label: 'Crushing, Grinding, and Polishing Machine Setters, Operators, and Tenders',
  },
  {
    key: 'counter_and_rental_clerks',
    value: 'counter_and_rental_clerks',
    label: 'Counter and Rental Clerks',
  },
  {
    key: 'welding_soldering_and_brazing_machine_setters_operators_and_tenders',
    value: 'welding_soldering_and_brazing_machine_setters_operators_and_tenders',
    label: 'Welding, Soldering, and Brazing Machine Setters, Operators, and Tenders',
  },
  {
    key: 'fabric_and_apparel_patternmakers',
    value: 'fabric_and_apparel_patternmakers',
    label: 'Fabric and Apparel Patternmakers',
  },
  {
    key: 'title_examiners_abstractors_and_searchers',
    value: 'title_examiners_abstractors_and_searchers',
    label: 'Title Examiners, Abstractors, and Searchers',
  },
  {
    key: 'prepress_technicians_and_workers',
    value: 'prepress_technicians_and_workers',
    label: 'Prepress Technicians and Workers',
  },
  { key: 'gaming_supervisors', value: 'gaming_supervisors', label: 'Gaming Supervisors' },
  {
    key: 'parking_lot_attendants',
    value: 'parking_lot_attendants',
    label: 'Parking Lot Attendants',
  },
];
