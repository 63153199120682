import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './SignupForm.module.css';
import { useHistory } from "react-router-dom";

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        img1,
        onOpenTermsOfService,
        values,
      } = fieldRenderProps;

      const history = useHistory();

      //const handleSubmit2 = async (event) => {
      //  event.preventDefault(); // Prevent the default form submission behavior
      //  await fieldRenderProps.handleSubmit(); // Wait for form submission to complete
      //  if (!props.inProgress) { // Check if inProgress state is finished
      //    history.push('/about'); // Use history.push to navigate without full page reload
      //  }
      //};

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );
      // console.log('vals', values)
      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      const userNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.userNameRequired',
      });
      const userNameRequired = validators.required(userNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const newPasswordSameAsConfirmationPassword =
        values.confirmation_password == values.password && values.password != undefined;
      const passwordChanged = values.confirmation_password !== values.password;
      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );
      const emailIcon = css.emailIcon;
      const keyIcon = css.keyIcon;
      const usernameIcon = css.usernameIcon;
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              className={css.firstNameRoot}
              type="text"
              iconCss={css.usernameIcon}
              id={formId ? `${formId}.username` : 'username'}
              name="username"
              autoComplete="given-name"
              placeholder="Choose a username"
              label={'Username'}
              // validate={userNameRequired}
              validate={validators.composeValidators(
                validators.required('Username required'),
                validators.usernameFormatValid(
                  'Username should be between 8-20 characters and avoid special characters and spaces '
                )
              )}
            />
            <p className={css.uniqueUsername}>Make it unique!</p>
            <FieldTextInput
              style={{ paddingBottom: '15px' }}
              type="email"
              iconCss={emailIcon}
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              placeholder="Email address"
              label="Email"
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <p className={css.spamMsg}>We won't spam you, we need it for verification purposes.</p>
            <FieldTextInput
              className={css.passwordMain}
              iconCss={keyIcon}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              label="Password"
              autoComplete="new-password"
              placeholder="Choose a password"
              validate={passwordValidators}
            />
            <p className={css.passwordMsg}>
              8 characters, at least one capital letter and one special character.
            </p>
            <FieldTextInput
              type="password"
              className={css.password}
              id={formId ? `${formId}.confirmation_password` : 'confirmation_password'}
              name="confirmation_password"
              autoComplete="confirmation_password"
              label={'Confirm password'}
              // onfieldBlur={() => setBlurred(true)}
              iconCss={keyIcon}
              placeholder="Repeat the password"
              validate={passwordValidators}
            />
            {newPasswordSameAsConfirmationPassword
              ? null
              : typeof values.confirmation_password != 'undefined' &&
                passwordChanged && (
                  <div className="mb-4" style={{ color: 'red' }}>
                    <p className="my-0 mt-4 text-sm text-red-500">
                      Password and confirmation passwords do not match
                    </p>
                  </div>
                )}
            {/* <FieldTextInput
              className={css.firstNameRoot}
              type="text"
              iconCss={css.usernameIcon}
              id={formId ? `${formId}.fname` : 'fname'}
              name=""
              autoComplete="given-name"
              label={firstNameLabel}
              placeholder={firstNamePlaceholder}
              validate={firstNameRequired}
            />
            <FieldTextInput
              className={css.lastNameRoot}
              type="text"
              iconCss={css.usernameIcon}
              id={formId ? `${formId}.lname` : 'lname'}
              name="lname"
              autoComplete="family-name"
              label={lastNameLabel}
              placeholder={lastNamePlaceholder}
              validate={lastNameRequired}
            /> */}
          </div>

          <div className={css.bottomWrapper}>
            {/* <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p> */}
            <PrimaryButton
              style={{
                width: '167px',
                height: '50px',
                left: '876px',
                top: '794px',
                margin: ' 0 auto',
                background: '#6F00DE',
                borderRadius: '25px',
              }}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              Continue
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
