import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingSocialsForm } from '../../forms';
import { ListingLink } from '..';
import useInitialValues from '../UseInitialValuesHook/useInitialValues';
import config from '../../config';

const REACT_APP_FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
const REACT_APP_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

import css from './EditListingSocialsPanel.module.css';
import { useHistory } from 'react-router-dom';

const FEATURES_NAME = 'socials';

const EditListingSocialsPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    location,
    currentUser,
  } = props;
  const instagramErr = new URLSearchParams(location?.search).get('instagramErr');

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingSocialsPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingSocialsPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingSocialsPanel.createListingTitle" />
  );

  let oldInitialValues = {};
  oldInitialValues = {
    ...oldInitialValues,
    ...{
      instaFollowers: publicData.instaFollowers
        ? config.custom?.instaFollowersOptions?.find(
            e => e.key == String(publicData.instaFollowers)
          )
        : '',
    },
  };
  const { initialValues, updateInitialValues } = useInitialValues(oldInitialValues);
  const connectInstagram =
    REACT_APP_FACEBOOK_CLIENT_ID && REACT_APP_REDIRECT_URL
      ? `https://api.instagram.com/oauth/authorize?client_id=${REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${REACT_APP_REDIRECT_URL}&scope=user_profile&response_type=code`
      : '';
  return (
    <div className={classes}>
      <EditListingSocialsForm
        className={css.form}
        name={FEATURES_NAME}
        initialValues={initialValues}
        instagramErr={instagramErr}
        onSubmit={values => {
          const { instaFollowers = {} } = values;
          updateInitialValues({
            instaFollowers,
          });
          const updatedValues = {
            publicData: {
              instaFollowers: instaFollowers?.value,
            },
          };
          onSubmit(updatedValues);
        }}
        connectInstagram={connectInstagram}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        currentUser={currentUser}
      />
    </div>
  );
};

EditListingSocialsPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingSocialsPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingSocialsPanel;
