import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { professions } from '../../professions';
import { universities } from '../../universities';
import {
  maxLength,
  usernameFormatValid,
  nonEmptyArray,
  required,
  composeValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
} from '../../util/validators';
import config from '../../config';
import {
  Form,
  Button,
  AddImages,
  FieldTextInput,
  DateOfBirth,
  ValidationError,
  Map,
  LocationAutocompleteInputField,
  FieldSelectModern,
} from '../../components';
import CustomCertificateSelectFieldMaybe from './CustomCertificateSelectFieldMaybe';
import { FiPaperclip } from 'react-icons/fi';
import css from './EditListingDescriptionForm.module.css';
import CardMap from '../../containers/LandingPage/SectionFeaturedListing/CardMap';
import moment from 'moment';
const ACCEPT_IMAGES = 'image/*';
const identity = v => v;

const TITLE_MAX_LENGTH = 40;
const GENDER_MAX_LENGTH = 10;
const DESC_MAX_LENGTH = 180;
const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        certificateOptions,
        onImageUpload,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        images,
        onRemoveImage,
        updateInProgress,
        updateUserNameError,
        updateUserNameInProgress,
        publicData,
        listingId,
        values,
        fetchErrors,
        errors,
        form,
      } = formRenderProps;
      // console.log({ values, form });
      // values.images = images;

      if (images) form.change(`images`, images);

      const [imageUploadRequested, setimageUploadRequested] = useState(0);
      const chooseImageText = 'Attach a photo';

      const onImageUploadHandler = file => {
        if (file) {
          setimageUploadRequested(true);

          setimageUploadRequested(true);
          onImageUpload({ id: `${file.name}_${Date.now()}`, file })
            .then(() => {
              setimageUploadRequested(false);
            })
            .catch(() => {
              setimageUploadRequested(false);
            });
        }
      };

      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const genderMaxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: GENDER_MAX_LENGTH,
        }
      );

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const maxLength10Message = maxLength(genderMaxLengthMessage, GENDER_MAX_LENGTH);

      const maxLength180Message = maxLength(maxLengthMessage, DESC_MAX_LENGTH);

      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });
      const dateInvalidMessage =
        values && values.day?.value && values.month?.value && values.year?.value
          ? moment(
              `${values.year?.value}-${values.month?.value}-${values.day?.value}
            `,
              'YYYY-MM-DD'
            )?.isValid()
            ? null
            : 'Date is Invalid'
          : null;

      const classes = classNames(css.root, className);
      const submitReady = ready;
      const submitInProgress = updateInProgress || updateUserNameInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || dateInvalidMessage;
      // console.log({ updated, ready });
      const address = values && values.location ? values.location.search : '';
      const geolocation = values?.location?.selectedPlace?.origin;

      const mapProps = geolocation
        ? config.maps.fuzzy.enabled
          ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, null) }
          : { address, center: geolocation }
        : null;
      const map = geolocation ? (
        <CardMap
          containerClassName={css.mapContainer}
          mapClassName={css.mapsContainer}
          center={geolocation}
          circle={false}
        />
      ) : null;

      const docIcon = css.docIcon;
      const addPersonIcon = css.addPersonIcon;
      const calendarIcon = css.calendarIcon;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          {/* <div>
            <h2 className={css.label}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.nameLabel" />
            </h2>
            <label className={css.subLabel}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.nameSubLabel" />
            </label>
            <FieldTextInput
              id="name"
              name="name"
              className={css.title}
              type="text"
              // label={titleMessage}
              iconCss={addPersonIcon}
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.EditListingDescriptionForm.namePlaceholder',
              })}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required('Name required'), maxLength60Message)}
            />
          </div> */}
          <div>
            <h2 className={css.label}>Choose a username</h2>
            <label className={css.subLabel}>Make it unique!</label>
            <FieldTextInput
              className={css.title}
              type="text"
              iconCss={css.usernameIcon}
              id={'username'}
              name="username"
              autoComplete="given-name"
              placeholder="Choose a username"
              customErrorText={
                updateUserNameError
                  ? intl.formatMessage({
                      id: updateUserNameError,
                    })
                  : null
              }
              validate={composeValidators(
                required('Username required'),
                usernameFormatValid(
                  'Username should be between 8-20 characters and avoid special characters and spaces '
                )
              )}
            />
          </div>

          <div>
            <h2 className={css.label}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.descLabel" />
            </h2>
            <label className={css.subLabel}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.descSublabel" />
            </label>
            <FieldTextInput
              id="description"
              name="description"
              className={css.title}
              type="textarea"
              // label={titleMessage}
              // iconCss={docIcon}
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.EditListingDescriptionForm.descPlaceholder',
              })}
              maxLength={DESC_MAX_LENGTH}
              validate={composeValidators(maxLength180Message)}
            />
          </div>
          <div>
            <h2 className={css.label}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.photoLabel" />
            </h2>
            <label className={css.subLabel}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.photoSublabel" />
            </label>
            <AddImages
              className={css.imagesField}
              images={images}
              thumbnailClassName={css.thumbnail}
              savedImageAltText={intl.formatMessage({
                id: 'EditListingPhotosForm.savedImageAltText',
              })}
              onRemoveImage={onRemoveImage}
            >
              <Field
                id="addImage"
                name="addImage"
                accept={ACCEPT_IMAGES}
                form={null}
                label={chooseImageText}
                type="file"
                className={css.addImageButton}
                disabled={imageUploadRequested}
              >
                {fieldprops => {
                  const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                  const { name, type } = input;
                  const onChange = e => {
                    const file = e.target.files[0];
                    form.change(`addImage`, file);
                    form.blur(`addImage`);
                    onImageUploadHandler(file);
                  };
                  const inputProps = { accept, id: name, name, onChange, type };
                  return (
                    <div className={css.addImageWrapper}>
                      <div className={css.aspectRatioWrapper}>
                        {fieldDisabled ? null : (
                          <input {...inputProps} className={css.addImageInput} />
                        )}
                        <label htmlFor={name} className={css.addImage}>
                          {label}
                        </label>
                      </div>
                    </div>
                  );
                }}
              </Field>

              <Field
                component={props => {
                  const { input, meta } = props;
                  return (
                    <div className={css.imageRequiredWrapper}>
                      <input {...input} />
                      <ValidationError fieldMeta={meta} />
                    </div>
                  );
                }}
                name="images"
                type="hidden"
                validate={composeValidators(nonEmptyArray('Image is Required'))}
              />
            </AddImages>
          </div>

          <div>
            <h2 className={css.label}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.dobLabel" />
            </h2>
            <label className={css.subLabel}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.dobSublabel" />
            </label>
            <DateOfBirth
              id="dob"
              name="dob"
              iconCss={calendarIcon}
              className={css.title}
              type="text"
              // label={titleMessage}
              maxLength={TITLE_MAX_LENGTH}
              //  validate={composeValidators(required('Gender is required'), maxLength60Message)}
            />
            <span className={css.error}>{dateInvalidMessage}</span>
          </div>

          <div>
            <h2 className={css.label}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.genderLabel" />
            </h2>
            {/* <label className={css.subLabel}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.genderSublabel"/>
            </label> */}
            {/* <FieldTextInput
              id="gender"
              name="gender"
              className={css.title}
              type="text"
              // label={titleMessage}
              iconCss={addPersonIcon}
              placeholder={'Enter your gender'}
              maxLength={GENDER_MAX_LENGTH}
              validate={composeValidators(required('Gender is required'), maxLength10Message)}
            /> */}
            <FieldSelectModern
              className={css.gender}
              id="gender"
              name="gender"
              inputRootClass={calendarIcon}
              options={config.custom.genderOptions}
              placeholder={'Select Gender'}
            />
          </div>
          <div>
            <h2 className={css.label}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.raceLabel" />
            </h2>

            <FieldSelectModern
              className={css.gender}
              id="race"
              name="race"
              inputRootClass={calendarIcon}
              options={config.custom.raceOptions}
              placeholder={'Select Race'}
            />
          </div>
          <div>
            <h2 className={css.label}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.professionLabel" />
            </h2>

            <FieldSelectModern
              className={css.gender}
              id="profession"
              name="profession"
              inputRootClass={calendarIcon}
              options={professions}
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.EditListingDescriptionForm.professionSublabel',
              })}
            />
          </div>
          <div>
            <h2 className={css.label}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.universityLabel" />
            </h2>

            <FieldSelectModern
              className={css.gender}
              id="university"
              name="university"
              inputRootClass={calendarIcon}
              options={universities}
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.EditListingDescriptionForm.universityPlaceholder',
              })}
            />
          </div>

          <div>
            <h2 className={css.label}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.locationLabel" />
            </h2>
            <label className={css.subLabel}>
              <FormattedMessage id="EditListingDescriptionForm.EditListingDescriptionForm.locationSublabel" />
            </label>

            <LocationAutocompleteInputField
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.EditListingDescriptionForm.locationPlaceholder',
              })}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              validate={composeValidators(
                autocompleteSearchRequired(addressRequiredMessage),
                autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
            />

            {map}
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
