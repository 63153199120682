import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { maxLength, nonEmptyArray, required, composeValidators } from '../../util/validators';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { FieldArray } from 'react-final-form-arrays';

import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldTextInput,
  FieldSelectModern,
} from '../../components';
import css from './EditListingPricingForm.module.css';
import { IoMdAddCircle } from 'react-icons/io';
import { AiFillCloseCircle } from 'react-icons/ai';

const { Money } = sdkTypes;
const DESC_MAX_LENGTH = 180;
const TITLE_MAX_LENGTH = 40;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;
      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });
      const maxLength180Message = maxLength(
        'Description should be not more than 180 letters',
        DESC_MAX_LENGTH
      );
      const maxLength60Message = maxLength(
        'Title should be not more than 40 letters',
        TITLE_MAX_LENGTH
      );

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <div>
            <h2 className={css.label}>
              <FormattedMessage id="EditListingPricingForm.EditListingPricingForm.mainLabel" />
            </h2>
            <label className={css.subLabel}>
              <FormattedMessage id="EditListingPricingForm.EditListingPricingForm.mainSublabel" />
            </label>
            <FieldArray name="prices">
              {({ fields }) => (
                <div className={css.pricingMainContainer}>
                  {fields.map((name, index) => (
                    <div key={name} className={css.eachPriceEdit}>
                      <div className={css.bodCloseContainer}>
                        <span onClick={() => fields.remove(index)}>
                          <AiFillCloseCircle className={css.close} />
                        </span>
                      </div>

                      <FieldTextInput
                        className={css.title}
                        type="text"
                        autoFocus
                        isUncontrolled
                        id={`${name}.title`}
                        inputRootClass={css.titleInput}
                        name={`${name}.title`}
                        maxLength={TITLE_MAX_LENGTH}
                        validate={composeValidators(
                          required(
                            intl.formatMessage({
                              id: 'EditListingPricingForm.EditListingPricingForm.validateTextTitle',
                            })
                          ),
                          maxLength60Message
                        )}
                        // label={companyRepresentativeNameLabel}
                        placeholder={intl.formatMessage({
                          id: 'EditListingPricingForm.EditListingPricingForm.packPlacehoder',
                        })}
                      />
                      <FieldTextInput
                        className={css.description}
                        isUncontrolled
                        type="textarea"
                        inputRootClass={css.descInput}
                        id={`${name}.description`}
                        name={`${name}.description`}
                        // label={"companyRepresentativeNameLabel"}
                        validate={composeValidators(maxLength180Message)}
                        maxLength={DESC_MAX_LENGTH}
                        placeholder={intl.formatMessage({
                          id: 'EditListingPricingForm.EditListingPricingForm.descPlaceholder',
                        })}
                      />
                      <FieldSelectModern
                        id={`${name}.method`}
                        name={`${name}.method`}
                        className={css.typeField}
                        options={config.custom.tierMethodOptions}
                        label={intl.formatMessage({
                          id: 'EditListingPricingForm.EditListingPricingForm.methodLabel',
                        })}
                        validate={validators.fieldSelectModernRequired(
                          intl.formatMessage({
                            id:
                              'EditListingPricingForm.EditListingPricingForm.methodValidateMessage',
                          })
                        )}
                        placeholder={intl.formatMessage({
                          id: 'EditListingPricingForm.EditListingPricingForm.methodPlaceholder',
                        })}
                      />
                      <FieldCurrencyInput
                        id={`${name}.price`}
                        name={`${name}.price`}
                        className={css.priceField}
                        label={intl.formatMessage({
                          id: 'EditListingPricingForm.EditListingPricingForm.priceLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'EditListingPricingForm.EditListingPricingForm.pricePlaceHoder',
                        })}
                        currencyConfig={config.currencyConfig}
                        validate={priceValidators}
                      />
                    </div>
                  ))}
                  <button
                    className={css.addButton}
                    type="button"
                    onClick={() =>
                      fields.push({
                        title: '',
                        description: '',
                        type: '',
                        subType: '',
                        method: '',
                      })
                    }
                  >
                    {intl.formatMessage({
                      id: 'EditListingPricingForm.EditListingPricingForm.addPackageMessage',
                    })}
                    <div>
                      <IoMdAddCircle className={css.addButtonIcon} />
                    </div>
                    {/* {addMessage} */}
                  </button>
                </div>
              )}
            </FieldArray>
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
