import { professions } from './professions';
import { universities } from './universities';

/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
const year = new Date().getFullYear() - 18;
let yearsArr = [];
for (var i = 1922; i < year; i++) {
  yearsArr.push(i);
}
export const genderOptions = [
  { label: 'Male', key: 'male', value: 'male' },
  { label: 'Female', key: 'female', value: 'female' },
  { label: 'Other', key: 'other', value: 'other' },
];

export const instaFollowersOptions = [
  { label: '1 - 1k', key: '1 - 1000', value: '1 - 1000' },
  { label: '1k - 5k', key: '1000 - 5000', value: '1000 - 5000' },
  { label: '5k- 10k', key: '5000 - 10000', value: '5000 - 10000' },
  { label: '10k - 50k', key: '10000 - 50000', value: '10000 - 50000' },
  { label: '50k - 100k', key: '50000 - 100000', value: '50000 - 100000' },
  { label: '100k - 500k', key: '100000 - 500000', value: '100000 - 500000' },
  { label: '500k - 1000k', key: '500000 - 1000000', value: '500000 - 1000000' },
  { label: '1M - 5M', key: '1000000 - 5000000', value: '1000000 - 5000000' },
  { label: '5M+', key: '5M+', value: '5000000+' },
];

export const daysOptions = [
  { value: '1', key: '1', label: '1' },
  { value: '2', key: '2', label: '2' },
  { value: '3', key: '3', label: '3' },
  { value: '4', key: '4', label: '4' },
  { value: '5', key: '5', label: '5' },
  { value: '6', key: '6', label: '6' },
  { value: '7', key: '7', label: '7' },
  { value: '8', key: '8', label: '8' },
  { value: '9', key: '9', label: '9' },
  { value: '10', key: '10', label: '10' },

  { value: '11', key: '11', label: '11' },
  { value: '12', key: '12', label: '12' },
  { value: '13', key: '13', label: '13' },
  { value: '14', key: '14', label: '14' },
  { value: '15', key: '15', label: '15' },
  { value: '16', key: '16', label: '16' },
  { value: '17', key: '17', label: '17' },
  { value: '18', key: '18', label: '18' },
  { value: '19', key: '19', label: '19' },
  { value: '20', key: '20', label: '20' },
  { value: '21', key: '21', label: '21' },

  { value: '22', key: '22', label: '22' },
  { value: '23', key: '23', label: '23' },
  { value: '24', key: '24', label: '24' },
  { value: '25', key: '25', label: '25' },
  { value: '26', key: '26', label: '26' },
  { value: '27', key: '27', label: '27' },
  { value: '28', key: '28', label: '28' },
  { value: '29', key: '29', label: '29' },
  { value: '30', key: '30', label: '30' },
  { value: '31', key: '31', label: '31' },
];
export const monthsOptions = [
  { value: '1', key: '1', label: 'Jan' },
  { value: '2', key: '2', label: 'Feb' },
  { value: '3', key: '3', label: 'Mar' },
  { value: '4', key: '4', label: 'Apr' },
  { value: '5', key: '5', label: 'May' },
  { value: '6', key: '6', label: 'Jun' },
  { value: '7', key: '7', label: 'Jul' },
  { value: '8', key: '8', label: 'Aug' },
  { value: '9', key: '9', label: 'Sep' },
  { value: '10', key: '10', label: 'Oct' },
  { value: '11', key: '11', label: 'Nov' },
  { value: '12', key: '12', label: 'Dec' },
];

export const yearsOptions = yearsArr.map(each => {
  return { value: each, label: each, key: each };
});

export const tierTypeOptions = [
  { label: 'Online', key: 'online', value: 'online' },
  { label: 'In-Person', key: 'offline', value: 'offline' },
  { label: 'Both ', key: 'both', value: 'both' },
];
export const raceOptions = [
  { label: 'Not Selected', key: '', value: '' },

  { label: 'Asian', key: 'asian', value: 'asian' },
  { label: 'American Indian / Native American', key: 'native', value: 'native' },
  { label: 'Hispanic', key: 'hispanic', value: 'hispanic' },
  { label: 'Black', key: 'black', value: 'black' },
  { label: 'White', key: 'white', value: 'white' },
  { label: 'Prefer Not To Say', key: 'not', value: 'not' },
];

export const tierSubTypeOptions = [
  { label: 'Just a message', key: 'justMessage', value: 'justMessage' },
  { label: 'Offline', key: 'offline', value: 'offline' },
  { label: 'Both ', key: 'both', value: 'both' },
];
export const tierMethodOptions = [
  { label: 'Online', key: 'online', value: 'online' },
  { label: 'In-Person', key: 'offline', value: 'offline' },
  { label: 'Both ', key: 'both', value: 'both' },
];

export const media = [
  {
    id: 'extra-fast-delivery',
    title: 'Extra fast delivery',
    description: 'Your order will be delivered in 3 days, not 5.',
  },
  {
    id: 'i-have-got-proof',
    title: "I've got proof!",
    description: 'Your tester will provide screenshots of the chats.',
  },
  {
    title: 'Ask for social media',
    id: 'ask-for-social-media',
    description: 'The tester will ask the person of your choice for their social media account.',
  },
  {
    title: 'Trustful',
    id: 'trustful',
    description: 'The tester will keep in touch with your SO for at least a week.',
  },
  {
    title: 'Try and start a conversation',
    id: 'try-and-start-a-conversation',
    description: 'The tester will try to get your SO to start a coversation with them.',
  },
];
export const ageFilter = [
  { label: '18 years old', key: '2004' },
  { label: '19 years old', key: '2003' },
  { label: '20 years old', key: '2002' },
  { label: '21 years old', key: '2001' },
  { label: '22 years old', key: '2000' },
  { label: '23 years old', key: '1999' },
  { label: '24 years old', key: '1998' },
  { label: '25 years old', key: '1997' },
  { label: '26 years old', key: '1996' },
  { label: '27 years old', key: '1995' },
  { label: '28 years old', key: '1994' },
  { label: '29 years old', key: '1993' },
  { label: '30 years old', key: '1992' },
  { label: '31 years old', key: '1991' },
  { label: '32 years old', key: '1990' },
  { label: '33 years old', key: '1989' },
  { label: '34 years old', key: '1988' },
  { label: '35 years old', key: '1987' },
  { label: '36 years old', key: '1986' },
  { label: '37 years old', key: '1985' },
  { label: '38 years old', key: '1984' },
  { label: '39 years old', key: '1983' },
  { label: '40 years old', key: '1982' },
  { label: '41 years old', key: '1981' },
  { label: '42 years old', key: '1980' },
  { label: '43 years old', key: '1979' },
  { label: '44 years old', key: '1978' },
  { label: '45 years old', key: '1977' },
  { label: '46 years old', key: '1976' },
  { label: '47 years old', key: '1975' },
  { label: '48 years old', key: '1974' },
  { label: '49 years old', key: '1973' },
  { label: '50 years old', key: '1972' },
  { label: '51 years old', key: '1971' },
];
export const filters = [
  // {
  //   id: 'dates-length',
  //   label: 'Dates',
  //   type: 'BookingDateRangeLengthFilter',
  //   group: 'primary',
  //   // Note: BookingDateRangeFilter is fixed filter,
  //   // you can't change "queryParamNames: ['dates'],"
  //   queryParamNames: ['dates', 'minDuration'],
  //   config: {
  //     // A global time zone to use in availability searches. As listings
  //     // can be in various time zones, we must decide what time zone we
  //     // use in search when looking for available listings within a
  //     // certain time interval.
  //     //
  //     // If you have all/most listings in a certain time zone, change this
  //     // config value to that.
  //     //
  //     // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  //     searchTimeZone: 'Etc/UTC',

  //     // Options for the minimum duration of the booking
  //     options: [
  //       { key: '0', label: 'Any length' },
  //       { key: '60', label: '1 hour', shortLabel: '1h' },
  //       { key: '120', label: '2 hours', shortLabel: '2h' },
  //     ],
  //   },
  // },
  {
    id: 'age',
    label: 'Age',
    type: 'CustomFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_age'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 18,
      max: 95,
      step: 1,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  // {
  //   id: 'yogaStyles',
  //   label: 'Yoga styles',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_yogaStyles'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'ashtanga', label: 'Ashtanga' },
  //       { key: 'hatha', label: 'Hatha' },
  //       { key: 'kundalini', label: 'Kundalini' },
  //       { key: 'restorative', label: 'Restorative' },
  //       { key: 'vinyasa', label: 'Vinyasa' },
  //       { key: 'yin', label: 'Yin' },
  //     ],
  //   },
  // },
  {
    id: 'gender',
    label: 'Gender',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_gender'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...genderOptions],
    },
  },
  {
    id: 'race',
    label: 'What race do you identify as?',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_race'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: raceOptions?.filter(s => s.label != 'Not Selected'),
    },
  },
  {
    id: 'instaFollowersOptions',
    label: 'Followers',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_instaFollowers'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: instaFollowersOptions,
    },
  },
  {
    id: 'profession',
    label: 'Profession',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_profession'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: professions?.filter(profession => profession.label != 'Not Selected'),
    },
  },
  // {
  //   id: 'age',
  //   label: 'Age',
  //   type: 'SelectSingleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_age'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: ageFilter,
  //   },
  // },
  {
    id: 'universities',
    label: 'Universities',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_universities'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: universities?.filter(university => university.label != 'Not Selected'),
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },
    { key: 'meta_rating,meta_totalReviews', label: 'Highest Rating' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
