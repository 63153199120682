import React, { useEffect } from 'react';

import { bool, func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ensureOwnListing, ensureCurrentUser } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import useInitialValues from '../UseInitialValuesHook/useInitialValues';
import { useSelector } from 'react-redux';
import { professions } from '../../professions';
import { universities } from '../../universities';

import css from './EditListingDescriptionPanel.module.css';
import {
  updateProfileAndUserName,
  clearUpdatedForm,
} from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

const EditListingDescriptionPanelComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    clearForm,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    updateInProgress,

    onUpdateProfileAndUserName,
    updateUserNameInProgress,
    updateUserNameError,

    images,
    errors,
  } = props;

  useEffect(() => {
    clearForm();
  }, []);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const user = useSelector(state => state?.user?.currentUser);
  const currentUser = ensureCurrentUser(user);

  const userProfile = currentUser?.attributes?.profile;
  const { firstName, lastName, metadata } = userProfile;

  const { geolocation, description, publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingDescriptionPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  let oldInitialValues = {};
  oldInitialValues = {
    ...oldInitialValues,
    ...{
      username: (firstName ? String(firstName) : '') + ' ',
      gender: publicData.gender
        ? config.custom?.genderOptions?.find(e => e.key == String(publicData.gender))
        : '',

      race: publicData.race
        ? config.custom?.raceOptions?.find(e => e.key == String(publicData.race))
        : '',

      profession: publicData.profession
        ? professions?.find(e => e.key == String(publicData.profession))
        : '',

      university: publicData.university
        ? universities?.find(e => e.key == String(publicData.university))
        : '',

      description: description,
      images,
      geolocation: geolocation,
      dob: publicData.dob,
      day: publicData.dob
        ? config.custom?.daysOptions?.find(e => e.key == String(publicData.dob)?.split('-')[2])
        : '',
      month: publicData.dob
        ? config.custom?.monthsOptions?.find(e => e.key == String(publicData.dob)?.split('-')[1])
        : '',
      year: publicData.dob
        ? config.custom?.yearsOptions?.find(e => e.key == String(publicData.dob)?.split('-')[0])
        : '',

      location: publicData.location?.address
        ? {
            search: publicData?.location?.address || '',
            selectedPlace: { address: publicData?.location?.address, origin: geolocation },
          }
        : {},
    },
  };
  const { initialValues, updateInitialValues } = useInitialValues(oldInitialValues);

  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  return (
    <div className={classes}>
      {/* <h1 className={css.title}>{panelTitle}</h1> */}
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{ ...initialValues }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            name,
            username,
            gender,
            race,
            university,
            profession,
            description,
            year,
            images,
            month,
            day,
            location,
          } = values;
          const {
            selectedPlace: { address, origin },
          } = location;
          const age = parseInt(year?.key);

          updateInitialValues({
            username: username.trim(),
            gender: gender,
            race: race,
            profession: profession,
            university: university,

            geolocation: origin,
            description: description,
            year: year,
            month: month,
            day: day,
            location: { search: address, selectedPlace: { address, origin } },
          });
          const updateValues = {
            title: username.trim(),
            images,
            description: description?.trim() || '',
            geolocation: origin,
            publicData: {
              name: name?.trim(),
              gender: gender?.value,
              race: race?.value,
              age,
              university: university?.value,
              profession: profession?.value,
              dob:
                year?.value && month?.value && day?.value
                  ? String(year?.value) + '-' + String(month?.value) + '-' + String(day?.value)
                  : '',
              location: { address },
            },
          };
          onUpdateProfileAndUserName({ firstName: username.trim(), username }).then(a => {
            onSubmit(updateValues);
          });
        }}
        listingId={currentListing.id}
        geolocation={geolocation}
        publicData={publicData}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        onUpdateImageOrder={onUpdateImageOrder}
        onRemoveImage={onRemoveImage}
        updateUserNameInProgress={updateUserNameInProgress}
        updateUserNameError={updateUserNameError}
        onImageUpload={onImageUpload}
        images={images}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        certificateOptions={certificateOptions}
      />
    </div>
  );
};

EditListingDescriptionPanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingDescriptionPanelComponent.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

const mapStateToProps = state => {
  const { updateUserNameInProgress, updateUserNameError } = state.ProfileSettingsPage;
  return {
    updateUserNameInProgress: updateUserNameInProgress,
    updateUserNameError,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfileAndUserName: data => dispatch(updateProfileAndUserName(data)),
  clearForm: () => dispatch(clearUpdatedForm()),
});

const EditListingDescriptionPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(EditListingDescriptionPanelComponent);

export default EditListingDescriptionPanel;
