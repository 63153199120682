import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
// ================ Action types ================ //

const PRODUCTS_FETCH_REQUEST = 'app/ChooseTesterPage/PRODUCTS_FETCH_REQUEST';
const PRODUCTS_FETCH_SUCCESS = 'app/ChooseTesterPage/PRODUCTS_FETCH_SUCCESS';
const PRODUCTS_FETCH_ERROR = 'app/ChooseTesterPage/PRODUCTS_FETCH_ERROR';

// ================ Reducer ================ //

const initialState = {
  productsLoading: false,
  productIds: null,
  productsError: null,
};
const resultIds = data => data.map(l => l.id);
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case PRODUCTS_FETCH_REQUEST:
      return { ...state, productsLoading: true, productsError: null };
    case PRODUCTS_FETCH_SUCCESS:
      return {
        ...state,
        productsLoading: false,
        productsError: null,
        productIds: resultIds(payload),
      };
    case PRODUCTS_FETCH_ERROR:
      return {
        ...state,
        productsLoading: false,
        productIds: null,
        productsError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchProductsRequest = () => ({ type: PRODUCTS_FETCH_REQUEST });
export const fetchProductsSuccess = payload => ({ type: PRODUCTS_FETCH_SUCCESS, payload });
export const fetchProductsError = error => ({ type: PRODUCTS_FETCH_ERROR, payload: error });

export const getAllListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchProductsRequest());
  console.log('getAllListings');

  return sdk.listings
    .query({
      include: ['images', 'author'],
      'fields.listing': [
        'title',
        'metadata',
        'geolocation',
        'price',
        'publicData',
        'createdAt',
        'sortingOrder',
      ],
      'fields.image': [
        'variants.default',
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.square-small',
        'variants.square-small2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
        'variants.square-xsmall',

        // 80x80
        'variants.square-xsmall2x',
      ],
      'limit.images': 1,
      perPage: 30,
      meta_featured: true,
      // sort: 'meta_sortingOrder',
    })
    .then(response => {
      // console.log('getAllListings', response);

      dispatch(addMarketplaceEntities(response));
      const denormalisedResponse = denormalisedResponseEntities(response);
      dispatch(fetchProductsSuccess(denormalisedResponse));

      return response;
    })
    .catch(e => {
      dispatch(fetchProductsError(storableError(e)));
      throw e;
    });
};

export const loadData = params => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(getAllListings())])
    .then(responses => {
      return responses;
    })
    .catch(e => {
      throw e;
    });
};

// export const loadData = () => {
//   // Since verify email happens in separate tab, current user's data might be updated
//   return getAllListings();
// };
