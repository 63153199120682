import React, { useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FiSend } from 'react-icons/fi';
import { MdAddPhotoAlternate } from 'react-icons/md';
import { FieldTextInput, Form, IconSpinner } from '../../components';
import css from './MessagingForm.module.css';
import axios from 'axios';

function MessagingForm(props) {
  const uploadInputRef = useRef();
  const [progress, setProgress] = useState(0);

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          handleSubmit,
          values,
          invalid,
          messagePlaceholder,
          inProgress,
          sendMessageError,
          form,
          txId,
        } = fieldRenderProps;

        const handleIconClick = () => {
          uploadInputRef.current.click();
        };

        const handleUpload = event => {
          const image = event.target.files[0];

          if (!image) {
            window.alert('Please select an image first.');
            return;
          }

          setProgress(true);

          const formData = new FormData();
          formData.append('image', image);

          axios
            .post(
              `${process.env.REACT_APP_CANONICAL_ROOT_URL}/api/image-upload?txId=${txId}`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            )
            .then(response => {
              form.change('downloadURL', response.data.downloadURL);
              handleSubmit();
            })
            .catch(error => {
              const alertMessage = error.response.data?.error
                ? error.response.data.error
                : 'Failed to upload image';
              window.alert(alertMessage);
            })
            .finally(() => setProgress(false));
        };

        const submitDisabled = invalid || !values?.message || inProgress || progress;

        return (
          <Form onSubmit={handleSubmit}>
            {sendMessageError && <p className={css.messageSendError}>{sendMessageError}</p>}
            <div className={css.root}>
              <FieldTextInput
                id="message"
                name="message"
                type="textarea"
                placeholder={messagePlaceholder}
                className={css.messageInput}
                style={{ width: '95%', padding: '1rem' }}
                disabled={inProgress}
              />

              <button
                type="submit"
                className={css.sendButton}
                data-inprogress={inProgress && !values?.downloadURL}
                disabled={submitDisabled}
              >
                {inProgress && !values?.downloadURL ? (
                  <IconSpinner className={css.loadingIcon} />
                ) : (
                  <FiSend />
                )}
              </button>

              <button
                type="button"
                className={css.uploadButton}
                onClick={handleIconClick}
                disabled={inProgress || progress}
              >
                {inProgress && values?.downloadURL ? (
                  <IconSpinner className={css.loadingIcon} />
                ) : progress ? (
                  <IconSpinner className={css.loadingIcon} />
                ) : (
                  <MdAddPhotoAlternate />
                )}
                <input
                  type="file"
                  ref={uploadInputRef}
                  onChange={handleUpload}
                  className={css.imageUploader}
                  accept="image/*"
                />
              </button>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default MessagingForm;
