import React, { createContext, useContext, useState } from 'react';

export const TabContext = createContext();

export const TabProvider = ({ children }) => {
  const [active, setActive] = useState(0);
  const setActiveTab = tab => () => {
    console.log('changing tab to', typeof tab == 'function' ? active + 1 : tab);
    return setActive(tab);
  };
  const clearPreviousActiveTab = () => {
    console.log('Clearing active tab history');
    setActive(0);
  };

  return (
    <TabContext.Provider value={{ active, setActiveTab, clearPreviousActiveTab }}>
      {children}
    </TabContext.Provider>
  );
};

export const withTab = Component => {
  const WrappedComponent = props => {
    const { active, setActiveTab, clearPreviousActiveTab } = useContext(TabContext);
    return (
      <Component
        {...props}
        active={active}
        setActiveTab={setActiveTab}
        clearPreviousActiveTab={clearPreviousActiveTab}
      />
    );
  };

  return WrappedComponent;
};
