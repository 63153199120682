import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import useInitialValues from '../UseInitialValuesHook/useInitialValues';
const moment = require('moment');

import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  let oldInitialValues = {};
  oldInitialValues = {
    ...oldInitialValues,
    price: price,
    prices: publicData?.prices
      ? publicData.prices.map(each => {
          return {
            ...each,

            method: config.custom.tierMethodOptions.find(e => e.key == each.method),
            // type: config.custom.tierTypeOptions.find(e => e.key == each.type),
            // subType: config.custom.tierSubTypeOptions.find(e => e.key == each.subType),

            price: each?.price ? new Money(each?.price, config.currency) : null,
          };
        })
      : [{ title: '' }],
  };
  const { initialValues, updateInitialValues } = useInitialValues(oldInitialValues);
  console.log;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingPricingPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={initialValues}
      onSubmit={values => {
        const { prices } = values;
        updateInitialValues({
          prices: prices,
        });
        const lowestPrice = prices?.reduce((prev, curr) => {
          return prev.price.amount < curr.price.amount ? prev : curr;
        });
        // console.log('lowestPrice', lowestPrice?.price);
        const updateValues = {
          price: lowestPrice?.price,
          publicData: {
            prices: prices?.map(each => {
              // console.log(
              //   moment(each.date.toISOString())
              //     .utcOffset(0, true)
              //     .toISOString()
              // );
              if (!each?.price?.amount) return null;
              return {
                ...each,
                method: config.custom.tierMethodOptions.find(e => e.key == each.method.key).key,
                // type: config.custom.tierTypeOptions.find(e => e.key == each.type.key).key,
                // subType: config.custom.tierSubTypeOptions.find(e => e.key == each.subType.key).key,

                price: each?.price?.amount,
              };
            }),
          },
        };
        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return <div className={classes}>{form}</div>;
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
