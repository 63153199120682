import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      {/* <p className={css.lastUpdated}>Last updated: November 22, 2019</p> */}
      <p>
        At{' '}
        <a href="https://loyalty-test.com" target="_blank">
          Loyalty-Test.com{' '}
        </a>{' '}
        we are committed to protecting the privacy of our users. This privacy policy explains how we
        collect, use, and disclose personal information from our users. By using our website or
        services, you agree to the terms of this privacy policy.
      </p>

      <h2>Information Collection and Use</h2>
      <hr />
      <p>
        We only collect personal information from our users when they register for an account, use
        our service. The type of information we collect are username, email address (private). We do
        not collect any information about the test subjects, and the information entered by the user
        about the test subjects will be hidden in our system and will not be recorded.
      </p>
      <hr />

      <h2>Information Disclosure</h2>
      <hr />
      <p>
        We do not sell or otherwise disclose personal information to third parties without the
        user's consent, except as required by law or as necessary to provide our services.
      </p>
      <hr />
      <h2>Data Security</h2>
      <hr />
      <p>
        We take appropriate security measures to protect against unauthorized access, alteration,
        disclosure, or destruction of personal information. These measures include physical,
        electronic, and administrative safeguards.
      </p>
      <hr />
      <h2>User Choices</h2>
      <hr />
      <p>
        Users can access and update their personal information by logging into their account on our
        website. Users can also request that we delete their personal information or unsubscribe
        from communications by contacting us at{' '}
        <a href="mailto:contact@loyalty-test.com">contact@loyalty-test.com</a>
      </p>
      <hr />
      <h2>Changes to this Privacy Policy</h2>
      <hr />
      <p>
        We may update this privacy policy from time to time. We will notify users of any changes by
        posting the new privacy policy on our website.
      </p>
      <hr />
      <h2>Contact Information</h2>
      <hr />
      <p>
        If you have any questions or concerns about this privacy policy, please contact us at{' '}
        <a href="mailto:contact@loyalty-test.com"> contact@loyalty-test.com</a>
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
