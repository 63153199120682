export const universities = [
  { label: 'Not Selected', key: '', value: '' },

  {
    key: 'abilene_christian_university',
    value: 'abilene_christian_university',
    label: 'Abilene Christian University',
  },
  {
    key: 'academy_of_art_college',
    value: 'academy_of_art_college',
    label: 'Academy of Art College',
  },
  { key: 'adams_state_college', value: 'adams_state_college', label: 'Adams State College' },
  { key: 'adelphi_university', value: 'adelphi_university', label: 'Adelphi University' },
  {
    key: 'adler_school_of_professional_psychology',
    value: 'adler_school_of_professional_psychology',
    label: 'Adler School of Professional Psychology',
  },
  { key: 'adrian_college', value: 'adrian_college', label: 'Adrian College' },
  { key: 'agnes_scott_college', value: 'agnes_scott_college', label: 'Agnes Scott College' },
  {
    key: 'air_force_institute_of_technology',
    value: 'air_force_institute_of_technology',
    label: 'Air Force Institute of Technology',
  },
  {
    key: 'alabama_agricultural_and_mechanical_university',
    value: 'alabama_agricultural_and_mechanical_university',
    label: 'Alabama Agricultural and Mechanical University',
  },
  {
    key: 'alabama_state_university',
    value: 'alabama_state_university',
    label: 'Alabama State University',
  },
  { key: 'alaska_bible_college', value: 'alaska_bible_college', label: 'Alaska Bible College' },
  {
    key: 'alaska_pacific_university',
    value: 'alaska_pacific_university',
    label: 'Alaska Pacific University',
  },
  {
    key: 'albany_college_of_pharmacy',
    value: 'albany_college_of_pharmacy',
    label: 'Albany College of Pharmacy',
  },
  { key: 'albany_law_school', value: 'albany_law_school', label: 'Albany Law School' },
  { key: 'albany_medical_center', value: 'albany_medical_center', label: 'Albany Medical Center' },
  {
    key: 'albany_state_university',
    value: 'albany_state_university',
    label: 'Albany State University',
  },
  {
    key: 'albertus_magnus_college',
    value: 'albertus_magnus_college',
    label: 'Albertus Magnus College',
  },
  { key: 'albion_college', value: 'albion_college', label: 'Albion College' },
  { key: 'albright_college', value: 'albright_college', label: 'Albright College' },
  {
    key: 'alcorn_state_university',
    value: 'alcorn_state_university',
    label: 'Alcorn State University',
  },
  {
    key: 'alderson_broaddus_college',
    value: 'alderson_broaddus_college',
    label: 'Alderson Broaddus College',
  },
  {
    key: 'alfred_adler_graduate_school',
    value: 'alfred_adler_graduate_school',
    label: 'Alfred Adler Graduate School',
  },
  { key: 'alfred_university', value: 'alfred_university', label: 'Alfred University' },
  { key: 'alice_lloyd_college', value: 'alice_lloyd_college', label: 'Alice Lloyd College' },
  { key: 'allegheny_college', value: 'allegheny_college', label: 'Allegheny College' },
  { key: 'allen_university', value: 'allen_university', label: 'Allen University' },
  { key: 'alma_college', value: 'alma_college', label: 'Alma College' },
  { key: 'alvernia_college', value: 'alvernia_college', label: 'Alvernia College' },
  { key: 'alverno_college', value: 'alverno_college', label: 'Alverno College' },
  { key: 'ambassador_university', value: 'ambassador_university', label: 'Ambassador University' },
  { key: 'amber_university', value: 'amber_university', label: 'Amber University' },
  {
    key: 'american_academy_of_nutrition',
    value: 'american_academy_of_nutrition',
    label: 'American Academy of Nutrition',
  },
  {
    key: 'american_business_technology_university',
    value: 'american_business_technology_university',
    label: 'American Business & Technology University',
  },
  {
    key: 'american_conservatory_of_music',
    value: 'american_conservatory_of_music',
    label: 'American Conservatory of Music',
  },
  {
    key: 'american_conservatory_theater',
    value: 'american_conservatory_theater',
    label: 'American Conservatory Theater',
  },
  {
    key: 'american_european_school_of_management',
    value: 'american_european_school_of_management',
    label: 'American-European School of Management',
  },
  {
    key: 'american_film_institute_center_for_advanced_film_and_television_studies',
    value: 'american_film_institute_center_for_advanced_film_and_television_studies',
    label: 'American Film Institute Center for Advanced Film and Television Studies',
  },
  {
    key: 'american_indian_college',
    value: 'american_indian_college',
    label: 'American Indian College',
  },
  {
    key: 'american_inter_continental_university_atlanta',
    value: 'american_inter_continental_university_atlanta',
    label: 'American InterContinental University - Atlanta',
  },
  {
    key: 'american_inter_continental_university_ft_lauderdale',
    value: 'american_inter_continental_university_ft_lauderdale',
    label: 'American InterContinental University - Ft. Lauderdale',
  },
  {
    key: 'american_inter_continental_university_georgia',
    value: 'american_inter_continental_university_georgia',
    label: 'American InterContinental University - Georgia',
  },
  {
    key: 'american_inter_continental_university_online',
    value: 'american_inter_continental_university_online',
    label: 'American InterContinental University Online',
  },
  {
    key: 'american_international_college',
    value: 'american_international_college',
    label: 'American International College',
  },
  {
    key: 'american_jewish_university',
    value: 'american_jewish_university',
    label: 'American Jewish University',
  },
  {
    key: 'american_military_university',
    value: 'american_military_university',
    label: 'American Military University',
  },
  {
    key: 'american_public_university',
    value: 'american_public_university',
    label: 'American Public University',
  },
  { key: 'american_university', value: 'american_university', label: 'American University' },
  {
    key: 'american_world_university',
    value: 'american_world_university',
    label: 'American World University',
  },
  { key: 'amherst_college', value: 'amherst_college', label: 'Amherst College' },
  { key: 'anderson_college', value: 'anderson_college', label: 'Anderson College' },
  { key: 'anderson_university', value: 'anderson_university', label: 'Anderson University' },
  {
    key: 'andon_college_modesto',
    value: 'andon_college_modesto',
    label: 'Andon College - Modesto',
  },
  {
    key: 'andon_college_stockton',
    value: 'andon_college_stockton',
    label: 'Andon College - Stockton',
  },
  {
    key: 'andrew_jackson_university',
    value: 'andrew_jackson_university',
    label: 'Andrew Jackson University',
  },
  { key: 'andrews_university', value: 'andrews_university', label: 'Andrews University' },
  {
    key: 'angelo_state_university',
    value: 'angelo_state_university',
    label: 'Angelo State University',
  },
  { key: 'anna_maria_college', value: 'anna_maria_college', label: 'Anna Maria College' },
  {
    key: 'antioch_new_england_graduate_school',
    value: 'antioch_new_england_graduate_school',
    label: 'Antioch New England Graduate School',
  },
  { key: 'antioch_university', value: 'antioch_university', label: 'Antioch University' },
  {
    key: 'antioch_university_los_angeles',
    value: 'antioch_university_los_angeles',
    label: 'Antioch University Los Angeles',
  },
  {
    key: 'antioch_university_santa_barbara',
    value: 'antioch_university_santa_barbara',
    label: 'Antioch University Santa Barbara',
  },
  {
    key: 'antioch_university_seattle',
    value: 'antioch_university_seattle',
    label: 'Antioch University Seattle',
  },
  {
    key: 'appalachian_bible_college',
    value: 'appalachian_bible_college',
    label: 'Appalachian Bible College',
  },
  {
    key: 'appalachian_state_university',
    value: 'appalachian_state_university',
    label: 'Appalachian State University',
  },
  { key: 'aquinas_college', value: 'aquinas_college', label: 'Aquinas College' },
  { key: 'arcadia_university', value: 'arcadia_university', label: 'Arcadia University' },
  { key: 'argosy_university', value: 'argosy_university', label: 'Argosy University' },
  {
    key: 'argosy_university_hawaii',
    value: 'argosy_university_hawaii',
    label: 'Argosy University - Hawaii',
  },
  {
    key: 'arizona_christian_university',
    value: 'arizona_christian_university',
    label: 'Arizona Christian University',
  },
  {
    key: 'arizona_state_university',
    value: 'arizona_state_university',
    label: 'Arizona State University',
  },
  {
    key: 'arizona_state_university_downtown_phoenix_campus',
    value: 'arizona_state_university_downtown_phoenix_campus',
    label: 'Arizona State University, Downtown Phoenix Campus',
  },
  {
    key: 'arizona_state_university_polytechnic_campus',
    value: 'arizona_state_university_polytechnic_campus',
    label: 'Arizona State University, Polytechnic Campus',
  },
  {
    key: 'arizona_state_university_tempe_campus',
    value: 'arizona_state_university_tempe_campus',
    label: 'Arizona State University, Tempe Campus',
  },
  {
    key: 'arizona_state_university_west_campus',
    value: 'arizona_state_university_west_campus',
    label: 'Arizona State University, West Campus',
  },
  {
    key: 'arkansas_state_university',
    value: 'arkansas_state_university',
    label: 'Arkansas State University',
  },
  {
    key: 'arkansas_state_university_beebe',
    value: 'arkansas_state_university_beebe',
    label: 'Arkansas State University, Beebe',
  },
  {
    key: 'arkansas_state_university_mountain_home',
    value: 'arkansas_state_university_mountain_home',
    label: 'Arkansas State University, Mountain Home',
  },
  {
    key: 'arkansas_state_university_newport',
    value: 'arkansas_state_university_newport',
    label: 'Arkansas State University, Newport',
  },
  {
    key: 'arkansas_tech_university',
    value: 'arkansas_tech_university',
    label: 'Arkansas Tech University',
  },
  {
    key: 'arlington_baptist_college',
    value: 'arlington_baptist_college',
    label: 'Arlington Baptist College',
  },
  {
    key: 'armstrong_atlantic_state_university',
    value: 'armstrong_atlantic_state_university',
    label: 'Armstrong Atlantic State University',
  },
  { key: 'armstrong_university', value: 'armstrong_university', label: 'Armstrong University' },
  {
    key: 'art_academy_of_cincinnati',
    value: 'art_academy_of_cincinnati',
    label: 'Art Academy of Cincinnati',
  },
  {
    key: 'art_center_college_of_design',
    value: 'art_center_college_of_design',
    label: 'Art Center College of Design',
  },
  {
    key: 'arthur_d_little_management_education_institute',
    value: 'arthur_d_little_management_education_institute',
    label: 'Arthur D. Little Management Education Institute',
  },
  {
    key: 'art_institute_of_charlotte',
    value: 'art_institute_of_charlotte',
    label: 'Art Institute of Charlotte',
  },
  {
    key: 'art_institute_of_southern_california',
    value: 'art_institute_of_southern_california',
    label: 'Art Institute of Southern California',
  },
  { key: 'asbury_college', value: 'asbury_college', label: 'Asbury College' },
  { key: 'ashland_university', value: 'ashland_university', label: 'Ashland University' },
  { key: 'assumption_college', value: 'assumption_college', label: 'Assumption College' },
  { key: 'athenaeum_of_ohio', value: 'athenaeum_of_ohio', label: 'Athenaeum of Ohio' },
  { key: 'athens_state_college', value: 'athens_state_college', label: 'Athens State College' },
  {
    key: 'atlanta_christian_college',
    value: 'atlanta_christian_college',
    label: 'Atlanta Christian College',
  },
  {
    key: 'atlanta_college_of_art',
    value: 'atlanta_college_of_art',
    label: 'Atlanta College of Art',
  },
  {
    key: 'atlantic_international_university',
    value: 'atlantic_international_university',
    label: 'Atlantic International University',
  },
  {
    key: 'atlantic_union_college',
    value: 'atlantic_union_college',
    label: 'Atlantic Union College',
  },
  { key: 'atlantic_university', value: 'atlantic_university', label: 'Atlantic University' },
  { key: 'auburn_university', value: 'auburn_university', label: 'Auburn University' },
  {
    key: 'auburn_university_at_montgomery',
    value: 'auburn_university_at_montgomery',
    label: 'Auburn University at Montgomery',
  },
  { key: 'audrey_cohen_college', value: 'audrey_cohen_college', label: 'Audrey Cohen College' },
  { key: 'augsburg_college', value: 'augsburg_college', label: 'Augsburg College' },
  { key: 'augustana_college', value: 'augustana_college', label: 'Augustana College' },
  { key: 'augustana_college', value: 'augustana_college', label: 'Augustana College' },
  {
    key: 'augusta_state_university',
    value: 'augusta_state_university',
    label: 'Augusta State University',
  },
  { key: 'aurora_university', value: 'aurora_university', label: 'Aurora University' },
  { key: 'austin_college', value: 'austin_college', label: 'Austin College' },
  {
    key: 'austin_community_college',
    value: 'austin_community_college',
    label: 'Austin Community College',
  },
  {
    key: 'austin_peay_state_university',
    value: 'austin_peay_state_university',
    label: 'Austin Peay State University',
  },
  { key: 'ave_maria_university', value: 'ave_maria_university', label: 'Ave Maria University' },
  { key: 'averett_college', value: 'averett_college', label: 'Averett College' },
  { key: 'avila_college', value: 'avila_college', label: 'Avila College' },
  {
    key: 'azusa_pacific_university',
    value: 'azusa_pacific_university',
    label: 'Azusa Pacific University',
  },
  { key: 'babson_college', value: 'babson_college', label: 'Babson College' },
  {
    key: 'baker_college_of_auburn_hills',
    value: 'baker_college_of_auburn_hills',
    label: 'Baker College of Auburn Hills',
  },
  {
    key: 'baker_college_of_cadillac',
    value: 'baker_college_of_cadillac',
    label: 'Baker College of Cadillac',
  },
  {
    key: 'baker_college_of_flint',
    value: 'baker_college_of_flint',
    label: 'Baker College of Flint',
  },
  {
    key: 'baker_college_of_mount_clemens',
    value: 'baker_college_of_mount_clemens',
    label: 'Baker College of Mount Clemens',
  },
  {
    key: 'baker_college_of_muskegon',
    value: 'baker_college_of_muskegon',
    label: 'Baker College of Muskegon',
  },
  {
    key: 'baker_college_of_owosso',
    value: 'baker_college_of_owosso',
    label: 'Baker College of Owosso',
  },
  {
    key: 'baker_college_of_port_huron',
    value: 'baker_college_of_port_huron',
    label: 'Baker College of Port Huron',
  },
  { key: 'baker_university', value: 'baker_university', label: 'Baker University' },
  {
    key: 'baldwin_wallace_college',
    value: 'baldwin_wallace_college',
    label: 'Baldwin-Wallace College',
  },
  { key: 'ball_state_university', value: 'ball_state_university', label: 'Ball State University' },
  {
    key: 'baltimore_hebrew_university',
    value: 'baltimore_hebrew_university',
    label: 'Baltimore Hebrew University',
  },
  {
    key: 'baltimore_international_college',
    value: 'baltimore_international_college',
    label: 'Baltimore International College',
  },
  {
    key: 'bank_street_college_of_education',
    value: 'bank_street_college_of_education',
    label: 'Bank Street College of Education',
  },
  {
    key: 'baptist_bible_college_of_missouri',
    value: 'baptist_bible_college_of_missouri',
    label: 'Baptist Bible College of Missouri',
  },
  {
    key: 'baptist_bible_college_of_pennsylvania',
    value: 'baptist_bible_college_of_pennsylvania',
    label: 'Baptist Bible College of Pennsylvania',
  },
  { key: 'barat_college', value: 'barat_college', label: 'Barat College' },
  { key: 'barber_scotia_college', value: 'barber_scotia_college', label: 'Barber-Scotia College' },
  { key: 'barclay_college', value: 'barclay_college', label: 'Barclay College' },
  { key: 'bard_college', value: 'bard_college', label: 'Bard College' },
  {
    key: 'bard_graduate_center_for_studies_in_the_decorative_arts',
    value: 'bard_graduate_center_for_studies_in_the_decorative_arts',
    label: 'Bard Graduate Center for Studies in the Decorative Arts',
  },
  {
    key: 'barnard_college_columbia_university',
    value: 'barnard_college_columbia_university',
    label: 'Barnard College - Columbia University',
  },
  { key: 'barry_university', value: 'barry_university', label: 'Barry University' },
  {
    key: 'bartlesville_wesleyan_college',
    value: 'bartlesville_wesleyan_college',
    label: 'Bartlesville Wesleyan College',
  },
  { key: 'barton_college', value: 'barton_college', label: 'Barton College' },
  { key: 'bastyr_university', value: 'bastyr_university', label: 'Bastyr University' },
  { key: 'bates_college', value: 'bates_college', label: 'Bates College' },
  { key: 'bauder_college', value: 'bauder_college', label: 'Bauder College' },
  {
    key: 'baylor_college_of_dentistry',
    value: 'baylor_college_of_dentistry',
    label: 'Baylor College of Dentistry',
  },
  {
    key: 'baylor_college_of_medicine',
    value: 'baylor_college_of_medicine',
    label: 'Baylor College of Medicine',
  },
  { key: 'baylor_university', value: 'baylor_university', label: 'Baylor University' },
  { key: 'belhaven_college', value: 'belhaven_college', label: 'Belhaven College' },
  { key: 'bellarmine_college', value: 'bellarmine_college', label: 'Bellarmine College' },
  { key: 'bellevue_university', value: 'bellevue_university', label: 'Bellevue University' },
  {
    key: 'bellin_college_of_nursing',
    value: 'bellin_college_of_nursing',
    label: 'Bellin College of Nursing',
  },
  { key: 'belmont_abbey_college', value: 'belmont_abbey_college', label: 'Belmont Abbey College' },
  { key: 'belmont_university', value: 'belmont_university', label: 'Belmont University' },
  { key: 'beloit_college', value: 'beloit_college', label: 'Beloit College' },
  {
    key: 'bemidji_state_univeristy',
    value: 'bemidji_state_univeristy',
    label: 'Bemidji State Univeristy',
  },
  { key: 'benedict_college', value: 'benedict_college', label: 'Benedict College' },
  { key: 'benedictine_college', value: 'benedictine_college', label: 'Benedictine College' },
  {
    key: 'benedictine_university',
    value: 'benedictine_university',
    label: 'Benedictine University',
  },
  {
    key: 'benedictine_university_springfield_college_in_illinois',
    value: 'benedictine_university_springfield_college_in_illinois',
    label: 'Benedictine University, Springfield College in Illinois',
  },
  { key: 'bennett_college', value: 'bennett_college', label: 'Bennett College' },
  { key: 'bennington_college', value: 'bennington_college', label: 'Bennington College' },
  { key: 'bentley_college', value: 'bentley_college', label: 'Bentley College' },
  { key: 'berea_college', value: 'berea_college', label: 'Berea College' },
  {
    key: 'berean_university_of_the_assemblies_of_god',
    value: 'berean_university_of_the_assemblies_of_god',
    label: 'Berean University of the Assemblies of God',
  },
  {
    key: 'berklee_college_of_music',
    value: 'berklee_college_of_music',
    label: 'Berklee College of Music',
  },
  { key: 'berne_university', value: 'berne_university', label: 'Berne University' },
  { key: 'berry_college', value: 'berry_college', label: 'Berry College' },
  {
    key: 'bethany_college_california',
    value: 'bethany_college_california',
    label: 'Bethany College California',
  },
  {
    key: 'bethany_college_kansas',
    value: 'bethany_college_kansas',
    label: 'Bethany College Kansas',
  },
  {
    key: 'bethany_college_west_virginia',
    value: 'bethany_college_west_virginia',
    label: 'Bethany College West Virginia',
  },
  {
    key: 'bethel_college_mc_kenzie',
    value: 'bethel_college_mc_kenzie',
    label: 'Bethel College McKenzie',
  },
  {
    key: 'bethel_college_mishawaka',
    value: 'bethel_college_mishawaka',
    label: 'Bethel College Mishawaka',
  },
  { key: 'bethel_college_newton', value: 'bethel_college_newton', label: 'Bethel College Newton' },
  {
    key: 'beth_el_college_of_nursing_and_health_sciences',
    value: 'beth_el_college_of_nursing_and_health_sciences',
    label: 'Beth-El College of Nursing and Health Sciences',
  },
  {
    key: 'bethel_college_st_paul',
    value: 'bethel_college_st_paul',
    label: 'Bethel College St. Paul',
  },
  {
    key: 'bethune_cookman_college',
    value: 'bethune_cookman_college',
    label: 'Bethune-Cookman College',
  },
  { key: 'biola_university', value: 'biola_university', label: 'Biola University' },
  {
    key: 'birmingham_southern_college',
    value: 'birmingham_southern_college',
    label: 'Birmingham-Southern College',
  },
  { key: 'blackburn_college', value: 'blackburn_college', label: 'Blackburn College' },
  { key: 'black_hawk_college', value: 'black_hawk_college', label: 'Black Hawk College' },
  {
    key: 'black_hills_state_university',
    value: 'black_hills_state_university',
    label: 'Black Hills State University',
  },
  {
    key: 'blessing_rieman_college_of_nursing',
    value: 'blessing_rieman_college_of_nursing',
    label: 'Blessing-Rieman College of Nursing',
  },
  { key: 'bloomfield_college', value: 'bloomfield_college', label: 'Bloomfield College' },
  {
    key: 'bloomsburg_university_of_pennsylvania',
    value: 'bloomsburg_university_of_pennsylvania',
    label: 'Bloomsburg University of Pennsylvania',
  },
  { key: 'bluefield_college', value: 'bluefield_college', label: 'Bluefield College' },
  {
    key: 'bluefield_state_college',
    value: 'bluefield_state_college',
    label: 'Bluefield State College',
  },
  { key: 'blue_mountain_college', value: 'blue_mountain_college', label: 'Blue Mountain College' },
  { key: 'bluffton_college', value: 'bluffton_college', label: 'Bluffton College' },
  { key: 'bob_jones_university', value: 'bob_jones_university', label: 'Bob Jones University' },
  { key: 'boise_bible_college', value: 'boise_bible_college', label: 'Boise Bible College' },
  {
    key: 'boise_state_university',
    value: 'boise_state_university',
    label: 'Boise State University',
  },
  { key: 'boricua_college', value: 'boricua_college', label: 'Boricua College' },
  {
    key: 'boston_architectural_center',
    value: 'boston_architectural_center',
    label: 'Boston Architectural Center',
  },
  { key: 'boston_college', value: 'boston_college', label: 'Boston College' },
  { key: 'boston_university', value: 'boston_university', label: 'Boston University' },
  { key: 'bowdoin_college', value: 'bowdoin_college', label: 'Bowdoin College' },
  {
    key: 'bowie_state_university',
    value: 'bowie_state_university',
    label: 'Bowie State University',
  },
  {
    key: 'bowling_green_state_university',
    value: 'bowling_green_state_university',
    label: 'Bowling Green State University',
  },
  {
    key: 'bowling_green_state_university_firelands',
    value: 'bowling_green_state_university_firelands',
    label: 'Bowling Green State University, Firelands',
  },
  { key: 'bradley_university', value: 'bradley_university', label: 'Bradley University' },
  { key: 'brandeis_university', value: 'brandeis_university', label: 'Brandeis University' },
  { key: 'brandman_university', value: 'brandman_university', label: 'Brandman University' },
  { key: 'brenau_university', value: 'brenau_university', label: 'Brenau University' },
  { key: 'brescia_university', value: 'brescia_university', label: 'Brescia University' },
  { key: 'brevard_college', value: 'brevard_college', label: 'Brevard College' },
  {
    key: 'brewton_parker_college',
    value: 'brewton_parker_college',
    label: 'Brewton-Parker College',
  },
  {
    key: 'breyer_state_university_virtual_university',
    value: 'breyer_state_university_virtual_university',
    label: 'Breyer State University (Virtual University)',
  },
  { key: 'briar_cliff_college', value: 'briar_cliff_college', label: 'Briar Cliff College' },
  { key: 'bridgewater_college', value: 'bridgewater_college', label: 'Bridgewater College' },
  {
    key: 'bridgewater_state_college',
    value: 'bridgewater_state_college',
    label: 'Bridgewater State College',
  },
  {
    key: 'brigham_young_university',
    value: 'brigham_young_university',
    label: 'Brigham Young University',
  },
  {
    key: 'brigham_young_university',
    value: 'brigham_young_university',
    label: 'Brigham Young University',
  },
  {
    key: 'brigham_young_university_hawaii',
    value: 'brigham_young_university_hawaii',
    label: 'Brigham Young University Hawaii',
  },
  { key: 'brooklyn_law_school', value: 'brooklyn_law_school', label: 'Brooklyn Law School' },
  {
    key: 'brooks_institute_of_photography',
    value: 'brooks_institute_of_photography',
    label: 'Brooks Institute of Photography',
  },
  { key: 'brown_university', value: 'brown_university', label: 'Brown University' },
  {
    key: 'brunswick_community_college',
    value: 'brunswick_community_college',
    label: 'Brunswick Community College',
  },
  { key: 'bryan_college', value: 'bryan_college', label: 'Bryan College' },
  {
    key: 'bryant_and_stratton_college',
    value: 'bryant_and_stratton_college',
    label: 'Bryant and Stratton College',
  },
  { key: 'bryant_university', value: 'bryant_university', label: 'Bryant University' },
  {
    key: 'bryn_athyn_college_of_the_new_church',
    value: 'bryn_athyn_college_of_the_new_church',
    label: 'Bryn Athyn College of the New Church',
  },
  { key: 'bryn_mawr_college', value: 'bryn_mawr_college', label: 'Bryn Mawr College' },
  { key: 'bucknell_university', value: 'bucknell_university', label: 'Bucknell University' },
  {
    key: 'buena_vista_university',
    value: 'buena_vista_university',
    label: 'Buena Vista University',
  },
  { key: 'burlington_college', value: 'burlington_college', label: 'Burlington College' },
  { key: 'butler_university', value: 'butler_university', label: 'Butler University' },
  { key: 'cabrini_college', value: 'cabrini_college', label: 'Cabrini College' },
  { key: 'caldwell_college', value: 'caldwell_college', label: 'Caldwell College' },
  {
    key: 'california_baptist_college',
    value: 'california_baptist_college',
    label: 'California Baptist College',
  },
  {
    key: 'california_coast_university',
    value: 'california_coast_university',
    label: 'California Coast University',
  },
  {
    key: 'california_college_for_health_sciences',
    value: 'california_college_for_health_sciences',
    label: 'California College for Health Sciences',
  },
  {
    key: 'california_college_of_arts_and_crafts',
    value: 'california_college_of_arts_and_crafts',
    label: 'California College of Arts and Crafts',
  },
  {
    key: 'california_college_of_podiatric_medicine',
    value: 'california_college_of_podiatric_medicine',
    label: 'California College of Podiatric Medicine',
  },
  {
    key: 'california_college_san_diego',
    value: 'california_college_san_diego',
    label: 'California College San Diego',
  },
  {
    key: 'california_institute_of_integral_studies',
    value: 'california_institute_of_integral_studies',
    label: 'California Institute of Integral Studies',
  },
  {
    key: 'california_institute_of_technology',
    value: 'california_institute_of_technology',
    label: 'California Institute of Technology',
  },
  {
    key: 'california_institute_of_the_arts',
    value: 'california_institute_of_the_arts',
    label: 'California Institute of the Arts',
  },
  {
    key: 'california_lutheran_university',
    value: 'california_lutheran_university',
    label: 'California Lutheran University',
  },
  {
    key: 'california_maritime_academy',
    value: 'california_maritime_academy',
    label: 'California Maritime Academy',
  },
  {
    key: 'california_polytechnic_state_university_san_luis_obispo',
    value: 'california_polytechnic_state_university_san_luis_obispo',
    label: 'California Polytechnic State University - San Luis Obispo',
  },
  {
    key: 'california_school_of_professional_psychology_berkley_alameda',
    value: 'california_school_of_professional_psychology_berkley_alameda',
    label: 'California School of Professional Psychology - Berkley/Alameda',
  },
  {
    key: 'california_school_of_professional_psychology_fresno',
    value: 'california_school_of_professional_psychology_fresno',
    label: 'California School of Professional Psychology - Fresno',
  },
  {
    key: 'california_school_of_professional_psychology_los_angels',
    value: 'california_school_of_professional_psychology_los_angels',
    label: 'California School of Professional Psychology - Los Angels',
  },
  {
    key: 'california_school_of_professional_psychology_san_diego',
    value: 'california_school_of_professional_psychology_san_diego',
    label: 'California School of Professional Psychology - San Diego',
  },
  {
    key: 'california_state_polytechnic_university_pomona',
    value: 'california_state_polytechnic_university_pomona',
    label: 'California State Polytechnic University - Pomona',
  },
  {
    key: 'california_state_university_bakersfield',
    value: 'california_state_university_bakersfield',
    label: 'California State University, Bakersfield',
  },
  {
    key: 'california_state_university_channel_islands',
    value: 'california_state_university_channel_islands',
    label: 'California State University, Channel Islands',
  },
  {
    key: 'california_state_university_chico',
    value: 'california_state_university_chico',
    label: 'California State University, Chico',
  },
  {
    key: 'california_state_university_dominguez_hills',
    value: 'california_state_university_dominguez_hills',
    label: 'California State University, Dominguez Hills',
  },
  {
    key: 'california_state_university_fresno',
    value: 'california_state_university_fresno',
    label: 'California State University, Fresno',
  },
  {
    key: 'california_state_university_fullerton',
    value: 'california_state_university_fullerton',
    label: 'California State University, Fullerton',
  },
  {
    key: 'california_state_university_hayward',
    value: 'california_state_university_hayward',
    label: 'California State University, Hayward',
  },
  {
    key: 'california_state_university_long_beach',
    value: 'california_state_university_long_beach',
    label: 'California State University, Long Beach',
  },
  {
    key: 'california_state_university_los_angeles',
    value: 'california_state_university_los_angeles',
    label: 'California State University, Los Angeles',
  },
  {
    key: 'california_state_university_monterey_bay',
    value: 'california_state_university_monterey_bay',
    label: 'California State University, Monterey Bay',
  },
  {
    key: 'california_state_university_northridge',
    value: 'california_state_university_northridge',
    label: 'California State University, Northridge',
  },
  {
    key: 'california_state_university_sacramento',
    value: 'california_state_university_sacramento',
    label: 'California State University, Sacramento',
  },
  {
    key: 'california_state_university_san_bernadino',
    value: 'california_state_university_san_bernadino',
    label: 'California State University, San Bernadino',
  },
  {
    key: 'california_state_university_san_marcos',
    value: 'california_state_university_san_marcos',
    label: 'California State University, San Marcos',
  },
  {
    key: 'california_state_university_stanislaus',
    value: 'california_state_university_stanislaus',
    label: 'California State University, Stanislaus',
  },
  {
    key: 'california_university_of_management_and_sciences',
    value: 'california_university_of_management_and_sciences',
    label: 'California University of Management and Sciences',
  },
  {
    key: 'california_university_of_pennsylvania',
    value: 'california_university_of_pennsylvania',
    label: 'California University of Pennsylvania',
  },
  {
    key: 'california_western_school_of_law',
    value: 'california_western_school_of_law',
    label: 'California Western School of Law',
  },
  {
    key: 'calumet_college_of_st_joseph',
    value: 'calumet_college_of_st_joseph',
    label: 'Calumet College of St. Joseph',
  },
  { key: 'calvary_bible_college', value: 'calvary_bible_college', label: 'Calvary Bible College' },
  { key: 'calvin_college', value: 'calvin_college', label: 'Calvin College' },
  { key: 'cambridge_college', value: 'cambridge_college', label: 'Cambridge College' },
  { key: 'cameron_university', value: 'cameron_university', label: 'Cameron University' },
  {
    key: 'campbellsville_college',
    value: 'campbellsville_college',
    label: 'Campbellsville College',
  },
  { key: 'campbell_university', value: 'campbell_university', label: 'Campbell University' },
  { key: 'canisius_college', value: 'canisius_college', label: 'Canisius College' },
  { key: 'capella_university', value: 'capella_university', label: 'Capella University' },
  { key: 'capital_university', value: 'capital_university', label: 'Capital University' },
  {
    key: 'capital_university_law_school',
    value: 'capital_university_law_school',
    label: 'Capital University Law School',
  },
  { key: 'capitol_college', value: 'capitol_college', label: 'Capitol College' },
  {
    key: 'cardinal_stritch_university',
    value: 'cardinal_stritch_university',
    label: 'Cardinal Stritch University',
  },
  { key: 'carleton_college', value: 'carleton_college', label: 'Carleton College' },
  {
    key: 'carlos_albizu_university',
    value: 'carlos_albizu_university',
    label: 'Carlos Albizu University',
  },
  { key: 'carlow_college', value: 'carlow_college', label: 'Carlow College' },
  {
    key: 'carnegie_mellon_university',
    value: 'carnegie_mellon_university',
    label: 'Carnegie Mellon University',
  },
  {
    key: 'carroll_college_helena',
    value: 'carroll_college_helena',
    label: 'Carroll College Helena',
  },
  {
    key: 'carroll_college_waukesha',
    value: 'carroll_college_waukesha',
    label: 'Carroll College Waukesha',
  },
  { key: 'carson_newman_college', value: 'carson_newman_college', label: 'Carson-Newman College' },
  { key: 'carthage_college', value: 'carthage_college', label: 'Carthage College' },
  {
    key: 'case_western_reserve_university',
    value: 'case_western_reserve_university',
    label: 'Case Western Reserve University',
  },
  {
    key: 'castleton_state_college',
    value: 'castleton_state_college',
    label: 'Castleton State College',
  },
  { key: 'catawba_college', value: 'catawba_college', label: 'Catawba College' },
  {
    key: 'catholic_theological_union',
    value: 'catholic_theological_union',
    label: 'Catholic Theological Union',
  },
  { key: 'cedar_crest_college', value: 'cedar_crest_college', label: 'Cedar Crest College' },
  { key: 'cedarville_college', value: 'cedarville_college', label: 'Cedarville College' },
  { key: 'centenary_college', value: 'centenary_college', label: 'Centenary College' },
  {
    key: 'centenary_college_of_louisiana',
    value: 'centenary_college_of_louisiana',
    label: 'Centenary College of Louisiana',
  },
  {
    key: 'center_for_humanistic_studies',
    value: 'center_for_humanistic_studies',
    label: 'Center for Humanistic Studies',
  },
  {
    key: 'central_baptist_college',
    value: 'central_baptist_college',
    label: 'Central Baptist College',
  },
  { key: 'central_bible_college', value: 'central_bible_college', label: 'Central Bible College' },
  {
    key: 'central_christian_college_of_the_bible',
    value: 'central_christian_college_of_the_bible',
    label: 'Central Christian College of the Bible',
  },
  { key: 'central_college', value: 'central_college', label: 'Central College' },
  {
    key: 'central_connecticut_state_university',
    value: 'central_connecticut_state_university',
    label: 'Central Connecticut State University',
  },
  {
    key: 'central_methodist_college',
    value: 'central_methodist_college',
    label: 'Central Methodist College',
  },
  {
    key: 'central_michigan_university',
    value: 'central_michigan_university',
    label: 'Central Michigan University',
  },
  {
    key: 'central_state_university',
    value: 'central_state_university',
    label: 'Central State University',
  },
  {
    key: 'central_washington_university',
    value: 'central_washington_university',
    label: 'Central Washington University',
  },
  { key: 'centre_college', value: 'centre_college', label: 'Centre College' },
  { key: 'chadron_state_college', value: 'chadron_state_college', label: 'Chadron State College' },
  {
    key: 'chaminade_university_of_honolulu',
    value: 'chaminade_university_of_honolulu',
    label: 'Chaminade University of Honolulu',
  },
  { key: 'champlain_college', value: 'champlain_college', label: 'Champlain College' },
  { key: 'chapman_university', value: 'chapman_university', label: 'Chapman University' },
  {
    key: 'charles_r_drew_university_of_medicine_and_science',
    value: 'charles_r_drew_university_of_medicine_and_science',
    label: 'Charles R. Drew University of Medicine and Science',
  },
  {
    key: 'charleston_southern_university',
    value: 'charleston_southern_university',
    label: 'Charleston Southern University',
  },
  {
    key: 'charter_oak_state_college',
    value: 'charter_oak_state_college',
    label: 'Charter Oak State College',
  },
  { key: 'chatham_college', value: 'chatham_college', label: 'Chatham College' },
  { key: 'chestnut_hill_college', value: 'chestnut_hill_college', label: 'Chestnut Hill College' },
  {
    key: 'cheyney_university_of_pennsylvania',
    value: 'cheyney_university_of_pennsylvania',
    label: 'Cheyney University of Pennsylvania',
  },
  {
    key: 'chicago_state_university',
    value: 'chicago_state_university',
    label: 'Chicago State University',
  },
  { key: 'chowan_college', value: 'chowan_college', label: 'Chowan College' },
  { key: 'christendom_college', value: 'christendom_college', label: 'Christendom College' },
  {
    key: 'christian_brothers_university',
    value: 'christian_brothers_university',
    label: 'Christian Brothers University',
  },
  {
    key: 'christian_heritage_college',
    value: 'christian_heritage_college',
    label: 'Christian Heritage College',
  },
  {
    key: 'christopher_newport_university',
    value: 'christopher_newport_university',
    label: 'Christopher Newport University',
  },
  {
    key: 'circleville_bible_college',
    value: 'circleville_bible_college',
    label: 'Circleville Bible College',
  },
  { key: 'city_university', value: 'city_university', label: 'City University' },
  {
    key: 'city_university_of_new_york_bernard_m_baruch_college',
    value: 'city_university_of_new_york_bernard_m_baruch_college',
    label: 'City University of New York, Bernard M. Baruch College',
  },
  {
    key: 'city_university_of_new_york_brooklyn_college',
    value: 'city_university_of_new_york_brooklyn_college',
    label: 'City University of New York, Brooklyn College',
  },
  {
    key: 'city_university_of_new_york_city_college',
    value: 'city_university_of_new_york_city_college',
    label: 'City University of New York, City College',
  },
  {
    key: 'city_university_of_new_york_college_of_staten_island',
    value: 'city_university_of_new_york_college_of_staten_island',
    label: 'City University of New York, College of Staten Island',
  },
  {
    key: 'city_university_of_new_york_cuny_system',
    value: 'city_university_of_new_york_cuny_system',
    label: 'City University of New York (CUNY) System',
  },
  {
    key: 'city_university_of_new_york_graduate_school_and_university_center',
    value: 'city_university_of_new_york_graduate_school_and_university_center',
    label: 'City University of New York, Graduate School and University Center',
  },
  {
    key: 'city_university_of_new_york_hunter_college',
    value: 'city_university_of_new_york_hunter_college',
    label: 'City University of New York, Hunter College',
  },
  {
    key: 'city_university_of_new_york_john_jay_college_of_criminal_justice',
    value: 'city_university_of_new_york_john_jay_college_of_criminal_justice',
    label: 'City University of New York, John Jay College of Criminal Justice',
  },
  {
    key: 'city_university_of_new_york_lehman_college',
    value: 'city_university_of_new_york_lehman_college',
    label: 'City University of New York, Lehman College',
  },
  {
    key: 'city_university_of_new_york_medgar_evers_college',
    value: 'city_university_of_new_york_medgar_evers_college',
    label: 'City University of New York, Medgar Evers College',
  },
  {
    key: 'city_university_of_new_york_medical_school_sophie_davis_school_of_biomedical_education',
    value: 'city_university_of_new_york_medical_school_sophie_davis_school_of_biomedical_education',
    label:
      'City University of New York Medical School / Sophie Davis School of Biomedical Education',
  },
  {
    key: 'city_university_of_new_york_new_york_city_technical_college',
    value: 'city_university_of_new_york_new_york_city_technical_college',
    label: 'City University of New York, New York City Technical College',
  },
  {
    key: 'city_university_of_new_york_queens_college',
    value: 'city_university_of_new_york_queens_college',
    label: 'City University of New York, Queens College',
  },
  {
    key: 'city_university_of_new_york_school_of_law_at_queens_college',
    value: 'city_university_of_new_york_school_of_law_at_queens_college',
    label: 'City University of New York, School of Law at Queens College',
  },
  {
    key: 'city_university_of_new_york_york_college',
    value: 'city_university_of_new_york_york_college',
    label: 'City University of New York, York College',
  },
  { key: 'claflin_college', value: 'claflin_college', label: 'Claflin College' },
  {
    key: 'claremont_graduate_university',
    value: 'claremont_graduate_university',
    label: 'Claremont Graduate University',
  },
  {
    key: 'claremont_lincoln_university',
    value: 'claremont_lincoln_university',
    label: 'Claremont Lincoln University',
  },
  {
    key: 'claremont_mc_kenna_college',
    value: 'claremont_mc_kenna_college',
    label: 'Claremont McKenna College',
  },
  { key: 'clarion_university', value: 'clarion_university', label: 'Clarion University' },
  {
    key: 'clark_atlanta_university',
    value: 'clark_atlanta_university',
    label: 'Clark Atlanta University',
  },
  { key: 'clarke_college', value: 'clarke_college', label: 'Clarke College' },
  { key: 'clarkson_college', value: 'clarkson_college', label: 'Clarkson College' },
  { key: 'clarkson_university', value: 'clarkson_university', label: 'Clarkson University' },
  { key: 'clark_university', value: 'clark_university', label: 'Clark University' },
  {
    key: 'clayton_college_state_university',
    value: 'clayton_college_state_university',
    label: 'Clayton College & State University',
  },
  {
    key: 'clearwater_christian_college',
    value: 'clearwater_christian_college',
    label: 'Clearwater Christian College',
  },
  { key: 'cleary_college', value: 'cleary_college', label: 'Cleary College' },
  { key: 'clemson_university', value: 'clemson_university', label: 'Clemson University' },
  {
    key: 'cleveland_chiropractic_college_kansas_city',
    value: 'cleveland_chiropractic_college_kansas_city',
    label: 'Cleveland Chiropractic College, Kansas City',
  },
  {
    key: 'cleveland_chiropractic_college_los_angeles',
    value: 'cleveland_chiropractic_college_los_angeles',
    label: 'Cleveland Chiropractic College, Los Angeles',
  },
  {
    key: 'cleveland_institute_of_art',
    value: 'cleveland_institute_of_art',
    label: 'Cleveland Institute of Art',
  },
  {
    key: 'cleveland_institute_of_music',
    value: 'cleveland_institute_of_music',
    label: 'Cleveland Institute of Music',
  },
  {
    key: 'cleveland_state_university',
    value: 'cleveland_state_university',
    label: 'Cleveland State University',
  },
  {
    key: 'coastal_carolina_university',
    value: 'coastal_carolina_university',
    label: 'Coastal Carolina University',
  },
  { key: 'coe_college', value: 'coe_college', label: 'Coe College' },
  {
    key: 'cogswell_polytechnical_college',
    value: 'cogswell_polytechnical_college',
    label: 'Cogswell Polytechnical College',
  },
  { key: 'coker_college', value: 'coker_college', label: 'Coker College' },
  { key: 'colby_college', value: 'colby_college', label: 'Colby College' },
  { key: 'colby_sawyer_college', value: 'colby_sawyer_college', label: 'Colby-Sawyer College' },
  { key: 'coleman_college', value: 'coleman_college', label: 'Coleman College' },
  { key: 'colgate_university', value: 'colgate_university', label: 'Colgate University' },
  {
    key: 'college_america_denver',
    value: 'college_america_denver',
    label: 'CollegeAmerica, Denver',
  },
  {
    key: 'college_america_phoenix',
    value: 'college_america_phoenix',
    label: 'CollegeAmerica, Phoenix',
  },
  {
    key: 'college_for_creative_studies',
    value: 'college_for_creative_studies',
    label: 'College for Creative Studies',
  },
  {
    key: 'college_for_financial_planning',
    value: 'college_for_financial_planning',
    label: 'College for Financial Planning',
  },
  {
    key: 'college_for_lifelong_learning',
    value: 'college_for_lifelong_learning',
    label: 'College for Lifelong Learning',
  },
  { key: 'college_misericordia', value: 'college_misericordia', label: 'College Misericordia' },
  {
    key: 'college_of_aeronautics',
    value: 'college_of_aeronautics',
    label: 'College of Aeronautics',
  },
  { key: 'college_of_charleston', value: 'college_of_charleston', label: 'College of Charleston' },
  { key: 'college_of_du_page', value: 'college_of_du_page', label: 'College of DuPage' },
  { key: 'college_of_idaho', value: 'college_of_idaho', label: 'College of Idaho' },
  {
    key: 'college_of_mount_st_joseph',
    value: 'college_of_mount_st_joseph',
    label: 'College of Mount St. Joseph',
  },
  {
    key: 'college_of_mount_st_vincent',
    value: 'college_of_mount_st_vincent',
    label: 'College of Mount St. Vincent',
  },
  {
    key: 'college_of_new_rochelle',
    value: 'college_of_new_rochelle',
    label: 'College of New Rochelle',
  },
  { key: 'college_of_notre_dame', value: 'college_of_notre_dame', label: 'College of Notre Dame' },
  {
    key: 'college_of_notre_dame_of_maryland',
    value: 'college_of_notre_dame_of_maryland',
    label: 'College of Notre Dame of Maryland',
  },
  {
    key: 'college_of_osteopathic_medicine_of_the_pacific_western_university_of_health_sciences',
    value: 'college_of_osteopathic_medicine_of_the_pacific_western_university_of_health_sciences',
    label: 'College of Osteopathic Medicine of the Pacific (Western University of Health Sciences)',
  },
  {
    key: 'college_of_st_benedict_and_st_john_s_university',
    value: 'college_of_st_benedict_and_st_john_s_university',
    label: "College of St. Benedict and St. John's University",
  },
  {
    key: 'college_of_st_catherine',
    value: 'college_of_st_catherine',
    label: 'College of St. Catherine',
  },
  {
    key: 'college_of_st_elizabeth',
    value: 'college_of_st_elizabeth',
    label: 'College of St. Elizabeth',
  },
  { key: 'college_of_st_joseph', value: 'college_of_st_joseph', label: 'College of St. Joseph' },
  { key: 'college_of_st_mary', value: 'college_of_st_mary', label: 'College of St. Mary' },
  { key: 'college_of_st_rose', value: 'college_of_st_rose', label: 'College of St. Rose' },
  {
    key: 'college_of_st_scholastica',
    value: 'college_of_st_scholastica',
    label: 'College of St. Scholastica',
  },
  {
    key: 'college_of_the_atlantic',
    value: 'college_of_the_atlantic',
    label: 'College of the Atlantic',
  },
  {
    key: 'college_of_the_holy_cross',
    value: 'college_of_the_holy_cross',
    label: 'College of the Holy Cross',
  },
  { key: 'college_of_the_ozarks', value: 'college_of_the_ozarks', label: 'College of the Ozarks' },
  {
    key: 'college_of_visual_arts',
    value: 'college_of_visual_arts',
    label: 'College of Visual Arts',
  },
  {
    key: 'college_of_william_and_mary',
    value: 'college_of_william_and_mary',
    label: 'College of William and Mary',
  },
  {
    key: 'colorado_christian_university',
    value: 'colorado_christian_university',
    label: 'Colorado Christian University',
  },
  { key: 'colorado_college', value: 'colorado_college', label: 'Colorado College' },
  {
    key: 'colorado_school_of_mines',
    value: 'colorado_school_of_mines',
    label: 'Colorado School of Mines',
  },
  {
    key: 'colorado_state_university',
    value: 'colorado_state_university',
    label: 'Colorado State University',
  },
  {
    key: 'colorado_state_university_pueblo',
    value: 'colorado_state_university_pueblo',
    label: 'Colorado State University-Pueblo',
  },
  {
    key: 'colorado_technical_university',
    value: 'colorado_technical_university',
    label: 'Colorado Technical University',
  },
  {
    key: 'columbia_college_chicago',
    value: 'columbia_college_chicago',
    label: 'Columbia College Chicago',
  },
  {
    key: 'columbia_college_hollywood',
    value: 'columbia_college_hollywood',
    label: 'Columbia College Hollywood',
  },
  {
    key: 'columbia_college_of_missouri',
    value: 'columbia_college_of_missouri',
    label: 'Columbia College of Missouri',
  },
  {
    key: 'columbia_college_south_carolina',
    value: 'columbia_college_south_carolina',
    label: 'Columbia College South Carolina',
  },
  {
    key: 'columbia_commonwealth_university',
    value: 'columbia_commonwealth_university',
    label: 'Columbia Commonwealth University',
  },
  {
    key: 'columbia_international_university',
    value: 'columbia_international_university',
    label: 'Columbia International University',
  },
  {
    key: 'columbia_southern_university',
    value: 'columbia_southern_university',
    label: 'Columbia Southern University',
  },
  {
    key: 'columbia_union_college',
    value: 'columbia_union_college',
    label: 'Columbia Union College',
  },
  { key: 'columbia_university', value: 'columbia_university', label: 'Columbia University' },
  {
    key: 'columbus_college_of_art_and_design',
    value: 'columbus_college_of_art_and_design',
    label: 'Columbus College of Art and Design',
  },
  {
    key: 'columbus_state_university',
    value: 'columbus_state_university',
    label: 'Columbus State University',
  },
  { key: 'columbus_university', value: 'columbus_university', label: 'Columbus University' },
  {
    key: 'community_college_of_denver',
    value: 'community_college_of_denver',
    label: 'Community College of Denver',
  },
  { key: 'concord_college', value: 'concord_college', label: 'Concord College' },
  {
    key: 'concordia_college_ann_arbor',
    value: 'concordia_college_ann_arbor',
    label: 'Concordia College, Ann Arbor',
  },
  {
    key: 'concordia_college_bronxville',
    value: 'concordia_college_bronxville',
    label: 'Concordia College, Bronxville',
  },
  {
    key: 'concordia_college_moorhead',
    value: 'concordia_college_moorhead',
    label: 'Concordia College, Moorhead',
  },
  {
    key: 'concordia_college_selma',
    value: 'concordia_college_selma',
    label: 'Concordia College, Selma',
  },
  {
    key: 'concordia_college_seward',
    value: 'concordia_college_seward',
    label: 'Concordia College, Seward',
  },
  {
    key: 'concordia_college_st_paul',
    value: 'concordia_college_st_paul',
    label: 'Concordia College, St. Paul',
  },
  {
    key: 'concordia_university_austin',
    value: 'concordia_university_austin',
    label: 'Concordia University, Austin',
  },
  {
    key: 'concordia_university_irvine',
    value: 'concordia_university_irvine',
    label: 'Concordia University, Irvine',
  },
  {
    key: 'concordia_university_mequon',
    value: 'concordia_university_mequon',
    label: 'Concordia University, Mequon',
  },
  {
    key: 'concordia_university_portland',
    value: 'concordia_university_portland',
    label: 'Concordia University, Portland',
  },
  {
    key: 'concordia_university_river_forest',
    value: 'concordia_university_river_forest',
    label: 'Concordia University, River Forest',
  },
  { key: 'connecticut_college', value: 'connecticut_college', label: 'Connecticut College' },
  { key: 'converse_college', value: 'converse_college', label: 'Converse College' },
  {
    key: 'conway_school_of_landscape_design',
    value: 'conway_school_of_landscape_design',
    label: 'Conway School of Landscape Design',
  },
  { key: 'coppin_state_college', value: 'coppin_state_college', label: 'Coppin State College' },
  { key: 'cornell_college', value: 'cornell_college', label: 'Cornell College' },
  { key: 'cornell_university', value: 'cornell_university', label: 'Cornell University' },
  {
    key: 'cornish_college_of_the_arts',
    value: 'cornish_college_of_the_arts',
    label: 'Cornish College of the Arts',
  },
  {
    key: 'cosmopolitan_university',
    value: 'cosmopolitan_university',
    label: 'Cosmopolitan University',
  },
  { key: 'covenant_college', value: 'covenant_college', label: 'Covenant College' },
  {
    key: 'cranbrook_academy_of_art',
    value: 'cranbrook_academy_of_art',
    label: 'Cranbrook Academy of Art',
  },
  { key: 'creighton_university', value: 'creighton_university', label: 'Creighton University' },
  { key: 'crichton_college', value: 'crichton_college', label: 'Crichton College' },
  { key: 'crown_college', value: 'crown_college', label: 'Crown College' },
  {
    key: 'culver_stockton_college',
    value: 'culver_stockton_college',
    label: 'Culver-Stockton College',
  },
  { key: 'cumberland_college', value: 'cumberland_college', label: 'Cumberland College' },
  { key: 'cumberland_university', value: 'cumberland_university', label: 'Cumberland University' },
  { key: 'curry_college', value: 'curry_college', label: 'Curry College' },
  { key: 'daemen_college', value: 'daemen_college', label: 'Daemen College' },
  {
    key: 'dakota_state_university',
    value: 'dakota_state_university',
    label: 'Dakota State University',
  },
  {
    key: 'dakota_wesleyan_university',
    value: 'dakota_wesleyan_university',
    label: 'Dakota Wesleyan University',
  },
  {
    key: 'dallas_baptist_university',
    value: 'dallas_baptist_university',
    label: 'Dallas Baptist University',
  },
  {
    key: 'dallas_christian_college',
    value: 'dallas_christian_college',
    label: 'Dallas Christian College',
  },
  { key: 'dana_college', value: 'dana_college', label: 'Dana College' },
  {
    key: 'daniel_webster_college',
    value: 'daniel_webster_college',
    label: 'Daniel Webster College',
  },
  {
    key: 'danville_area_community_college',
    value: 'danville_area_community_college',
    label: 'Danville Area Community College',
  },
  { key: 'dartmouth_college', value: 'dartmouth_college', label: 'Dartmouth College' },
  { key: 'darton_college', value: 'darton_college', label: 'Darton College' },
  {
    key: 'davenport_college_of_business_grand_rapids',
    value: 'davenport_college_of_business_grand_rapids',
    label: 'Davenport College of Business, Grand Rapids',
  },
  {
    key: 'davenport_college_of_business_kalamazoo',
    value: 'davenport_college_of_business_kalamazoo',
    label: 'Davenport College of Business, Kalamazoo',
  },
  {
    key: 'davenport_college_of_business_lansing',
    value: 'davenport_college_of_business_lansing',
    label: 'Davenport College of Business, Lansing',
  },
  { key: 'davidson_college', value: 'davidson_college', label: 'Davidson College' },
  {
    key: 'davis_and_elkins_college',
    value: 'davis_and_elkins_college',
    label: 'Davis and Elkins College',
  },
  {
    key: 'deaconess_college_of_nursing',
    value: 'deaconess_college_of_nursing',
    label: 'Deaconess College of Nursing',
  },
  {
    key: 'delaware_state_university',
    value: 'delaware_state_university',
    label: 'Delaware State University',
  },
  {
    key: 'delaware_valley_college',
    value: 'delaware_valley_college',
    label: 'Delaware Valley College',
  },
  {
    key: 'delta_international_university',
    value: 'delta_international_university',
    label: 'Delta International University',
  },
  {
    key: 'delta_state_university',
    value: 'delta_state_university',
    label: 'Delta State University',
  },
  { key: 'denison_university', value: 'denison_university', label: 'Denison University' },
  {
    key: 'denver_paralegal_institute',
    value: 'denver_paralegal_institute',
    label: 'Denver Paralegal Institute',
  },
  {
    key: 'denver_technical_college',
    value: 'denver_technical_college',
    label: 'Denver Technical College',
  },
  { key: 'de_paul_university', value: 'de_paul_university', label: 'DePaul University' },
  { key: 'de_pauw_university', value: 'de_pauw_university', label: 'DePauw University' },
  { key: 'de_sales_university', value: 'de_sales_university', label: 'DeSales University' },
  {
    key: 'design_institute_of_san_diego',
    value: 'design_institute_of_san_diego',
    label: 'Design Institute of San Diego',
  },
  {
    key: 'detroit_college_of_business',
    value: 'detroit_college_of_business',
    label: 'Detroit College of Business',
  },
  {
    key: 'detroit_college_of_business_flint',
    value: 'detroit_college_of_business_flint',
    label: 'Detroit College of Business - Flint',
  },
  {
    key: 'detroit_college_of_business_warren',
    value: 'detroit_college_of_business_warren',
    label: 'Detroit College of Business - Warren',
  },
  {
    key: 'detroit_college_of_law',
    value: 'detroit_college_of_law',
    label: 'Detroit College of Law',
  },
  {
    key: 'de_vry_institute_of_technology_chicago',
    value: 'de_vry_institute_of_technology_chicago',
    label: 'DeVry Institute of Technology, Chicago',
  },
  {
    key: 'de_vry_institute_of_technology_columbus',
    value: 'de_vry_institute_of_technology_columbus',
    label: 'DeVry Institute of Technology, Columbus',
  },
  {
    key: 'de_vry_institute_of_technology_decatur',
    value: 'de_vry_institute_of_technology_decatur',
    label: 'DeVry Institute of Technology, Decatur',
  },
  {
    key: 'de_vry_institute_of_technology_du_page',
    value: 'de_vry_institute_of_technology_du_page',
    label: 'DeVry Institute of Technology, DuPage',
  },
  {
    key: 'de_vry_institute_of_technology_irving',
    value: 'de_vry_institute_of_technology_irving',
    label: 'DeVry Institute of Technology, Irving',
  },
  {
    key: 'de_vry_institute_of_technology_kansas_city',
    value: 'de_vry_institute_of_technology_kansas_city',
    label: 'DeVry Institute of Technology, Kansas City',
  },
  {
    key: 'de_vry_institute_of_technology_phoenix',
    value: 'de_vry_institute_of_technology_phoenix',
    label: 'DeVry Institute of Technology, Phoenix',
  },
  {
    key: 'de_vry_institute_of_technology_pomona',
    value: 'de_vry_institute_of_technology_pomona',
    label: 'DeVry Institute of Technology, Pomona',
  },
  { key: 'dickinson_college', value: 'dickinson_college', label: 'Dickinson College' },
  {
    key: 'dickinson_state_university',
    value: 'dickinson_state_university',
    label: 'Dickinson State University',
  },
  { key: 'dillard_university', value: 'dillard_university', label: 'Dillard University' },
  { key: 'divine_word_college', value: 'divine_word_college', label: 'Divine Word College' },
  { key: 'dixie_college', value: 'dixie_college', label: 'Dixie College' },
  { key: 'doane_college', value: 'doane_college', label: 'Doane College' },
  { key: 'dominican_college', value: 'dominican_college', label: 'Dominican College' },
  {
    key: 'dominican_college_of_san_rafael',
    value: 'dominican_college_of_san_rafael',
    label: 'Dominican College of San Rafael',
  },
  {
    key: 'dominican_school_of_philosophy_and_theology',
    value: 'dominican_school_of_philosophy_and_theology',
    label: 'Dominican School of Philosophy and Theology',
  },
  { key: 'dominican_university', value: 'dominican_university', label: 'Dominican University' },
  { key: 'dominion_college', value: 'dominion_college', label: 'Dominion College' },
  { key: 'dordt_college', value: 'dordt_college', label: 'Dordt College' },
  { key: 'dowling_college', value: 'dowling_college', label: 'Dowling College' },
  { key: 'drake_university', value: 'drake_university', label: 'Drake University' },
  { key: 'drew_university', value: 'drew_university', label: 'Drew University' },
  { key: 'drexel_university', value: 'drexel_university', label: 'Drexel University' },
  { key: 'drury_college', value: 'drury_college', label: 'Drury College' },
  { key: 'duke_university', value: 'duke_university', label: 'Duke University' },
  {
    key: 'duluth_business_university',
    value: 'duluth_business_university',
    label: 'Duluth Business University',
  },
  { key: 'duquesne_university', value: 'duquesne_university', label: 'Duquesne University' },
  { key: 'd_youville_college', value: 'd_youville_college', label: "D'Youville College" },
  { key: 'earlham_college', value: 'earlham_college', label: 'Earlham College' },
  { key: 'earth_net_institute', value: 'earth_net_institute', label: 'EarthNet Institute' },
  {
    key: 'east_carolina_university',
    value: 'east_carolina_university',
    label: 'East Carolina University',
  },
  {
    key: 'east_central_university',
    value: 'east_central_university',
    label: 'East Central University',
  },
  { key: 'eastern_college', value: 'eastern_college', label: 'Eastern College' },
  {
    key: 'eastern_connecticut_state_university',
    value: 'eastern_connecticut_state_university',
    label: 'Eastern Connecticut State University',
  },
  {
    key: 'eastern_conservatory_of_music',
    value: 'eastern_conservatory_of_music',
    label: 'Eastern Conservatory of Music',
  },
  {
    key: 'eastern_illinois_university',
    value: 'eastern_illinois_university',
    label: 'Eastern Illinois University',
  },
  {
    key: 'eastern_kentucky_university',
    value: 'eastern_kentucky_university',
    label: 'Eastern Kentucky University',
  },
  {
    key: 'eastern_mennonite_university',
    value: 'eastern_mennonite_university',
    label: 'Eastern Mennonite University',
  },
  {
    key: 'eastern_michigan_university',
    value: 'eastern_michigan_university',
    label: 'Eastern Michigan University',
  },
  {
    key: 'eastern_nazarene_college',
    value: 'eastern_nazarene_college',
    label: 'Eastern Nazarene College',
  },
  {
    key: 'eastern_new_mexico_university',
    value: 'eastern_new_mexico_university',
    label: 'Eastern New Mexico University',
  },
  {
    key: 'eastern_oregon_university',
    value: 'eastern_oregon_university',
    label: 'Eastern Oregon University',
  },
  {
    key: 'eastern_virginia_medical_school',
    value: 'eastern_virginia_medical_school',
    label: 'Eastern Virginia Medical School',
  },
  {
    key: 'eastern_washington_university',
    value: 'eastern_washington_university',
    label: 'Eastern Washington University',
  },
  {
    key: 'east_stroudsburg_state_university',
    value: 'east_stroudsburg_state_university',
    label: 'East Stroudsburg State University',
  },
  {
    key: 'east_tennessee_state_university',
    value: 'east_tennessee_state_university',
    label: 'East Tennessee State University',
  },
  {
    key: 'east_texas_baptist_university',
    value: 'east_texas_baptist_university',
    label: 'East Texas Baptist University',
  },
  { key: 'east_west_university', value: 'east_west_university', label: 'East-West University' },
  { key: 'eckerd_college', value: 'eckerd_college', label: 'Eckerd College' },
  { key: 'edgewood_college', value: 'edgewood_college', label: 'Edgewood College' },
  {
    key: 'edinboro_university_of_pennsylvania',
    value: 'edinboro_university_of_pennsylvania',
    label: 'Edinboro University of Pennsylvania',
  },
  {
    key: 'edison_community_college',
    value: 'edison_community_college',
    label: 'Edison Community College',
  },
  { key: 'edward_waters_college', value: 'edward_waters_college', label: 'Edward Waters College' },
  {
    key: 'elizabeth_city_state_university',
    value: 'elizabeth_city_state_university',
    label: 'Elizabeth City State University',
  },
  { key: 'elizabethtown_college', value: 'elizabethtown_college', label: 'Elizabethtown College' },
  { key: 'elmhurst_college', value: 'elmhurst_college', label: 'Elmhurst College' },
  { key: 'elmira_college', value: 'elmira_college', label: 'Elmira College' },
  { key: 'elms_college', value: 'elms_college', label: 'Elms College' },
  { key: 'elon_college', value: 'elon_college', label: 'Elon College' },
  {
    key: 'embry_riddle_aeronautical_university',
    value: 'embry_riddle_aeronautical_university',
    label: 'Embry-Riddle Aeronautical University',
  },
  {
    key: 'embry_riddle_aeronautical_university_prescott',
    value: 'embry_riddle_aeronautical_university_prescott',
    label: 'Embry Riddle Aeronautical University, Prescott',
  },
  { key: 'emerson_college', value: 'emerson_college', label: 'Emerson College' },
  { key: 'emmanuel_college', value: 'emmanuel_college', label: 'Emmanuel College' },
  {
    key: 'emmanuel_college_georgia',
    value: 'emmanuel_college_georgia',
    label: 'Emmanuel College Georgia',
  },
  { key: 'emmaus_bible_college', value: 'emmaus_bible_college', label: 'Emmaus Bible College' },
  { key: 'emory_henry_college', value: 'emory_henry_college', label: 'Emory & Henry College' },
  { key: 'emory_university', value: 'emory_university', label: 'Emory University' },
  {
    key: 'emporia_state_university',
    value: 'emporia_state_university',
    label: 'Emporia State University',
  },
  { key: 'erskine_college', value: 'erskine_college', label: 'Erskine College' },
  { key: 'eugene_bible_college', value: 'eugene_bible_college', label: 'Eugene Bible College' },
  { key: 'eureka_college', value: 'eureka_college', label: 'Eureka College' },
  { key: 'evangel_university', value: 'evangel_university', label: 'Evangel University' },
  {
    key: 'evergreen_state_college',
    value: 'evergreen_state_college',
    label: 'Evergreen State College',
  },
  { key: 'excel_college', value: 'excel_college', label: 'Excel College' },
  { key: 'excelsior_college', value: 'excelsior_college', label: 'Excelsior College' },
  { key: 'fairfield_university', value: 'fairfield_university', label: 'Fairfield University' },
  {
    key: 'fairleigh_dickinson_university_florham_madison_campus',
    value: 'fairleigh_dickinson_university_florham_madison_campus',
    label: 'Fairleigh Dickinson University, Florham-Madison Campus',
  },
  {
    key: 'fairleigh_dickinson_university_teaneck_hackensack_campus',
    value: 'fairleigh_dickinson_university_teaneck_hackensack_campus',
    label: 'Fairleigh Dickinson University, Teaneck-Hackensack Campus',
  },
  {
    key: 'fairmont_state_college',
    value: 'fairmont_state_college',
    label: 'Fairmont State College',
  },
  {
    key: 'fashion_institute_of_new_york',
    value: 'fashion_institute_of_new_york',
    label: 'Fashion Institute of New York',
  },
  { key: 'faulkner_university', value: 'faulkner_university', label: 'Faulkner University' },
  {
    key: 'fayetteville_state_university',
    value: 'fayetteville_state_university',
    label: 'Fayetteville State University',
  },
  { key: 'felician_college', value: 'felician_college', label: 'Felician College' },
  {
    key: 'ferris_state_university',
    value: 'ferris_state_university',
    label: 'Ferris State University',
  },
  { key: 'ferrum_college', value: 'ferrum_college', label: 'Ferrum College' },
  { key: 'fielding_institute', value: 'fielding_institute', label: 'Fielding Institute' },
  {
    key: 'finch_university_of_health_sciences_the_chicago_medical_school',
    value: 'finch_university_of_health_sciences_the_chicago_medical_school',
    label: 'Finch University of Health Sciences - The Chicago Medical School',
  },
  { key: 'finlandia_university', value: 'finlandia_university', label: 'Finlandia University' },
  { key: 'fisher_college', value: 'fisher_college', label: 'Fisher College' },
  { key: 'fisk_university', value: 'fisk_university', label: 'Fisk University' },
  {
    key: 'fitchburg_state_college',
    value: 'fitchburg_state_college',
    label: 'Fitchburg State College',
  },
  { key: 'flagler_college', value: 'flagler_college', label: 'Flagler College' },
  {
    key: 'florida_agricultural_and_mechanical_university',
    value: 'florida_agricultural_and_mechanical_university',
    label: 'Florida Agricultural and Mechanical University',
  },
  {
    key: 'florida_atlantic_university',
    value: 'florida_atlantic_university',
    label: 'Florida Atlantic University',
  },
  {
    key: 'florida_christian_college',
    value: 'florida_christian_college',
    label: 'Florida Christian College',
  },
  {
    key: 'florida_community_college_at_jacksonville',
    value: 'florida_community_college_at_jacksonville',
    label: 'Florida Community College at Jacksonville',
  },
  {
    key: 'florida_gulf_coast_university',
    value: 'florida_gulf_coast_university',
    label: 'Florida Gulf Coast University',
  },
  {
    key: 'florida_institute_of_technology',
    value: 'florida_institute_of_technology',
    label: 'Florida Institute of Technology',
  },
  {
    key: 'florida_international_university',
    value: 'florida_international_university',
    label: 'Florida International University',
  },
  {
    key: 'florida_memorial_college',
    value: 'florida_memorial_college',
    label: 'Florida Memorial College',
  },
  {
    key: 'florida_metropolitan_university_fort_lauderdale_college',
    value: 'florida_metropolitan_university_fort_lauderdale_college',
    label: 'Florida Metropolitan University, Fort Lauderdale College',
  },
  {
    key: 'florida_metropolitan_university_tampa_college',
    value: 'florida_metropolitan_university_tampa_college',
    label: 'Florida Metropolitan University, Tampa College',
  },
  {
    key: 'florida_metropolitan_university_tampa_college_brandon',
    value: 'florida_metropolitan_university_tampa_college_brandon',
    label: 'Florida Metropolitan University, Tampa College Brandon',
  },
  {
    key: 'florida_metropolitan_university_tampa_college_lakeland',
    value: 'florida_metropolitan_university_tampa_college_lakeland',
    label: 'Florida Metropolitan University, Tampa College Lakeland',
  },
  {
    key: 'florida_southern_college',
    value: 'florida_southern_college',
    label: 'Florida Southern College',
  },
  {
    key: 'florida_state_university',
    value: 'florida_state_university',
    label: 'Florida State University',
  },
  {
    key: 'florida_university_of_medicine',
    value: 'florida_university_of_medicine',
    label: 'Florida University of Medicine',
  },
  { key: 'fontbonne_college', value: 'fontbonne_college', label: 'Fontbonne College' },
  {
    key: 'foothill_de_anza_community_college_district',
    value: 'foothill_de_anza_community_college_district',
    label: 'Foothill-De Anza Community College District',
  },
  { key: 'fordham_university', value: 'fordham_university', label: 'Fordham University' },
  {
    key: 'forest_institute_of_professional_psychology',
    value: 'forest_institute_of_professional_psychology',
    label: 'Forest Institute of Professional Psychology',
  },
  {
    key: 'fort_hays_state_university',
    value: 'fort_hays_state_university',
    label: 'Fort Hays State University',
  },
  { key: 'fort_lewis_college', value: 'fort_lewis_college', label: 'Fort Lewis College' },
  {
    key: 'fort_valley_state_college',
    value: 'fort_valley_state_college',
    label: 'Fort Valley State College',
  },
  {
    key: 'framingham_state_college',
    value: 'framingham_state_college',
    label: 'Framingham State College',
  },
  {
    key: 'franciscan_school_of_theology',
    value: 'franciscan_school_of_theology',
    label: 'Franciscan School of Theology',
  },
  {
    key: 'franciscan_university_of_steubenville',
    value: 'franciscan_university_of_steubenville',
    label: 'Franciscan University of Steubenville',
  },
  {
    key: 'francis_marion_university',
    value: 'francis_marion_university',
    label: 'Francis Marion University',
  },
  {
    key: 'franklin_and_marshall_college',
    value: 'franklin_and_marshall_college',
    label: 'Franklin and Marshall College',
  },
  { key: 'franklin_college', value: 'franklin_college', label: 'Franklin College' },
  {
    key: 'franklin_pierce_college',
    value: 'franklin_pierce_college',
    label: 'Franklin Pierce College',
  },
  {
    key: 'franklin_pierce_law_center',
    value: 'franklin_pierce_law_center',
    label: 'Franklin Pierce Law Center',
  },
  { key: 'franklin_university', value: 'franklin_university', label: 'Franklin University' },
  {
    key: 'franklin_w_olin_college_of_engineering',
    value: 'franklin_w_olin_college_of_engineering',
    label: 'Franklin W. Olin College of Engineering',
  },
  {
    key: 'frank_lloyd_wright_school_of_architecture',
    value: 'frank_lloyd_wright_school_of_architecture',
    label: 'Frank Lloyd Wright School of Architecture',
  },
  {
    key: 'fred_hutchinson_cancer_research_center',
    value: 'fred_hutchinson_cancer_research_center',
    label: 'Fred Hutchinson Cancer Research Center',
  },
  {
    key: 'freed_hardeman_university',
    value: 'freed_hardeman_university',
    label: 'Freed-Hardeman University',
  },
  {
    key: 'free_university_virginia_theological_university',
    value: 'free_university_virginia_theological_university',
    label: 'Free University (Virginia Theological University)',
  },
  {
    key: 'free_will_baptist_bible_college',
    value: 'free_will_baptist_bible_college',
    label: 'Free Will Baptist Bible College',
  },
  { key: 'fresno_city_college', value: 'fresno_city_college', label: 'Fresno City College' },
  {
    key: 'fresno_pacific_university',
    value: 'fresno_pacific_university',
    label: 'Fresno Pacific University',
  },
  { key: 'friends_university', value: 'friends_university', label: 'Friends University' },
  {
    key: 'frostburg_state_university',
    value: 'frostburg_state_university',
    label: 'Frostburg State University',
  },
  { key: 'full_sail_university', value: 'full_sail_university', label: 'Full Sail University' },
  { key: 'furman_university', value: 'furman_university', label: 'Furman University' },
  { key: 'gallaudet_university', value: 'gallaudet_university', label: 'Gallaudet University' },
  { key: 'gannon_university', value: 'gannon_university', label: 'Gannon University' },
  {
    key: 'gardner_webb_university',
    value: 'gardner_webb_university',
    label: 'Gardner Webb University',
  },
  {
    key: 'gemological_institute_of_america',
    value: 'gemological_institute_of_america',
    label: 'Gemological Institute of America',
  },
  { key: 'geneva_college', value: 'geneva_college', label: 'Geneva College' },
  { key: 'george_fox_university', value: 'george_fox_university', label: 'George Fox University' },
  {
    key: 'george_mason_university',
    value: 'george_mason_university',
    label: 'George Mason University',
  },
  { key: 'georgetown_college', value: 'georgetown_college', label: 'Georgetown College' },
  { key: 'georgetown_university', value: 'georgetown_university', label: 'Georgetown University' },
  {
    key: 'george_washington_university',
    value: 'george_washington_university',
    label: 'George Washington University',
  },
  { key: 'george_wythe_college', value: 'george_wythe_college', label: 'George Wythe College' },
  {
    key: 'georgia_baptist_college_of_nursing',
    value: 'georgia_baptist_college_of_nursing',
    label: 'Georgia Baptist College of Nursing',
  },
  {
    key: 'georgia_college_state_university',
    value: 'georgia_college_state_university',
    label: 'Georgia College & State University',
  },
  {
    key: 'georgia_health_sciences_university',
    value: 'georgia_health_sciences_university',
    label: 'Georgia Health Sciences University',
  },
  {
    key: 'georgia_institute_of_technology',
    value: 'georgia_institute_of_technology',
    label: 'Georgia Institute of Technology',
  },
  {
    key: 'georgian_court_college',
    value: 'georgian_court_college',
    label: 'Georgian Court College',
  },
  {
    key: 'georgia_school_of_professional_psychology',
    value: 'georgia_school_of_professional_psychology',
    label: 'Georgia School of Professional Psychology',
  },
  {
    key: 'georgia_southern_university',
    value: 'georgia_southern_university',
    label: 'Georgia Southern University',
  },
  {
    key: 'georgia_southwestern_university',
    value: 'georgia_southwestern_university',
    label: 'Georgia Southwestern University',
  },
  {
    key: 'georgia_state_university',
    value: 'georgia_state_university',
    label: 'Georgia State University',
  },
  { key: 'gettysburg_college', value: 'gettysburg_college', label: 'Gettysburg College' },
  {
    key: 'glendale_university_college_of_law',
    value: 'glendale_university_college_of_law',
    label: 'Glendale University College of Law',
  },
  {
    key: 'glenville_state_college',
    value: 'glenville_state_college',
    label: 'Glenville State College',
  },
  { key: 'goddard_college', value: 'goddard_college', label: 'Goddard College' },
  {
    key: 'god_s_bible_school_and_college',
    value: 'god_s_bible_school_and_college',
    label: "God's Bible School and College",
  },
  {
    key: 'golden_gate_university',
    value: 'golden_gate_university',
    label: 'Golden Gate University',
  },
  { key: 'goldey_beacom_college', value: 'goldey_beacom_college', label: 'Goldey-Beacom College' },
  { key: 'gonzaga_university', value: 'gonzaga_university', label: 'Gonzaga University' },
  { key: 'gordon_college', value: 'gordon_college', label: 'Gordon College' },
  {
    key: 'gordon_conwell_theological_seminary',
    value: 'gordon_conwell_theological_seminary',
    label: 'Gordon Conwell Theological Seminary',
  },
  { key: 'goshen_college', value: 'goshen_college', label: 'Goshen College' },
  { key: 'goucher_college', value: 'goucher_college', label: 'Goucher College' },
  {
    key: 'governors_state_university',
    value: 'governors_state_university',
    label: 'Governors State University',
  },
  { key: 'grace_bible_college', value: 'grace_bible_college', label: 'Grace Bible College' },
  { key: 'grace_college', value: 'grace_college', label: 'Grace College' },
  { key: 'graceland_college', value: 'graceland_college', label: 'Graceland College' },
  { key: 'grace_university', value: 'grace_university', label: 'Grace University' },
  {
    key: 'graduate_theological_union',
    value: 'graduate_theological_union',
    label: 'Graduate Theological Union',
  },
  {
    key: 'grambling_state_university',
    value: 'grambling_state_university',
    label: 'Grambling State University',
  },
  {
    key: 'grand_canyon_university',
    value: 'grand_canyon_university',
    label: 'Grand Canyon University',
  },
  {
    key: 'grand_valley_state_university',
    value: 'grand_valley_state_university',
    label: 'Grand Valley State University',
  },
  { key: 'grand_view_college', value: 'grand_view_college', label: 'Grand View College' },
  { key: 'grantham_university', value: 'grantham_university', label: 'Grantham University' },
  { key: 'gratz_college', value: 'gratz_college', label: 'Gratz College' },
  {
    key: 'great_lakes_christian_college',
    value: 'great_lakes_christian_college',
    label: 'Great Lakes Christian College',
  },
  {
    key: 'green_mountain_college',
    value: 'green_mountain_college',
    label: 'Green Mountain College',
  },
  { key: 'greensboro_college', value: 'greensboro_college', label: 'Greensboro College' },
  { key: 'greenville_college', value: 'greenville_college', label: 'Greenville College' },
  { key: 'grinnell_college', value: 'grinnell_college', label: 'Grinnell College' },
  { key: 'grove_city_college', value: 'grove_city_college', label: 'Grove City College' },
  { key: 'guilford_college', value: 'guilford_college', label: 'Guilford College' },
  {
    key: 'gustavus_adolphus_college',
    value: 'gustavus_adolphus_college',
    label: 'Gustavus Adolphus College',
  },
  { key: 'gwynedd_mercy_college', value: 'gwynedd_mercy_college', label: 'Gwynedd-Mercy College' },
  {
    key: 'hagerstown_community_college',
    value: 'hagerstown_community_college',
    label: 'Hagerstown Community College',
  },
  { key: 'hamilton_college', value: 'hamilton_college', label: 'Hamilton College' },
  {
    key: 'hamilton_technical_college',
    value: 'hamilton_technical_college',
    label: 'Hamilton Technical College',
  },
  { key: 'hamline_university', value: 'hamline_university', label: 'Hamline University' },
  {
    key: 'hampden_sydney_college',
    value: 'hampden_sydney_college',
    label: 'Hampden-Sydney College',
  },
  { key: 'hampshire_college', value: 'hampshire_college', label: 'Hampshire College' },
  { key: 'hampton_college', value: 'hampton_college', label: 'Hampton College' },
  { key: 'hampton_university', value: 'hampton_university', label: 'Hampton University' },
  {
    key: 'hannibal_lagrange_college',
    value: 'hannibal_lagrange_college',
    label: 'Hannibal-Lagrange College',
  },
  { key: 'hanover_college', value: 'hanover_college', label: 'Hanover College' },
  { key: 'harding_university', value: 'harding_university', label: 'Harding University' },
  {
    key: 'harding_university_graduate_school_of_religion',
    value: 'harding_university_graduate_school_of_religion',
    label: 'Harding University Graduate School of Religion',
  },
  {
    key: 'hardin_simmons_university',
    value: 'hardin_simmons_university',
    label: 'Hardin-Simmons University',
  },
  {
    key: 'harrington_institute_of_interior_design',
    value: 'harrington_institute_of_interior_design',
    label: 'Harrington Institute of Interior Design',
  },
  {
    key: 'harris_stowe_state_university',
    value: 'harris_stowe_state_university',
    label: 'Harris-Stowe State University',
  },
  {
    key: 'hartford_college_for_women',
    value: 'hartford_college_for_women',
    label: 'Hartford College for Women',
  },
  {
    key: 'hartford_graduate_center_rensselaer_at_hartford',
    value: 'hartford_graduate_center_rensselaer_at_hartford',
    label: 'Hartford Graduate Center (Rensselaer at Hartford)',
  },
  { key: 'hartwick_college', value: 'hartwick_college', label: 'Hartwick College' },
  { key: 'harvard_university', value: 'harvard_university', label: 'Harvard University' },
  { key: 'harvey_mudd_college', value: 'harvey_mudd_college', label: 'Harvey Mudd College' },
  { key: 'hastings_college', value: 'hastings_college', label: 'Hastings College' },
  { key: 'haverford_college', value: 'haverford_college', label: 'Haverford College' },
  {
    key: 'hawaii_pacific_university',
    value: 'hawaii_pacific_university',
    label: 'Hawaii Pacific University',
  },
  {
    key: 'heartland_baptist_bible_college',
    value: 'heartland_baptist_bible_college',
    label: 'Heartland Baptist Bible College',
  },
  { key: 'hebrew_college', value: 'hebrew_college', label: 'Hebrew College' },
  { key: 'heidelberg_college', value: 'heidelberg_college', label: 'Heidelberg College' },
  {
    key: 'henderson_state_univerisity',
    value: 'henderson_state_univerisity',
    label: 'Henderson State Univerisity',
  },
  { key: 'hendrix_college', value: 'hendrix_college', label: 'Hendrix College' },
  { key: 'heritage_college', value: 'heritage_college', label: 'Heritage College' },
  { key: 'hesser_college', value: 'hesser_college', label: 'Hesser College' },
  { key: 'high_point_university', value: 'high_point_university', label: 'High Point University' },
  { key: 'hilbert_college', value: 'hilbert_college', label: 'Hilbert College' },
  { key: 'hillsdale_college', value: 'hillsdale_college', label: 'Hillsdale College' },
  { key: 'hiram_college', value: 'hiram_college', label: 'Hiram College' },
  {
    key: 'hobart_and_william_smith_colleges',
    value: 'hobart_and_william_smith_colleges',
    label: 'Hobart and William Smith Colleges',
  },
  {
    key: 'hobe_sound_bible_college',
    value: 'hobe_sound_bible_college',
    label: 'Hobe Sound Bible College',
  },
  { key: 'hodges_university', value: 'hodges_university', label: 'Hodges University' },
  { key: 'hofstra_university', value: 'hofstra_university', label: 'Hofstra University' },
  { key: 'hollins_university', value: 'hollins_university', label: 'Hollins University' },
  { key: 'holy_apostles_college', value: 'holy_apostles_college', label: 'Holy Apostles College' },
  { key: 'holy_cross_college', value: 'holy_cross_college', label: 'Holy Cross College' },
  { key: 'holy_family_college', value: 'holy_family_college', label: 'Holy Family College' },
  { key: 'holy_names_college', value: 'holy_names_college', label: 'Holy Names College' },
  { key: 'hood_college', value: 'hood_college', label: 'Hood College' },
  { key: 'hope_college', value: 'hope_college', label: 'Hope College' },
  {
    key: 'hope_international_university',
    value: 'hope_international_university',
    label: 'Hope International University',
  },
  { key: 'houghton_college', value: 'houghton_college', label: 'Houghton College' },
  {
    key: 'houston_baptist_university',
    value: 'houston_baptist_university',
    label: 'Houston Baptist University',
  },
  {
    key: 'howard_payne_university',
    value: 'howard_payne_university',
    label: 'Howard Payne University',
  },
  { key: 'howard_university', value: 'howard_university', label: 'Howard University' },
  {
    key: 'humboldt_state_university',
    value: 'humboldt_state_university',
    label: 'Humboldt State University',
  },
  { key: 'humphreys_college', value: 'humphreys_college', label: 'Humphreys College' },
  { key: 'huntington_college', value: 'huntington_college', label: 'Huntington College' },
  { key: 'huron_university', value: 'huron_university', label: 'Huron University' },
  { key: 'husson_college', value: 'husson_college', label: 'Husson College' },
  {
    key: 'huston_tillotson_college',
    value: 'huston_tillotson_college',
    label: 'Huston-Tillotson College',
  },
  { key: 'ici_university', value: 'ici_university', label: 'ICI University' },
  { key: 'ict_college', value: 'ict_college', label: 'ICT College' },
  {
    key: 'idaho_state_university',
    value: 'idaho_state_university',
    label: 'Idaho State University',
  },
  { key: 'iglobal_university', value: 'iglobal_university', label: 'Iglobal University' },
  {
    key: 'illinois_benedictine_university',
    value: 'illinois_benedictine_university',
    label: 'Illinois Benedictine University',
  },
  { key: 'illinois_college', value: 'illinois_college', label: 'Illinois College' },
  {
    key: 'illinois_college_of_optometry',
    value: 'illinois_college_of_optometry',
    label: 'Illinois College of Optometry',
  },
  {
    key: 'illinois_institute_of_technology',
    value: 'illinois_institute_of_technology',
    label: 'Illinois Institute of Technology',
  },
  {
    key: 'illinois_school_of_professional_psychology_chicago_campus',
    value: 'illinois_school_of_professional_psychology_chicago_campus',
    label: 'Illinois School of Professional Psychology - Chicago Campus',
  },
  {
    key: 'illinois_school_of_professional_psychology_meadows_campus',
    value: 'illinois_school_of_professional_psychology_meadows_campus',
    label: 'Illinois School of Professional Psychology - Meadows Campus',
  },
  {
    key: 'illinois_state_university',
    value: 'illinois_state_university',
    label: 'Illinois State University',
  },
  {
    key: 'illinois_valley_community_college',
    value: 'illinois_valley_community_college',
    label: 'Illinois Valley Community College',
  },
  {
    key: 'illinois_wesleyan_university',
    value: 'illinois_wesleyan_university',
    label: 'Illinois Wesleyan University',
  },
  { key: 'immaculata_university', value: 'immaculata_university', label: 'Immaculata University' },
  { key: 'impac_university', value: 'impac_university', label: 'IMPAC University' },
  {
    key: 'indiana_institute_of_technologyy',
    value: 'indiana_institute_of_technologyy',
    label: 'Indiana Institute of Technologyy',
  },
  {
    key: 'indiana_state_university',
    value: 'indiana_state_university',
    label: 'Indiana State University',
  },
  {
    key: 'indiana_university_at_bloomington',
    value: 'indiana_university_at_bloomington',
    label: 'Indiana University at Bloomington',
  },
  {
    key: 'indiana_university_at_kokomo',
    value: 'indiana_university_at_kokomo',
    label: 'Indiana University at Kokomo',
  },
  {
    key: 'indiana_university_at_south_bend',
    value: 'indiana_university_at_south_bend',
    label: 'Indiana University at South Bend',
  },
  {
    key: 'indiana_university_east',
    value: 'indiana_university_east',
    label: 'Indiana University - East',
  },
  {
    key: 'indiana_university_northwest',
    value: 'indiana_university_northwest',
    label: 'Indiana University - Northwest',
  },
  {
    key: 'indiana_university_of_pennsylvania',
    value: 'indiana_university_of_pennsylvania',
    label: 'Indiana University of Pennsylvania',
  },
  {
    key: 'indiana_university_purdue_university_at_columbus',
    value: 'indiana_university_purdue_university_at_columbus',
    label: 'Indiana University-Purdue University at Columbus',
  },
  {
    key: 'indiana_university_purdue_university_at_fort_wayne',
    value: 'indiana_university_purdue_university_at_fort_wayne',
    label: 'Indiana University-Purdue University at Fort Wayne',
  },
  {
    key: 'indiana_university_purdue_university_at_indianapolis',
    value: 'indiana_university_purdue_university_at_indianapolis',
    label: 'Indiana University-Purdue University at Indianapolis',
  },
  {
    key: 'indiana_university_southeast',
    value: 'indiana_university_southeast',
    label: 'Indiana University - Southeast',
  },
  {
    key: 'indiana_university_system',
    value: 'indiana_university_system',
    label: 'Indiana University (System)',
  },
  {
    key: 'indiana_wesleyan_university',
    value: 'indiana_wesleyan_university',
    label: 'Indiana Wesleyan University',
  },
  {
    key: 'institute_of_clinical_social_work',
    value: 'institute_of_clinical_social_work',
    label: 'Institute of Clinical Social Work',
  },
  {
    key: 'institute_of_paper_science_and_technology',
    value: 'institute_of_paper_science_and_technology',
    label: 'Institute of Paper Science and Technology',
  },
  {
    key: 'institute_of_textile_technology',
    value: 'institute_of_textile_technology',
    label: 'Institute of Textile Technology',
  },
  {
    key: 'institute_of_transpersonal_psychology',
    value: 'institute_of_transpersonal_psychology',
    label: 'Institute of Transpersonal Psychology',
  },
  {
    key: 'intelli_tec_college_grand_junction',
    value: 'intelli_tec_college_grand_junction',
    label: 'IntelliTec College - Grand Junction',
  },
  {
    key: 'international_academy_of_merchandising_and_design_chicago',
    value: 'international_academy_of_merchandising_and_design_chicago',
    label: 'International Academy of  Merchandising and Design Chicago',
  },
  {
    key: 'international_academy_of_merchandising_and_design_tampa',
    value: 'international_academy_of_merchandising_and_design_tampa',
    label: 'International Academy of  Merchandising and Design Tampa',
  },
  {
    key: 'international_bible_college',
    value: 'international_bible_college',
    label: 'International Bible College',
  },
  { key: 'international_college', value: 'international_college', label: 'International College' },
  { key: 'iona_college', value: 'iona_college', label: 'Iona College' },
  {
    key: 'iowa_state_university_of_science_and_technology',
    value: 'iowa_state_university_of_science_and_technology',
    label: 'Iowa State University of Science and Technology',
  },
  { key: 'iowa_wesleyan_college', value: 'iowa_wesleyan_college', label: 'Iowa Wesleyan College' },
  { key: 'ithaca_college', value: 'ithaca_college', label: 'Ithaca College' },
  {
    key: 'itt_technical_institute_fort_wayne',
    value: 'itt_technical_institute_fort_wayne',
    label: 'ITT Technical Institute Fort Wayne',
  },
  {
    key: 'itt_technical_institute_indianapolis',
    value: 'itt_technical_institute_indianapolis',
    label: 'ITT Technical Institute Indianapolis',
  },
  {
    key: 'itt_technical_institute_maitland',
    value: 'itt_technical_institute_maitland',
    label: 'ITT Technical Institute Maitland',
  },
  {
    key: 'itt_technical_institute_portland',
    value: 'itt_technical_institute_portland',
    label: 'ITT Technical Institute Portland',
  },
  {
    key: 'itt_technical_institute_west_covina',
    value: 'itt_technical_institute_west_covina',
    label: 'ITT Technical Institute West Covina',
  },
  {
    key: 'jackson_state_university',
    value: 'jackson_state_university',
    label: 'Jackson State University',
  },
  {
    key: 'jacksonville_state_university',
    value: 'jacksonville_state_university',
    label: 'Jacksonville State University',
  },
  {
    key: 'jacksonville_university',
    value: 'jacksonville_university',
    label: 'Jacksonville University',
  },
  {
    key: 'james_madison_university',
    value: 'james_madison_university',
    label: 'James Madison University',
  },
  { key: 'jamestown_college', value: 'jamestown_college', label: 'Jamestown College' },
  {
    key: 'jarvis_christian_college',
    value: 'jarvis_christian_college',
    label: 'Jarvis Christian College',
  },
  { key: 'john_brown_university', value: 'john_brown_university', label: 'John Brown University' },
  {
    key: 'john_carroll_university',
    value: 'john_carroll_university',
    label: 'John Carroll University',
  },
  {
    key: 'john_f_kennedy_university',
    value: 'john_f_kennedy_university',
    label: 'John F. Kennedy University',
  },
  {
    key: 'john_marshall_law_school',
    value: 'john_marshall_law_school',
    label: 'John Marshall Law School',
  },
  {
    key: 'john_paul_the_great_catholic_university',
    value: 'john_paul_the_great_catholic_university',
    label: 'John Paul the Great Catholic University',
  },
  {
    key: 'johns_hopkins_university',
    value: 'johns_hopkins_university',
    label: 'Johns Hopkins University',
  },
  { key: 'johnson_bible_college', value: 'johnson_bible_college', label: 'Johnson Bible College' },
  {
    key: 'johnson_county_community_college',
    value: 'johnson_county_community_college',
    label: 'Johnson County Community College',
  },
  {
    key: 'johnson_c_smith_university',
    value: 'johnson_c_smith_university',
    label: 'Johnson C. Smith University',
  },
  { key: 'johnson_state_college', value: 'johnson_state_college', label: 'Johnson State College' },
  {
    key: 'johnson_wales_university',
    value: 'johnson_wales_university',
    label: 'Johnson & Wales University',
  },
  {
    key: 'johnson_wales_university_charleston',
    value: 'johnson_wales_university_charleston',
    label: 'Johnson & Wales University, Charleston',
  },
  { key: 'john_wesley_college', value: 'john_wesley_college', label: 'John Wesley College' },
  { key: 'jones_college', value: 'jones_college', label: 'Jones College' },
  {
    key: 'jones_international_university',
    value: 'jones_international_university',
    label: 'Jones International University',
  },
  { key: 'judson_college_elgin', value: 'judson_college_elgin', label: 'Judson College Elgin' },
  { key: 'judson_college_marion', value: 'judson_college_marion', label: 'Judson College Marion' },
  { key: 'juniata_college', value: 'juniata_college', label: 'Juniata College' },
  { key: 'kalamazoo_college', value: 'kalamazoo_college', label: 'Kalamazoo College' },
  {
    key: 'kankakee_community_college',
    value: 'kankakee_community_college',
    label: 'Kankakee Community College',
  },
  {
    key: 'kansas_city_art_institute',
    value: 'kansas_city_art_institute',
    label: 'Kansas City Art Institute',
  },
  {
    key: 'kansas_state_university',
    value: 'kansas_state_university',
    label: 'Kansas State University',
  },
  {
    key: 'kansas_wesleyan_university',
    value: 'kansas_wesleyan_university',
    label: 'Kansas Wesleyan University',
  },
  { key: 'kaplan_university', value: 'kaplan_university', label: 'Kaplan University' },
  {
    key: 'katharine_gibbs_school',
    value: 'katharine_gibbs_school',
    label: 'Katharine Gibbs School',
  },
  {
    key: 'kean_university_of_new_jersey',
    value: 'kean_university_of_new_jersey',
    label: 'Kean University of New Jersey',
  },
  {
    key: 'keck_graduate_institute_of_applied_life_sciences',
    value: 'keck_graduate_institute_of_applied_life_sciences',
    label: 'Keck Graduate Institute of Applied Life Sciences',
  },
  { key: 'keene_state_college', value: 'keene_state_college', label: 'Keene State College' },
  {
    key: 'keller_graduate_school_of_management',
    value: 'keller_graduate_school_of_management',
    label: 'Keller Graduate School of Management',
  },
  { key: 'kendall_college', value: 'kendall_college', label: 'Kendall College' },
  {
    key: 'kendall_college_of_art_and_design',
    value: 'kendall_college_of_art_and_design',
    label: 'Kendall College of Art and Design',
  },
  {
    key: 'kennesaw_state_university',
    value: 'kennesaw_state_university',
    label: 'Kennesaw State University',
  },
  { key: 'kent_state_university', value: 'kent_state_university', label: 'Kent State University' },
  {
    key: 'kent_state_university_ashtabula',
    value: 'kent_state_university_ashtabula',
    label: 'Kent State University - Ashtabula',
  },
  {
    key: 'kent_state_university_east_liverpool',
    value: 'kent_state_university_east_liverpool',
    label: 'Kent State University - East Liverpool',
  },
  {
    key: 'kent_state_university_salem',
    value: 'kent_state_university_salem',
    label: 'Kent State University - Salem',
  },
  {
    key: 'kent_state_university_stark',
    value: 'kent_state_university_stark',
    label: 'Kent State University - Stark',
  },
  {
    key: 'kent_state_university_trumbull',
    value: 'kent_state_university_trumbull',
    label: 'Kent State University - Trumbull',
  },
  {
    key: 'kent_state_university_tuscarawas',
    value: 'kent_state_university_tuscarawas',
    label: 'Kent State University - Tuscarawas',
  },
  {
    key: 'kentucky_christian_college',
    value: 'kentucky_christian_college',
    label: 'Kentucky Christian College',
  },
  {
    key: 'kentucky_state_university',
    value: 'kentucky_state_university',
    label: 'Kentucky State University',
  },
  {
    key: 'kentucky_wesleyan_college',
    value: 'kentucky_wesleyan_college',
    label: 'Kentucky Wesleyan College',
  },
  { key: 'kenyon_college', value: 'kenyon_college', label: 'Kenyon College' },
  {
    key: 'kettering_university_gmi',
    value: 'kettering_university_gmi',
    label: 'Kettering University (GMI)',
  },
  { key: 'keuka_college', value: 'keuka_college', label: 'Keuka College' },
  { key: 'king_college', value: 'king_college', label: 'King College' },
  { key: 'king_s_college', value: 'king_s_college', label: "King's College" },
  {
    key: 'kirksville_college_of_osteopathic_medicine',
    value: 'kirksville_college_of_osteopathic_medicine',
    label: 'Kirksville College of Osteopathic Medicine',
  },
  {
    key: 'kirkwood_community_college',
    value: 'kirkwood_community_college',
    label: 'Kirkwood Community College',
  },
  { key: 'knox_college', value: 'knox_college', label: 'Knox College' },
  { key: 'knoxville_college', value: 'knoxville_college', label: 'Knoxville College' },
  {
    key: 'kutztown_university_of_pennsylvania',
    value: 'kutztown_university_of_pennsylvania',
    label: 'Kutztown University of Pennsylvania',
  },
  {
    key: 'laboratory_institute_of_merchandising',
    value: 'laboratory_institute_of_merchandising',
    label: 'Laboratory Institute of Merchandising',
  },
  { key: 'lafayette_college', value: 'lafayette_college', label: 'Lafayette College' },
  { key: 'lagrange_college', value: 'lagrange_college', label: 'Lagrange College' },
  { key: 'lake_erie_college', value: 'lake_erie_college', label: 'Lake Erie College' },
  { key: 'lake_forest_college', value: 'lake_forest_college', label: 'Lake Forest College' },
  {
    key: 'lake_forest_graduate_school_of_management',
    value: 'lake_forest_graduate_school_of_management',
    label: 'Lake Forest Graduate School of Management',
  },
  { key: 'lakeland_college', value: 'lakeland_college', label: 'Lakeland College' },
  {
    key: 'lake_superior_state_university',
    value: 'lake_superior_state_university',
    label: 'Lake Superior State University',
  },
  {
    key: 'lakeview_college_of_nursing',
    value: 'lakeview_college_of_nursing',
    label: 'Lakeview College of Nursing',
  },
  { key: 'lamar_university', value: 'lamar_university', label: 'Lamar University' },
  {
    key: 'lamar_university_port_arthur',
    value: 'lamar_university_port_arthur',
    label: 'Lamar University - Port Arthur',
  },
  { key: 'lambuth_university', value: 'lambuth_university', label: 'Lambuth University' },
  {
    key: 'lancaster_bible_college',
    value: 'lancaster_bible_college',
    label: 'Lancaster Bible College',
  },
  { key: 'lander_university', value: 'lander_university', label: 'Lander University' },
  { key: 'lane_college', value: 'lane_college', label: 'Lane College' },
  { key: 'langston_university', value: 'langston_university', label: 'Langston University' },
  { key: 'la_roche_college', value: 'la_roche_college', label: 'La Roche College' },
  { key: 'la_salle_university', value: 'la_salle_university', label: 'La Salle University' },
  { key: 'lasell_college', value: 'lasell_college', label: 'Lasell College' },
  { key: 'la_sierra_university', value: 'la_sierra_university', label: 'La Sierra University' },
  {
    key: 'laurus_technical_institute',
    value: 'laurus_technical_institute',
    label: 'Laurus Technical Institute',
  },
  {
    key: 'lawrence_technological_university',
    value: 'lawrence_technological_university',
    label: 'Lawrence Technological University',
  },
  { key: 'lawrence_university', value: 'lawrence_university', label: 'Lawrence University' },
  {
    key: 'lebanon_valley_college',
    value: 'lebanon_valley_college',
    label: 'Lebanon Valley College',
  },
  { key: 'lees_mc_rae_college', value: 'lees_mc_rae_college', label: 'Lees-McRae College' },
  { key: 'lee_university', value: 'lee_university', label: 'Lee University' },
  { key: 'lehigh_univervsity', value: 'lehigh_univervsity', label: 'Lehigh Univervsity' },
  { key: 'le_moyne_college', value: 'le_moyne_college', label: 'Le Moyne College' },
  { key: 'le_moyne_owen_college', value: 'le_moyne_owen_college', label: 'Le Moyne-Owen College' },
  { key: 'lenoir_rhyne_college', value: 'lenoir_rhyne_college', label: 'Lenoir-Rhyne College' },
  { key: 'lesley_university', value: 'lesley_university', label: 'Lesley University' },
  { key: 'letourneau_university', value: 'letourneau_university', label: 'Letourneau University' },
  {
    key: 'lewis_and_clark_college',
    value: 'lewis_and_clark_college',
    label: 'Lewis and Clark College',
  },
  {
    key: 'lewis_clark_community_college',
    value: 'lewis_clark_community_college',
    label: 'Lewis & Clark Community College',
  },
  {
    key: 'lewis_clark_state_college',
    value: 'lewis_clark_state_college',
    label: 'Lewis-Clark State College',
  },
  { key: 'lewis_university', value: 'lewis_university', label: 'Lewis University' },
  { key: 'liberty_university', value: 'liberty_university', label: 'Liberty University' },
  {
    key: 'life_chiropractic_college_west',
    value: 'life_chiropractic_college_west',
    label: 'Life Chiropractic College West',
  },
  { key: 'life_university', value: 'life_university', label: 'Life University' },
  { key: 'limestone_college', value: 'limestone_college', label: 'Limestone College' },
  {
    key: 'lincoln_memorial_university',
    value: 'lincoln_memorial_university',
    label: 'Lincoln Memorial University',
  },
  {
    key: 'lincoln_university_missouri',
    value: 'lincoln_university_missouri',
    label: 'Lincoln University Missouri',
  },
  {
    key: 'lincoln_university_pennsylvania',
    value: 'lincoln_university_pennsylvania',
    label: 'Lincoln University Pennsylvania',
  },
  {
    key: 'lincoln_university_san_francisco',
    value: 'lincoln_university_san_francisco',
    label: 'Lincoln University San Francisco',
  },
  { key: 'lindenwood_university', value: 'lindenwood_university', label: 'Lindenwood University' },
  {
    key: 'lindsey_wilson_college',
    value: 'lindsey_wilson_college',
    label: 'Lindsey Wilson College',
  },
  { key: 'linfield_college', value: 'linfield_college', label: 'Linfield College' },
  { key: 'lipscomb_university', value: 'lipscomb_university', label: 'Lipscomb University' },
  { key: 'livingstone_college', value: 'livingstone_college', label: 'Livingstone College' },
  {
    key: 'lock_haven_university_of_pennsylvania',
    value: 'lock_haven_university_of_pennsylvania',
    label: 'Lock Haven University of Pennsylvania',
  },
  {
    key: 'logan_college_of_chiropractic',
    value: 'logan_college_of_chiropractic',
    label: 'Logan College of Chiropractic',
  },
  { key: 'loma_linda_university', value: 'loma_linda_university', label: 'Loma Linda University' },
  {
    key: 'long_island_university',
    value: 'long_island_university',
    label: 'Long Island University',
  },
  {
    key: 'long_island_university_c_w_post_campus',
    value: 'long_island_university_c_w_post_campus',
    label: 'Long Island University, C.W. Post Campus',
  },
  {
    key: 'long_island_university_southampton_college',
    value: 'long_island_university_southampton_college',
    label: 'Long Island University, Southampton College',
  },
  { key: 'longwood_college', value: 'longwood_college', label: 'Longwood College' },
  { key: 'loras_college', value: 'loras_college', label: 'Loras College' },
  {
    key: 'los_angeles_college_of_chiropractic',
    value: 'los_angeles_college_of_chiropractic',
    label: 'Los Angeles College of Chiropractic',
  },
  {
    key: 'louisiana_baptist_university',
    value: 'louisiana_baptist_university',
    label: 'Louisiana Baptist University',
  },
  { key: 'louisiana_college', value: 'louisiana_college', label: 'Louisiana College' },
  {
    key: 'louisiana_state_university_and_agricultural_and_mechanical_college',
    value: 'louisiana_state_university_and_agricultural_and_mechanical_college',
    label: 'Louisiana State University and Agricultural and Mechanical College',
  },
  {
    key: 'louisiana_state_university_at_alexandria',
    value: 'louisiana_state_university_at_alexandria',
    label: 'Louisiana State University at Alexandria',
  },
  {
    key: 'louisiana_state_university_at_eunice',
    value: 'louisiana_state_university_at_eunice',
    label: 'Louisiana State University at Eunice',
  },
  {
    key: 'louisiana_state_university_health_sciences_center_new_orleans',
    value: 'louisiana_state_university_health_sciences_center_new_orleans',
    label: 'Louisiana State University Health Sciences Center New Orleans',
  },
  {
    key: 'louisiana_state_university_in_shreveport',
    value: 'louisiana_state_university_in_shreveport',
    label: 'Louisiana State University in Shreveport',
  },
  {
    key: 'louisiana_tech_university',
    value: 'louisiana_tech_university',
    label: 'Louisiana Tech University',
  },
  { key: 'lourdes_college', value: 'lourdes_college', label: 'Lourdes College' },
  {
    key: 'loyola_college_in_maryland',
    value: 'loyola_college_in_maryland',
    label: 'Loyola College in Maryland',
  },
  {
    key: 'loyola_marymount_university',
    value: 'loyola_marymount_university',
    label: 'Loyola Marymount University',
  },
  {
    key: 'loyola_university_new_orleans',
    value: 'loyola_university_new_orleans',
    label: 'Loyola University New Orleans',
  },
  {
    key: 'loyola_university_of_chicago',
    value: 'loyola_university_of_chicago',
    label: 'Loyola University of Chicago',
  },
  {
    key: 'lubbock_christian_university',
    value: 'lubbock_christian_university',
    label: 'Lubbock Christian University',
  },
  {
    key: 'lutheran_bible_institute_of_seattle',
    value: 'lutheran_bible_institute_of_seattle',
    label: 'Lutheran Bible Institute of Seattle',
  },
  { key: 'luther_college', value: 'luther_college', label: 'Luther College' },
  { key: 'lycoming_college', value: 'lycoming_college', label: 'Lycoming College' },
  { key: 'lynchburg_college', value: 'lynchburg_college', label: 'Lynchburg College' },
  { key: 'lyndon_state_college', value: 'lyndon_state_college', label: 'Lyndon State College' },
  { key: 'lynn_university', value: 'lynn_university', label: 'Lynn University' },
  { key: 'lyon_college', value: 'lyon_college', label: 'Lyon College' },
  { key: 'macalester_college', value: 'macalester_college', label: 'Macalester College' },
  { key: 'mac_murray_college', value: 'mac_murray_college', label: 'MacMurray College' },
  { key: 'macon_state_college', value: 'macon_state_college', label: 'Macon State College' },
  {
    key: 'madison_university_distance_education',
    value: 'madison_university_distance_education',
    label: 'Madison University (Distance Education)',
  },
  { key: 'madonna_university', value: 'madonna_university', label: 'Madonna University' },
  {
    key: 'maharishi_university_of_management',
    value: 'maharishi_university_of_management',
    label: 'Maharishi University of Management',
  },
  { key: 'maine_college_of_art', value: 'maine_college_of_art', label: 'Maine College of Art' },
  {
    key: 'maine_maritime_academy',
    value: 'maine_maritime_academy',
    label: 'Maine Maritime Academy',
  },
  { key: 'malone_college', value: 'malone_college', label: 'Malone College' },
  { key: 'manchester_college', value: 'manchester_college', label: 'Manchester College' },
  {
    key: 'manhattan_christian_college',
    value: 'manhattan_christian_college',
    label: 'Manhattan Christian College',
  },
  { key: 'manhattan_college', value: 'manhattan_college', label: 'Manhattan College' },
  {
    key: 'manhattan_school_of_music',
    value: 'manhattan_school_of_music',
    label: 'Manhattan School of Music',
  },
  {
    key: 'manhattanville_college',
    value: 'manhattanville_college',
    label: 'Manhattanville College',
  },
  {
    key: 'mankato_state_university',
    value: 'mankato_state_university',
    label: 'Mankato State University',
  },
  {
    key: 'mansfield_university_of_pennsylvania',
    value: 'mansfield_university_of_pennsylvania',
    label: 'Mansfield University of Pennsylvania',
  },
  {
    key: 'maranatha_baptist_bible_college',
    value: 'maranatha_baptist_bible_college',
    label: 'Maranatha Baptist Bible College',
  },
  { key: 'marian_college', value: 'marian_college', label: 'Marian College' },
  {
    key: 'marian_college_of_fond_du_lac',
    value: 'marian_college_of_fond_du_lac',
    label: 'Marian College of Fond Du Lac',
  },
  { key: 'marietta_college', value: 'marietta_college', label: 'Marietta College' },
  { key: 'marist_college', value: 'marist_college', label: 'Marist College' },
  { key: 'marlboro_college', value: 'marlboro_college', label: 'Marlboro College' },
  { key: 'marquette_university', value: 'marquette_university', label: 'Marquette University' },
  { key: 'marshall_university', value: 'marshall_university', label: 'Marshall University' },
  { key: 'mars_hill_college', value: 'mars_hill_college', label: 'Mars Hill College' },
  { key: 'martin_luther_college', value: 'martin_luther_college', label: 'Martin Luther College' },
  {
    key: 'martin_methodist_college',
    value: 'martin_methodist_college',
    label: 'Martin Methodist College',
  },
  { key: 'martin_university', value: 'martin_university', label: 'Martin University' },
  { key: 'mary_baldwin_college', value: 'mary_baldwin_college', label: 'Mary Baldwin College' },
  {
    key: 'marycrest_international_university',
    value: 'marycrest_international_university',
    label: 'Marycrest International University',
  },
  { key: 'marygrove_college', value: 'marygrove_college', label: 'Marygrove College' },
  { key: 'marylhurst_university', value: 'marylhurst_university', label: 'Marylhurst University' },
  {
    key: 'marymount_college_new_york',
    value: 'marymount_college_new_york',
    label: 'Marymount College New York',
  },
  {
    key: 'marymount_manhattan_college',
    value: 'marymount_manhattan_college',
    label: 'Marymount Manhattan College',
  },
  { key: 'marymount_university', value: 'marymount_university', label: 'Marymount University' },
  { key: 'maryville_college', value: 'maryville_college', label: 'Maryville College' },
  {
    key: 'maryville_university_of_st_louis',
    value: 'maryville_university_of_st_louis',
    label: 'Maryville University of St. Louis',
  },
  {
    key: 'mary_washington_college',
    value: 'mary_washington_college',
    label: 'Mary Washington College',
  },
  { key: 'marywood_university', value: 'marywood_university', label: 'Marywood University' },
  {
    key: 'massachusetts_college_of_art',
    value: 'massachusetts_college_of_art',
    label: 'Massachusetts College of Art',
  },
  {
    key: 'massachusetts_college_of_liberal_arts',
    value: 'massachusetts_college_of_liberal_arts',
    label: 'Massachusetts College of Liberal Arts',
  },
  {
    key: 'massachusetts_college_of_pharmacy_and_allied_health_sciences',
    value: 'massachusetts_college_of_pharmacy_and_allied_health_sciences',
    label: 'Massachusetts College of Pharmacy and Allied Health Sciences',
  },
  {
    key: 'massachusetts_institute_of_technology',
    value: 'massachusetts_institute_of_technology',
    label: 'Massachusetts Institute of Technology',
  },
  {
    key: 'massachusetts_maritime_academy',
    value: 'massachusetts_maritime_academy',
    label: 'Massachusetts Maritime Academy',
  },
  {
    key: 'massachusetts_school_of_professional_psychology',
    value: 'massachusetts_school_of_professional_psychology',
    label: 'Massachusetts School of Professional Psychology',
  },
  { key: 'mayo_graduate_school', value: 'mayo_graduate_school', label: 'Mayo Graduate School' },
  { key: 'mayo_medical_school', value: 'mayo_medical_school', label: 'Mayo Medical School' },
  {
    key: 'mayville_state_university',
    value: 'mayville_state_university',
    label: 'Mayville State University',
  },
  { key: 'mc_kendree_college', value: 'mc_kendree_college', label: 'McKendree College' },
  { key: 'mc_murry_university', value: 'mc_murry_university', label: 'McMurry University' },
  {
    key: 'mc_neese_state_university',
    value: 'mc_neese_state_university',
    label: 'McNeese State University',
  },
  { key: 'mc_pherson_college', value: 'mc_pherson_college', label: 'McPherson College' },
  { key: 'medaille_college', value: 'medaille_college', label: 'Medaille College' },
  {
    key: 'medcenter_one_college_of_nursing',
    value: 'medcenter_one_college_of_nursing',
    label: 'Medcenter One College of Nursing',
  },
  {
    key: 'medical_college_of_georgia',
    value: 'medical_college_of_georgia',
    label: 'Medical College of Georgia',
  },
  {
    key: 'medical_college_of_ohio',
    value: 'medical_college_of_ohio',
    label: 'Medical College of Ohio',
  },
  {
    key: 'medical_college_of_pennsylvania_and_hahnemann_university',
    value: 'medical_college_of_pennsylvania_and_hahnemann_university',
    label: 'Medical College of Pennsylvania and Hahnemann University',
  },
  {
    key: 'medical_college_of_wisconsin',
    value: 'medical_college_of_wisconsin',
    label: 'Medical College of Wisconsin',
  },
  {
    key: 'medical_university_of_south_carolina',
    value: 'medical_university_of_south_carolina',
    label: 'Medical University of South Carolina',
  },
  {
    key: 'meharry_medical_college',
    value: 'meharry_medical_college',
    label: 'Meharry Medical College',
  },
  {
    key: 'memphis_college_of_art',
    value: 'memphis_college_of_art',
    label: 'Memphis College of Art',
  },
  { key: 'menlo_college', value: 'menlo_college', label: 'Menlo College' },
  {
    key: 'mennonite_college_of_nursing',
    value: 'mennonite_college_of_nursing',
    label: 'Mennonite College of Nursing',
  },
  { key: 'mercer_university', value: 'mercer_university', label: 'Mercer University' },
  {
    key: 'mercer_university_cecil_b_day_campus',
    value: 'mercer_university_cecil_b_day_campus',
    label: 'Mercer University, Cecil B. Day Campus',
  },
  { key: 'mercy_college', value: 'mercy_college', label: 'Mercy College' },
  { key: 'mercyhurst_college', value: 'mercyhurst_college', label: 'Mercyhurst College' },
  { key: 'meredith_college', value: 'meredith_college', label: 'Meredith College' },
  { key: 'merrimack_college', value: 'merrimack_college', label: 'Merrimack College' },
  { key: 'mesa_state_college', value: 'mesa_state_college', label: 'Mesa State College' },
  { key: 'messiah_college', value: 'messiah_college', label: 'Messiah College' },
  { key: 'methodist_college', value: 'methodist_college', label: 'Methodist College' },
  {
    key: 'metropolitan_state_college_of_denver',
    value: 'metropolitan_state_college_of_denver',
    label: 'Metropolitan State College of Denver',
  },
  {
    key: 'metropolitan_state_university',
    value: 'metropolitan_state_university',
    label: 'Metropolitan State University',
  },
  {
    key: 'mgh_institute_of_health_professions',
    value: 'mgh_institute_of_health_professions',
    label: 'MGH Institute of Health Professions',
  },
  { key: 'miami_dade_college', value: 'miami_dade_college', label: 'Miami Dade College' },
  {
    key: 'miami_university_of_ohio',
    value: 'miami_university_of_ohio',
    label: 'Miami University of Ohio',
  },
  {
    key: 'miami_university_of_ohio_hamilton',
    value: 'miami_university_of_ohio_hamilton',
    label: 'Miami University of Ohio - Hamilton',
  },
  {
    key: 'miami_university_of_ohio_middletown',
    value: 'miami_university_of_ohio_middletown',
    label: 'Miami University of Ohio - Middletown',
  },
  {
    key: 'michigan_school_of_professional_psychology',
    value: 'michigan_school_of_professional_psychology',
    label: 'Michigan School of Professional Psychology',
  },
  {
    key: 'michigan_state_university',
    value: 'michigan_state_university',
    label: 'Michigan State University',
  },
  {
    key: 'michigan_technological_university',
    value: 'michigan_technological_university',
    label: 'Michigan Technological University',
  },
  {
    key: 'mid_america_nazarene_university',
    value: 'mid_america_nazarene_university',
    label: 'Mid-America Nazarene University',
  },
  {
    key: 'mid_american_bible_college',
    value: 'mid_american_bible_college',
    label: 'Mid-American Bible College',
  },
  {
    key: 'mid_continent_baptist_bible_college',
    value: 'mid_continent_baptist_bible_college',
    label: 'Mid-Continent Baptist Bible College',
  },
  { key: 'middlebury_college', value: 'middlebury_college', label: 'Middlebury College' },
  {
    key: 'middle_tennessee_state_university',
    value: 'middle_tennessee_state_university',
    label: 'Middle Tennessee State University',
  },
  {
    key: 'midland_lutheran_college',
    value: 'midland_lutheran_college',
    label: 'Midland Lutheran College',
  },
  { key: 'midway_college', value: 'midway_college', label: 'Midway College' },
  {
    key: 'midwestern_state_university',
    value: 'midwestern_state_university',
    label: 'Midwestern State University',
  },
  { key: 'midwestern_university', value: 'midwestern_university', label: 'Midwestern University' },
  { key: 'miles_college', value: 'miles_college', label: 'Miles College' },
  {
    key: 'millennia_atlantic_university',
    value: 'millennia_atlantic_university',
    label: 'Millennia Atlantic University',
  },
  {
    key: 'millersville_university_of_pennsylvania',
    value: 'millersville_university_of_pennsylvania',
    label: 'Millersville University of Pennsylvania',
  },
  { key: 'milligan_college', value: 'milligan_college', label: 'Milligan College' },
  { key: 'millikin_university', value: 'millikin_university', label: 'Millikin University' },
  { key: 'millsaps_college', value: 'millsaps_college', label: 'Millsaps College' },
  { key: 'mills_college', value: 'mills_college', label: 'Mills College' },
  { key: 'mills_grae_university', value: 'mills_grae_university', label: 'Mills Grae University' },
  {
    key: 'milwaukee_institute_of_art_and_design',
    value: 'milwaukee_institute_of_art_and_design',
    label: 'Milwaukee Institute of Art and Design',
  },
  {
    key: 'milwaukee_school_of_engineering',
    value: 'milwaukee_school_of_engineering',
    label: 'Milwaukee School of Engineering',
  },
  {
    key: 'minneapolis_college_of_art_and_design',
    value: 'minneapolis_college_of_art_and_design',
    label: 'Minneapolis College of Art and Design',
  },
  {
    key: 'minnesota_bible_college',
    value: 'minnesota_bible_college',
    label: 'Minnesota Bible College',
  },
  {
    key: 'minnesota_school_of_professional_psychology',
    value: 'minnesota_school_of_professional_psychology',
    label: 'Minnesota School of Professional Psychology',
  },
  {
    key: 'minot_state_university',
    value: 'minot_state_university',
    label: 'Minot State University',
  },
  { key: 'mississippi_college', value: 'mississippi_college', label: 'Mississippi College' },
  {
    key: 'mississippi_state_university',
    value: 'mississippi_state_university',
    label: 'Mississippi State University',
  },
  {
    key: 'mississippi_university_for_women',
    value: 'mississippi_university_for_women',
    label: 'Mississippi University for Women',
  },
  {
    key: 'mississippi_valley_state_university',
    value: 'mississippi_valley_state_university',
    label: 'Mississippi Valley State University',
  },
  {
    key: 'missouri_baptist_college',
    value: 'missouri_baptist_college',
    label: 'Missouri Baptist College',
  },
  {
    key: 'missouri_southern_state_college',
    value: 'missouri_southern_state_college',
    label: 'Missouri Southern State College',
  },
  { key: 'missouri_tech', value: 'missouri_tech', label: 'Missouri Tech' },
  {
    key: 'missouri_university_of_science_and_technology',
    value: 'missouri_university_of_science_and_technology',
    label: 'Missouri University of Science and Technology',
  },
  {
    key: 'missouri_valley_college',
    value: 'missouri_valley_college',
    label: 'Missouri Valley College',
  },
  {
    key: 'missouri_western_state_college',
    value: 'missouri_western_state_college',
    label: 'Missouri Western State College',
  },
  { key: 'molloy_college', value: 'molloy_college', label: 'Molloy College' },
  { key: 'monmouth_university', value: 'monmouth_university', label: 'Monmouth University' },
  {
    key: 'montana_state_university',
    value: 'montana_state_university',
    label: 'Montana State University',
  },
  {
    key: 'montana_state_university_billings',
    value: 'montana_state_university_billings',
    label: 'Montana State University - Billings',
  },
  {
    key: 'montana_state_university_northern',
    value: 'montana_state_university_northern',
    label: 'Montana State University - Northern',
  },
  { key: 'montana_tech', value: 'montana_tech', label: 'Montana Tech' },
  {
    key: 'montclair_state_university',
    value: 'montclair_state_university',
    label: 'Montclair State University',
  },
  {
    key: 'monterey_institute_of_international_studies',
    value: 'monterey_institute_of_international_studies',
    label: 'Monterey Institute of International Studies',
  },
  { key: 'montreat_college', value: 'montreat_college', label: 'Montreat College' },
  {
    key: 'montserrat_college_of_art',
    value: 'montserrat_college_of_art',
    label: 'Montserrat College of Art',
  },
  { key: 'moody_bible_institute', value: 'moody_bible_institute', label: 'Moody Bible Institute' },
  {
    key: 'moore_college_of_art_and_design',
    value: 'moore_college_of_art_and_design',
    label: 'Moore College of Art and Design',
  },
  {
    key: 'moorhead_state_university',
    value: 'moorhead_state_university',
    label: 'Moorhead State University',
  },
  {
    key: 'moraine_valley_community_college',
    value: 'moraine_valley_community_college',
    label: 'Moraine Valley Community College',
  },
  { key: 'moravian_college', value: 'moravian_college', label: 'Moravian College' },
  {
    key: 'morehead_state_university',
    value: 'morehead_state_university',
    label: 'Morehead State University',
  },
  { key: 'morehouse_college', value: 'morehouse_college', label: 'Morehouse College' },
  {
    key: 'morehouse_school_of_medicine',
    value: 'morehouse_school_of_medicine',
    label: 'Morehouse School of Medicine',
  },
  {
    key: 'morgan_state_university',
    value: 'morgan_state_university',
    label: 'Morgan State University',
  },
  { key: 'morningside_college', value: 'morningside_college', label: 'Morningside College' },
  { key: 'morris_brown_college', value: 'morris_brown_college', label: 'Morris Brown College' },
  { key: 'morris_college', value: 'morris_college', label: 'Morris College' },
  { key: 'morrison_college', value: 'morrison_college', label: 'Morrison College' },
  {
    key: 'mountain_state_university',
    value: 'mountain_state_university',
    label: 'Mountain State University',
  },
  {
    key: 'mount_aloysius_college',
    value: 'mount_aloysius_college',
    label: 'Mount Aloysius College',
  },
  {
    key: 'mount_carmel_college_of_nursing',
    value: 'mount_carmel_college_of_nursing',
    label: 'Mount Carmel College of Nursing',
  },
  { key: 'mount_holyoke_college', value: 'mount_holyoke_college', label: 'Mount Holyoke College' },
  { key: 'mount_ida_college', value: 'mount_ida_college', label: 'Mount Ida College' },
  { key: 'mount_marty_college', value: 'mount_marty_college', label: 'Mount Marty College' },
  { key: 'mount_mary_college', value: 'mount_mary_college', label: 'Mount Mary College' },
  { key: 'mount_mercy_college', value: 'mount_mercy_college', label: 'Mount Mercy College' },
  { key: 'mount_olive_college', value: 'mount_olive_college', label: 'Mount Olive College' },
  { key: 'mount_senario_college', value: 'mount_senario_college', label: 'Mount Senario College' },
  {
    key: 'mount_sinai_school_of_medicine',
    value: 'mount_sinai_school_of_medicine',
    label: 'Mount Sinai School of Medicine',
  },
  {
    key: 'mount_st_clare_college',
    value: 'mount_st_clare_college',
    label: 'Mount St. Clare College',
  },
  { key: 'mount_st_mary_college', value: 'mount_st_mary_college', label: 'Mount St. Mary College' },
  {
    key: 'mount_st_mary_s_college_california',
    value: 'mount_st_mary_s_college_california',
    label: "Mount St. Mary's College California",
  },
  {
    key: 'mount_st_mary_s_college_maryland',
    value: 'mount_st_mary_s_college_maryland',
    label: "Mount St. Mary's College Maryland",
  },
  { key: 'mount_union_college', value: 'mount_union_college', label: 'Mount Union College' },
  { key: 'mount_vernon_college', value: 'mount_vernon_college', label: 'Mount Vernon College' },
  {
    key: 'mount_vernon_nazarene_college',
    value: 'mount_vernon_nazarene_college',
    label: 'Mount Vernon Nazarene College',
  },
  { key: 'muhlenberg_college', value: 'muhlenberg_college', label: 'Muhlenberg College' },
  {
    key: 'multnomah_bible_college',
    value: 'multnomah_bible_college',
    label: 'Multnomah Bible College',
  },
  {
    key: 'murray_state_university',
    value: 'murray_state_university',
    label: 'Murray State University',
  },
  { key: 'muskingum_college', value: 'muskingum_college', label: 'Muskingum College' },
  { key: 'naes_college', value: 'naes_college', label: 'NAES College' },
  {
    key: 'national_american_university',
    value: 'national_american_university',
    label: 'National American University',
  },
  {
    key: 'national_american_university_albuquerque',
    value: 'national_american_university_albuquerque',
    label: 'National American University, Albuquerque',
  },
  {
    key: 'national_american_university_colorado_springs',
    value: 'national_american_university_colorado_springs',
    label: 'National American University, Colorado Springs',
  },
  {
    key: 'national_american_university_denver',
    value: 'national_american_university_denver',
    label: 'National American University, Denver',
  },
  {
    key: 'national_american_university_kansas_city',
    value: 'national_american_university_kansas_city',
    label: 'National American University, Kansas City',
  },
  {
    key: 'national_american_university_roseville',
    value: 'national_american_university_roseville',
    label: 'National American University, Roseville',
  },
  {
    key: 'national_american_university_sioux_falls',
    value: 'national_american_university_sioux_falls',
    label: 'National American University, Sioux Falls',
  },
  {
    key: 'national_college_of_chiropractic',
    value: 'national_college_of_chiropractic',
    label: 'National College of Chiropractic',
  },
  {
    key: 'national_college_of_naturopathic_medicine',
    value: 'national_college_of_naturopathic_medicine',
    label: 'National College of Naturopathic Medicine',
  },
  {
    key: 'national_defense_university',
    value: 'national_defense_university',
    label: 'National Defense University',
  },
  {
    key: 'national_hispanic_university',
    value: 'national_hispanic_university',
    label: 'National Hispanic University',
  },
  {
    key: 'national_louis_university',
    value: 'national_louis_university',
    label: 'National-Louis University',
  },
  {
    key: 'national_technological_university',
    value: 'national_technological_university',
    label: 'National Technological University',
  },
  {
    key: 'national_theatre_conservatory',
    value: 'national_theatre_conservatory',
    label: 'National Theatre Conservatory',
  },
  { key: 'national_university', value: 'national_university', label: 'National University' },
  {
    key: 'naval_postgraduate_school',
    value: 'naval_postgraduate_school',
    label: 'Naval Postgraduate School',
  },
  {
    key: 'nazarene_bible_college',
    value: 'nazarene_bible_college',
    label: 'Nazarene Bible College',
  },
  { key: 'nazareth_college', value: 'nazareth_college', label: 'Nazareth College' },
  {
    key: 'nebraska_christian_college',
    value: 'nebraska_christian_college',
    label: 'Nebraska Christian College',
  },
  {
    key: 'nebraska_methodist_college_of_nursing_and_allied_health',
    value: 'nebraska_methodist_college_of_nursing_and_allied_health',
    label: 'Nebraska Methodist College of Nursing and Allied Health',
  },
  {
    key: 'nebraska_wesleyan_university',
    value: 'nebraska_wesleyan_university',
    label: 'Nebraska Wesleyan University',
  },
  { key: 'neumann_college', value: 'neumann_college', label: 'Neumann College' },
  { key: 'newberry_college', value: 'newberry_college', label: 'Newberry College' },
  {
    key: 'new_college_of_california',
    value: 'new_college_of_california',
    label: 'New College of California',
  },
  {
    key: 'new_college_of_florida',
    value: 'new_college_of_florida',
    label: 'New College of Florida',
  },
  { key: 'new_england_college', value: 'new_england_college', label: 'New England College' },
  {
    key: 'new_england_college_of_optometry',
    value: 'new_england_college_of_optometry',
    label: 'New England College of Optometry',
  },
  {
    key: 'new_england_conservatory_of_music',
    value: 'new_england_conservatory_of_music',
    label: 'New England Conservatory of Music',
  },
  {
    key: 'new_england_institute_of_technology',
    value: 'new_england_institute_of_technology',
    label: 'New England Institute of Technology',
  },
  {
    key: 'new_england_school_of_art_and_design',
    value: 'new_england_school_of_art_and_design',
    label: 'New England School of Art and Design',
  },
  {
    key: 'new_england_school_of_communications',
    value: 'new_england_school_of_communications',
    label: 'New England School of Communications',
  },
  {
    key: 'new_england_school_of_law',
    value: 'new_england_school_of_law',
    label: 'New England School of Law',
  },
  { key: 'new_hampshire_college', value: 'new_hampshire_college', label: 'New Hampshire College' },
  {
    key: 'new_jersey_city_university',
    value: 'new_jersey_city_university',
    label: 'New Jersey City University',
  },
  {
    key: 'new_jersey_institute_of_technology',
    value: 'new_jersey_institute_of_technology',
    label: 'New Jersey Institute of Technology',
  },
  { key: 'newman_university', value: 'newman_university', label: 'Newman University' },
  {
    key: 'new_mexico_highlands_university',
    value: 'new_mexico_highlands_university',
    label: 'New Mexico Highlands University',
  },
  {
    key: 'new_mexico_institute_of_mining_and_technology',
    value: 'new_mexico_institute_of_mining_and_technology',
    label: 'New Mexico Institute of Mining and Technology',
  },
  {
    key: 'new_mexico_state_university',
    value: 'new_mexico_state_university',
    label: 'New Mexico State University',
  },
  {
    key: 'newschool_of_architecture_and_design',
    value: 'newschool_of_architecture_and_design',
    label: 'Newschool of Architecture and Design',
  },
  {
    key: 'new_york_academy_of_art_graduate_school_of_figurative_art',
    value: 'new_york_academy_of_art_graduate_school_of_figurative_art',
    label: 'New York Academy of Art, Graduate School of Figurative Art',
  },
  {
    key: 'new_york_chiropractic_college',
    value: 'new_york_chiropractic_college',
    label: 'New York Chiropractic College',
  },
  {
    key: 'new_york_college_of_podiatric_medicine',
    value: 'new_york_college_of_podiatric_medicine',
    label: 'New York College of Podiatric Medicine',
  },
  { key: 'new_york_film_academy', value: 'new_york_film_academy', label: 'New York Film Academy' },
  {
    key: 'new_york_institute_of_technology',
    value: 'new_york_institute_of_technology',
    label: 'New York Institute of Technology',
  },
  { key: 'new_york_law_school', value: 'new_york_law_school', label: 'New York Law School' },
  {
    key: 'new_york_medical_college',
    value: 'new_york_medical_college',
    label: 'New York Medical College',
  },
  {
    key: 'new_york_school_of_interior_design',
    value: 'new_york_school_of_interior_design',
    label: 'New York School of Interior Design',
  },
  { key: 'new_york_university', value: 'new_york_university', label: 'New York University' },
  { key: 'niagara_university', value: 'niagara_university', label: 'Niagara University' },
  {
    key: 'nicholls_state_university',
    value: 'nicholls_state_university',
    label: 'Nicholls State University',
  },
  { key: 'nichols_college', value: 'nichols_college', label: 'Nichols College' },
  {
    key: 'norfolk_state_university',
    value: 'norfolk_state_university',
    label: 'Norfolk State University',
  },
  {
    key: 'north_carolina_agricultural_and_technical_state_university',
    value: 'north_carolina_agricultural_and_technical_state_university',
    label: 'North Carolina Agricultural and Technical State University',
  },
  {
    key: 'north_carolina_central_university',
    value: 'north_carolina_central_university',
    label: 'North Carolina Central University',
  },
  {
    key: 'north_carolina_school_of_the_arts',
    value: 'north_carolina_school_of_the_arts',
    label: 'North Carolina School of the Arts',
  },
  {
    key: 'north_carolina_state_university',
    value: 'north_carolina_state_university',
    label: 'North Carolina State University',
  },
  {
    key: 'north_carolina_wesleyan_college',
    value: 'north_carolina_wesleyan_college',
    label: 'North Carolina Wesleyan College',
  },
  {
    key: 'north_central_bible_college',
    value: 'north_central_bible_college',
    label: 'North Central Bible College',
  },
  { key: 'north_central_college', value: 'north_central_college', label: 'North Central College' },
  {
    key: 'northcentral_university',
    value: 'northcentral_university',
    label: 'Northcentral University',
  },
  {
    key: 'north_dakota_state_university',
    value: 'north_dakota_state_university',
    label: 'North Dakota State University',
  },
  {
    key: 'northeastern_illinois_university',
    value: 'northeastern_illinois_university',
    label: 'Northeastern Illinois University',
  },
  {
    key: 'northeastern_ohio_university_college_of_medicine',
    value: 'northeastern_ohio_university_college_of_medicine',
    label: 'Northeastern Ohio University College of Medicine',
  },
  {
    key: 'northeastern_state_university',
    value: 'northeastern_state_university',
    label: 'Northeastern State University',
  },
  {
    key: 'northeastern_university',
    value: 'northeastern_university',
    label: 'Northeastern University',
  },
  {
    key: 'northern_arizona_university',
    value: 'northern_arizona_university',
    label: 'Northern Arizona University',
  },
  {
    key: 'northern_illinois_university',
    value: 'northern_illinois_university',
    label: 'Northern Illinois University',
  },
  {
    key: 'northern_kentucky_university',
    value: 'northern_kentucky_university',
    label: 'Northern Kentucky University',
  },
  {
    key: 'northern_michigan_university',
    value: 'northern_michigan_university',
    label: 'Northern Michigan University',
  },
  {
    key: 'northern_state_university',
    value: 'northern_state_university',
    label: 'Northern State University',
  },
  {
    key: 'northern_virginia_community_college',
    value: 'northern_virginia_community_college',
    label: 'Northern Virginia Community College',
  },
  { key: 'northface_university', value: 'northface_university', label: 'Northface University' },
  { key: 'north_georgia_college', value: 'north_georgia_college', label: 'North Georgia College' },
  {
    key: 'north_greenville_college',
    value: 'north_greenville_college',
    label: 'North Greenville College',
  },
  { key: 'northland_college', value: 'northland_college', label: 'Northland College' },
  { key: 'north_park_university', value: 'north_park_university', label: 'North Park University' },
  {
    key: 'northwest_christian_college',
    value: 'northwest_christian_college',
    label: 'Northwest Christian College',
  },
  {
    key: 'northwest_college_of_art',
    value: 'northwest_college_of_art',
    label: 'Northwest College of Art',
  },
  {
    key: 'northwestern_college_iowa',
    value: 'northwestern_college_iowa',
    label: 'Northwestern College Iowa',
  },
  {
    key: 'northwestern_college_of_chiropractic',
    value: 'northwestern_college_of_chiropractic',
    label: 'Northwestern College of Chiropractic',
  },
  {
    key: 'northwestern_college_st_paul',
    value: 'northwestern_college_st_paul',
    label: 'Northwestern College St. Paul',
  },
  {
    key: 'northwestern_oklahoma_state_university',
    value: 'northwestern_oklahoma_state_university',
    label: 'Northwestern Oklahoma State University',
  },
  {
    key: 'northwestern_state_university_of_louisiana',
    value: 'northwestern_state_university_of_louisiana',
    label: 'Northwestern State University of Louisiana',
  },
  {
    key: 'northwestern_university',
    value: 'northwestern_university',
    label: 'Northwestern University',
  },
  {
    key: 'northwest_missouri_state_university',
    value: 'northwest_missouri_state_university',
    label: 'Northwest Missouri State University',
  },
  {
    key: 'northwest_nazarene_university',
    value: 'northwest_nazarene_university',
    label: 'Northwest Nazarene University',
  },
  { key: 'northwest_university', value: 'northwest_university', label: 'Northwest University' },
  { key: 'northwood_university', value: 'northwood_university', label: 'Northwood University' },
  {
    key: 'northwood_university_florida_campus',
    value: 'northwood_university_florida_campus',
    label: 'Northwood University, Florida Campus',
  },
  {
    key: 'northwood_university_texas_campus',
    value: 'northwood_university_texas_campus',
    label: 'Northwood University, Texas Campus',
  },
  { key: 'norwich_university', value: 'norwich_university', label: 'Norwich University' },
  { key: 'notre_dame_college', value: 'notre_dame_college', label: 'Notre Dame College' },
  {
    key: 'notre_dame_college_of_ohio',
    value: 'notre_dame_college_of_ohio',
    label: 'Notre Dame College of Ohio',
  },
  {
    key: 'notre_dame_de_namur_university',
    value: 'notre_dame_de_namur_university',
    label: 'Notre Dame de Namur University',
  },
  {
    key: 'nova_southeastern_university',
    value: 'nova_southeastern_university',
    label: 'Nova Southeastern University',
  },
  { key: 'nyack_college', value: 'nyack_college', label: 'Nyack College' },
  {
    key: 'oakland_city_university',
    value: 'oakland_city_university',
    label: 'Oakland City University',
  },
  { key: 'oakland_university', value: 'oakland_university', label: 'Oakland University' },
  {
    key: 'oakton_community_college',
    value: 'oakton_community_college',
    label: 'Oakton Community College',
  },
  { key: 'oakwood_college', value: 'oakwood_college', label: 'Oakwood College' },
  { key: 'oberlin_college', value: 'oberlin_college', label: 'Oberlin College' },
  { key: 'occidental_college', value: 'occidental_college', label: 'Occidental College' },
  { key: 'oglala_lakota_college', value: 'oglala_lakota_college', label: 'Oglala Lakota College' },
  { key: 'oglethorpe_university', value: 'oglethorpe_university', label: 'Oglethorpe University' },
  {
    key: 'ohio_college_of_podiatric_medicine',
    value: 'ohio_college_of_podiatric_medicine',
    label: 'Ohio College of Podiatric Medicine',
  },
  {
    key: 'ohio_dominican_college',
    value: 'ohio_dominican_college',
    label: 'Ohio Dominican College',
  },
  {
    key: 'ohio_northern_university',
    value: 'ohio_northern_university',
    label: 'Ohio Northern University',
  },
  { key: 'ohio_state_university', value: 'ohio_state_university', label: 'Ohio State University' },
  {
    key: 'ohio_state_university_lima',
    value: 'ohio_state_university_lima',
    label: 'Ohio State University - Lima',
  },
  {
    key: 'ohio_state_university_mansfield',
    value: 'ohio_state_university_mansfield',
    label: 'Ohio State University - Mansfield',
  },
  {
    key: 'ohio_state_university_marion',
    value: 'ohio_state_university_marion',
    label: 'Ohio State University - Marion',
  },
  {
    key: 'ohio_state_university_newark',
    value: 'ohio_state_university_newark',
    label: 'Ohio State University - Newark',
  },
  { key: 'ohio_university', value: 'ohio_university', label: 'Ohio University' },
  {
    key: 'ohio_university_chillicothe',
    value: 'ohio_university_chillicothe',
    label: 'Ohio University - Chillicothe',
  },
  {
    key: 'ohio_university_eastern',
    value: 'ohio_university_eastern',
    label: 'Ohio University - Eastern',
  },
  {
    key: 'ohio_university_lancaster',
    value: 'ohio_university_lancaster',
    label: 'Ohio University - Lancaster',
  },
  {
    key: 'ohio_university_southern',
    value: 'ohio_university_southern',
    label: 'Ohio University - Southern',
  },
  {
    key: 'ohio_university_zanesville',
    value: 'ohio_university_zanesville',
    label: 'Ohio University - Zanesville',
  },
  { key: 'ohio_valley_college', value: 'ohio_valley_college', label: 'Ohio Valley College' },
  {
    key: 'ohio_wesleyan_university',
    value: 'ohio_wesleyan_university',
    label: 'Ohio Wesleyan University',
  },
  {
    key: 'oklahoma_baptist_university',
    value: 'oklahoma_baptist_university',
    label: 'Oklahoma Baptist University',
  },
  {
    key: 'oklahoma_christian_university',
    value: 'oklahoma_christian_university',
    label: 'Oklahoma Christian University',
  },
  {
    key: 'oklahoma_city_university',
    value: 'oklahoma_city_university',
    label: 'Oklahoma City University',
  },
  {
    key: 'oklahoma_panhandle_state_university',
    value: 'oklahoma_panhandle_state_university',
    label: 'Oklahoma Panhandle State University',
  },
  {
    key: 'oklahoma_state_university',
    value: 'oklahoma_state_university',
    label: 'Oklahoma State University',
  },
  {
    key: 'oklahoma_state_university_center_for_health_sciences',
    value: 'oklahoma_state_university_center_for_health_sciences',
    label: 'Oklahoma State University Center for Health Sciences',
  },
  {
    key: 'oklahoma_state_university_institute_of_technology',
    value: 'oklahoma_state_university_institute_of_technology',
    label: 'Oklahoma State University - Institute of Technology',
  },
  {
    key: 'oklahoma_state_university_oklahoma_city',
    value: 'oklahoma_state_university_oklahoma_city',
    label: 'Oklahoma State University - Oklahoma City',
  },
  {
    key: 'oklahoma_state_university_tulsa',
    value: 'oklahoma_state_university_tulsa',
    label: 'Oklahoma State University - Tulsa',
  },
  {
    key: 'old_dominion_university',
    value: 'old_dominion_university',
    label: 'Old Dominion University',
  },
  { key: 'olive_harvey_college', value: 'olive_harvey_college', label: 'Olive-Harvey College' },
  { key: 'olivet_college', value: 'olivet_college', label: 'Olivet College' },
  {
    key: 'olivet_nazarene_university',
    value: 'olivet_nazarene_university',
    label: 'Olivet Nazarene University',
  },
  {
    key: 'o_more_college_of_design',
    value: 'o_more_college_of_design',
    label: "O'More College of Design",
  },
  {
    key: 'oral_roberts_university',
    value: 'oral_roberts_university',
    label: 'Oral Roberts University',
  },
  {
    key: 'oregon_college_of_arts_and_crafts',
    value: 'oregon_college_of_arts_and_crafts',
    label: 'Oregon College of Arts and Crafts',
  },
  {
    key: 'oregon_graduate_institute_of_science_and_technology',
    value: 'oregon_graduate_institute_of_science_and_technology',
    label: 'Oregon Graduate Institute of Science and Technology',
  },
  {
    key: 'oregon_health_sciences_university',
    value: 'oregon_health_sciences_university',
    label: 'Oregon Health Sciences University',
  },
  {
    key: 'oregon_institute_of_technology',
    value: 'oregon_institute_of_technology',
    label: 'Oregon Institute of Technology',
  },
  {
    key: 'oregon_state_university',
    value: 'oregon_state_university',
    label: 'Oregon State University',
  },
  {
    key: 'otis_college_of_art_design',
    value: 'otis_college_of_art_design',
    label: 'Otis College of Art & Design',
  },
  { key: 'ottawa_university', value: 'ottawa_university', label: 'Ottawa University' },
  { key: 'otterbein_college', value: 'otterbein_college', label: 'Otterbein College' },
  {
    key: 'ouachita_baptist_university',
    value: 'ouachita_baptist_university',
    label: 'Ouachita Baptist University',
  },
  {
    key: 'our_lady_of_holy_cross_college',
    value: 'our_lady_of_holy_cross_college',
    label: 'Our Lady of Holy Cross College',
  },
  {
    key: 'our_lady_of_the_lake_university',
    value: 'our_lady_of_the_lake_university',
    label: 'Our Lady of the Lake University',
  },
  {
    key: 'ozark_christian_college',
    value: 'ozark_christian_college',
    label: 'Ozark Christian College',
  },
  { key: 'pace_university', value: 'pace_university', label: 'Pace University' },
  {
    key: 'pace_university_pleasantville_briarcliff',
    value: 'pace_university_pleasantville_briarcliff',
    label: 'Pace University  Pleasantville/Briarcliff',
  },
  {
    key: 'pacifica_graduate_institute',
    value: 'pacifica_graduate_institute',
    label: 'Pacifica Graduate Institute',
  },
  {
    key: 'pacific_college_of_oriental_medicine',
    value: 'pacific_college_of_oriental_medicine',
    label: 'Pacific College of Oriental Medicine',
  },
  {
    key: 'pacific_graduate_school_of_psychology',
    value: 'pacific_graduate_school_of_psychology',
    label: 'Pacific Graduate School of Psychology',
  },
  {
    key: 'pacific_lutheran_university',
    value: 'pacific_lutheran_university',
    label: 'Pacific Lutheran University',
  },
  {
    key: 'pacific_northwest_college_of_art',
    value: 'pacific_northwest_college_of_art',
    label: 'Pacific Northwest College of Art',
  },
  { key: 'pacific_oaks_college', value: 'pacific_oaks_college', label: 'Pacific Oaks College' },
  { key: 'pacific_union_college', value: 'pacific_union_college', label: 'Pacific Union College' },
  { key: 'pacific_university', value: 'pacific_university', label: 'Pacific University' },
  { key: 'paier_college_of_art', value: 'paier_college_of_art', label: 'Paier College of Art' },
  { key: 'paine_college', value: 'paine_college', label: 'Paine College' },
  {
    key: 'palm_beach_atlantic_university',
    value: 'palm_beach_atlantic_university',
    label: 'Palm Beach Atlantic University',
  },
  {
    key: 'palm_beach_state_college',
    value: 'palm_beach_state_college',
    label: 'Palm Beach State College',
  },
  {
    key: 'palmer_college_of_chiropractic',
    value: 'palmer_college_of_chiropractic',
    label: 'Palmer College of Chiropractic',
  },
  {
    key: 'palmer_college_of_chiropractic_west',
    value: 'palmer_college_of_chiropractic_west',
    label: 'Palmer College of Chiropractic West',
  },
  { key: 'park_college', value: 'park_college', label: 'Park College' },
  {
    key: 'parsons_school_of_design',
    value: 'parsons_school_of_design',
    label: 'Parsons School of Design',
  },
  { key: 'paul_quinn_college', value: 'paul_quinn_college', label: 'Paul Quinn College' },
  { key: 'peace_college', value: 'peace_college', label: 'Peace College' },
  {
    key: 'pebble_hills_university',
    value: 'pebble_hills_university',
    label: 'Pebble Hills University',
  },
  {
    key: 'pennsylvania_academy_of_the_fine_arts',
    value: 'pennsylvania_academy_of_the_fine_arts',
    label: 'Pennsylvania Academy of the Fine Arts',
  },
  {
    key: 'pennsylvania_college_of_optometry',
    value: 'pennsylvania_college_of_optometry',
    label: 'Pennsylvania College of Optometry',
  },
  {
    key: 'pennsylvania_institute_of_technology',
    value: 'pennsylvania_institute_of_technology',
    label: 'Pennsylvania Institute of Technology',
  },
  {
    key: 'pennsylvania_state_university',
    value: 'pennsylvania_state_university',
    label: 'Pennsylvania State University',
  },
  {
    key: 'pennsylvania_state_university_abington',
    value: 'pennsylvania_state_university_abington',
    label: 'Pennsylvania State University - Abington',
  },
  {
    key: 'pennsylvania_state_university_altoona',
    value: 'pennsylvania_state_university_altoona',
    label: 'Pennsylvania State University - Altoona',
  },
  {
    key: 'pennsylvania_state_university_at_erie_behrend_college',
    value: 'pennsylvania_state_university_at_erie_behrend_college',
    label: 'Pennsylvania State University at Erie - Behrend College',
  },
  {
    key: 'pennsylvania_state_university_at_harrisburg_the_capital_college',
    value: 'pennsylvania_state_university_at_harrisburg_the_capital_college',
    label: 'Pennsylvania State University at Harrisburg - The Capital College',
  },
  {
    key: 'pennsylvania_state_university_berks_lehigh_valley_college',
    value: 'pennsylvania_state_university_berks_lehigh_valley_college',
    label: 'Pennsylvania State University - Berks-Lehigh Valley College',
  },
  {
    key: 'pennsylvania_state_university_delaware_county',
    value: 'pennsylvania_state_university_delaware_county',
    label: 'Pennsylvania State University Delaware County',
  },
  {
    key: 'pennsylvania_state_university_great_valley',
    value: 'pennsylvania_state_university_great_valley',
    label: 'Pennsylvania State University Great Valley',
  },
  {
    key: 'pennsylvania_state_university_lehigh_valley',
    value: 'pennsylvania_state_university_lehigh_valley',
    label: 'Pennsylvania State University - Lehigh Valley',
  },
  {
    key: 'pennsylvania_state_university_milton_s_hershey_medical_center',
    value: 'pennsylvania_state_university_milton_s_hershey_medical_center',
    label: 'Pennsylvania State University - Milton S.Hershey Medical Center',
  },
  {
    key: 'pennsylvania_state_university_schuylkill',
    value: 'pennsylvania_state_university_schuylkill',
    label: 'Pennsylvania State University - Schuylkill',
  },
  { key: 'pepperdine_university', value: 'pepperdine_university', label: 'Pepperdine University' },
  { key: 'peru_state_college', value: 'peru_state_college', label: 'Peru State College' },
  { key: 'pfeiffer_university', value: 'pfeiffer_university', label: 'Pfeiffer University' },
  {
    key: 'philadelphia_college_of_bible',
    value: 'philadelphia_college_of_bible',
    label: 'Philadelphia College of Bible',
  },
  {
    key: 'philadelphia_college_of_osteopathic_medicine',
    value: 'philadelphia_college_of_osteopathic_medicine',
    label: 'Philadelphia College of Osteopathic Medicine',
  },
  {
    key: 'philadelphia_university',
    value: 'philadelphia_university',
    label: 'Philadelphia University',
  },
  {
    key: 'philander_smith_college',
    value: 'philander_smith_college',
    label: 'Philander Smith College',
  },
  {
    key: 'phillips_graduate_institute',
    value: 'phillips_graduate_institute',
    label: 'Phillips Graduate Institute',
  },
  { key: 'phillips_university', value: 'phillips_university', label: 'Phillips University' },
  {
    key: 'piedmont_baptist_college',
    value: 'piedmont_baptist_college',
    label: 'Piedmont Baptist College',
  },
  { key: 'piedmont_college', value: 'piedmont_college', label: 'Piedmont College' },
  { key: 'pikeville_college', value: 'pikeville_college', label: 'Pikeville College' },
  {
    key: 'pillsbury_baptist_bible_college',
    value: 'pillsbury_baptist_bible_college',
    label: 'Pillsbury Baptist Bible College',
  },
  {
    key: 'pittsburg_state_university',
    value: 'pittsburg_state_university',
    label: 'Pittsburg State University',
  },
  { key: 'pitzer_college', value: 'pitzer_college', label: 'Pitzer College' },
  {
    key: 'plymouth_state_college',
    value: 'plymouth_state_college',
    label: 'Plymouth State College',
  },
  {
    key: 'point_loma_nazarene_college',
    value: 'point_loma_nazarene_college',
    label: 'Point Loma Nazarene College',
  },
  { key: 'point_park_college', value: 'point_park_college', label: 'Point Park College' },
  {
    key: 'polytechnic_university',
    value: 'polytechnic_university',
    label: 'Polytechnic University',
  },
  {
    key: 'polytechnic_university_long_island_campus',
    value: 'polytechnic_university_long_island_campus',
    label: 'Polytechnic University, Long Island Campus',
  },
  {
    key: 'polytechnic_university_westchester_graduate_center',
    value: 'polytechnic_university_westchester_graduate_center',
    label: 'Polytechnic University, Westchester Graduate Center',
  },
  { key: 'pomona_college', value: 'pomona_college', label: 'Pomona College' },
  {
    key: 'portland_community_college',
    value: 'portland_community_college',
    label: 'Portland Community College',
  },
  {
    key: 'portland_state_university',
    value: 'portland_state_university',
    label: 'Portland State University',
  },
  {
    key: 'post_university_of_waterbury',
    value: 'post_university_of_waterbury',
    label: 'Post University of Waterbury',
  },
  {
    key: 'prairie_view_agricultural_and_mechanical_university',
    value: 'prairie_view_agricultural_and_mechanical_university',
    label: 'Prairie View Agricultural and Mechanical University',
  },
  { key: 'pratt_institute', value: 'pratt_institute', label: 'Pratt Institute' },
  { key: 'presbyterian_college', value: 'presbyterian_college', label: 'Presbyterian College' },
  { key: 'prescott_college', value: 'prescott_college', label: 'Prescott College' },
  { key: 'preston_university', value: 'preston_university', label: 'Preston University' },
  { key: 'princeton_university', value: 'princeton_university', label: 'Princeton University' },
  { key: 'principia_college', value: 'principia_college', label: 'Principia College' },
  { key: 'providence_college', value: 'providence_college', label: 'Providence College' },
  {
    key: 'puget_sound_christian_college',
    value: 'puget_sound_christian_college',
    label: 'Puget Sound Christian College',
  },
  { key: 'purdue_university', value: 'purdue_university', label: 'Purdue University' },
  {
    key: 'purdue_university_calumet',
    value: 'purdue_university_calumet',
    label: 'Purdue University Calumet',
  },
  {
    key: 'purdue_university_north_central',
    value: 'purdue_university_north_central',
    label: 'Purdue University North Central',
  },
  {
    key: 'quantum_veritas_international_university',
    value: 'quantum_veritas_international_university',
    label: 'Quantum-Veritas International University',
  },
  { key: 'queens_college', value: 'queens_college', label: 'Queens College' },
  { key: 'quincy_university', value: 'quincy_university', label: 'Quincy University' },
  { key: 'quinnipiac_college', value: 'quinnipiac_college', label: 'Quinnipiac College' },
  { key: 'radford_university', value: 'radford_university', label: 'Radford University' },
  {
    key: 'ramapo_college_of_new_jersey',
    value: 'ramapo_college_of_new_jersey',
    label: 'Ramapo College of New Jersey',
  },
  {
    key: 'rand_graduate_school_of_policy_studies',
    value: 'rand_graduate_school_of_policy_studies',
    label: 'Rand Graduate School of Policy Studies',
  },
  {
    key: 'randolph_macon_college',
    value: 'randolph_macon_college',
    label: 'Randolph-Macon College',
  },
  {
    key: 'randolph_macon_woman_s_college',
    value: 'randolph_macon_woman_s_college',
    label: "Randolph-Macon Woman's College",
  },
  { key: 'rasmussen_college', value: 'rasmussen_college', label: 'Rasmussen College' },
  {
    key: 'rasmussen_college_florida_campuses',
    value: 'rasmussen_college_florida_campuses',
    label: 'Rasmussen College, Florida Campuses',
  },
  {
    key: 'rasmussen_college_illinois_campuses',
    value: 'rasmussen_college_illinois_campuses',
    label: 'Rasmussen College, Illinois Campuses',
  },
  {
    key: 'rasmussen_college_minnesota_campuses',
    value: 'rasmussen_college_minnesota_campuses',
    label: 'Rasmussen College, Minnesota Campuses',
  },
  {
    key: 'rasmussen_college_north_dakota_campuses',
    value: 'rasmussen_college_north_dakota_campuses',
    label: 'Rasmussen College, North Dakota Campuses',
  },
  {
    key: 'rasmussen_college_wisconsin_campuses',
    value: 'rasmussen_college_wisconsin_campuses',
    label: 'Rasmussen College, Wisconsin Campuses',
  },
  { key: 'reed_college', value: 'reed_college', label: 'Reed College' },
  {
    key: 'reformed_bible_college',
    value: 'reformed_bible_college',
    label: 'Reformed Bible College',
  },
  {
    key: 'regent_international_university',
    value: 'regent_international_university',
    label: 'Regent International University',
  },
  { key: 'regent_university', value: 'regent_university', label: 'Regent University' },
  { key: 'regis_college', value: 'regis_college', label: 'Regis College' },
  { key: 'regis_university', value: 'regis_university', label: 'Regis University' },
  { key: 'reinhardt_college', value: 'reinhardt_college', label: 'Reinhardt College' },
  {
    key: 'rensselaer_polytechnic_institute',
    value: 'rensselaer_polytechnic_institute',
    label: 'Rensselaer Polytechnic Institute',
  },
  {
    key: 'research_college_of_nursing_rockhurst_university',
    value: 'research_college_of_nursing_rockhurst_university',
    label: 'Research College of Nursing - Rockhurst University',
  },
  { key: 'rhode_island_college', value: 'rhode_island_college', label: 'Rhode Island College' },
  {
    key: 'rhode_island_school_of_design',
    value: 'rhode_island_school_of_design',
    label: 'Rhode Island School of Design',
  },
  { key: 'rhodes_college', value: 'rhodes_college', label: 'Rhodes College' },
  { key: 'rice_university', value: 'rice_university', label: 'Rice University' },
  {
    key: 'richard_stockton_college_of_new_jersey',
    value: 'richard_stockton_college_of_new_jersey',
    label: 'Richard Stockton College of New Jersey',
  },
  { key: 'rider_university', value: 'rider_university', label: 'Rider University' },
  {
    key: 'ringling_college_of_art_and_design',
    value: 'ringling_college_of_art_and_design',
    label: 'Ringling College of Art and Design',
  },
  { key: 'ripon_college', value: 'ripon_college', label: 'Ripon College' },
  { key: 'rivier_college', value: 'rivier_college', label: 'Rivier College' },
  { key: 'roanoke_bible_college', value: 'roanoke_bible_college', label: 'Roanoke Bible College' },
  { key: 'roanoke_college', value: 'roanoke_college', label: 'Roanoke College' },
  { key: 'robert_morris_college', value: 'robert_morris_college', label: 'Robert Morris College' },
  {
    key: 'robert_morris_college_of_chicago',
    value: 'robert_morris_college_of_chicago',
    label: 'Robert Morris College of Chicago',
  },
  {
    key: 'roberts_wesleyan_college',
    value: 'roberts_wesleyan_college',
    label: 'Roberts Wesleyan College',
  },
  { key: 'rochester_college', value: 'rochester_college', label: 'Rochester College' },
  {
    key: 'rochester_institute_of_technology',
    value: 'rochester_institute_of_technology',
    label: 'Rochester Institute of Technology',
  },
  { key: 'rockford_college', value: 'rockford_college', label: 'Rockford College' },
  { key: 'rockhurst_college', value: 'rockhurst_college', label: 'Rockhurst College' },
  { key: 'rock_valley_college', value: 'rock_valley_college', label: 'Rock Valley College' },
  {
    key: 'rocky_mountain_college',
    value: 'rocky_mountain_college',
    label: 'Rocky Mountain College',
  },
  {
    key: 'rocky_mountain_college_of_art_and_design',
    value: 'rocky_mountain_college_of_art_and_design',
    label: 'Rocky Mountain College of Art and Design',
  },
  {
    key: 'rogers_state_university',
    value: 'rogers_state_university',
    label: 'Rogers State University',
  },
  {
    key: 'roger_williams_university',
    value: 'roger_williams_university',
    label: 'Roger Williams University',
  },
  { key: 'rollins_college', value: 'rollins_college', label: 'Rollins College' },
  { key: 'roosevelt_university', value: 'roosevelt_university', label: 'Roosevelt University' },
  {
    key: 'rose_hulman_institute_of_technology',
    value: 'rose_hulman_institute_of_technology',
    label: 'Rose-Hulman Institute of Technology',
  },
  { key: 'rosemont_college', value: 'rosemont_college', label: 'Rosemont College' },
  {
    key: 'ross_university_school_of_medicine',
    value: 'ross_university_school_of_medicine',
    label: 'Ross University, School of Medicine',
  },
  { key: 'rowan_university', value: 'rowan_university', label: 'Rowan University' },
  { key: 'rush_university', value: 'rush_university', label: 'Rush University' },
  { key: 'russel_sage_college', value: 'russel_sage_college', label: 'Russel Sage College' },
  { key: 'rust_college', value: 'rust_college', label: 'Rust College' },
  {
    key: 'rutgers_the_state_university_of_new_jersey',
    value: 'rutgers_the_state_university_of_new_jersey',
    label: 'Rutgers, The State University of New Jersey',
  },
  {
    key: 'rutgers_the_state_university_of_new_jersey_camden',
    value: 'rutgers_the_state_university_of_new_jersey_camden',
    label: 'Rutgers, The State University of New Jersey - Camden',
  },
  {
    key: 'rutgers_the_state_university_of_new_jersey_newark',
    value: 'rutgers_the_state_university_of_new_jersey_newark',
    label: 'Rutgers, The State University of New Jersey - Newark',
  },
  {
    key: 'sacred_heart_university',
    value: 'sacred_heart_university',
    label: 'Sacred Heart University',
  },
  { key: 'sage_graduate_school', value: 'sage_graduate_school', label: 'Sage Graduate School' },
  {
    key: 'saginaw_valley_state_university',
    value: 'saginaw_valley_state_university',
    label: 'Saginaw Valley State University',
  },
  { key: 'salem_college', value: 'salem_college', label: 'Salem College' },
  {
    key: 'salem_international_university',
    value: 'salem_international_university',
    label: 'Salem International University',
  },
  { key: 'salem_state_college', value: 'salem_state_college', label: 'Salem State College' },
  {
    key: 'salem_teikyo_university',
    value: 'salem_teikyo_university',
    label: 'Salem Teikyo University',
  },
  {
    key: 'salisbury_state_university',
    value: 'salisbury_state_university',
    label: 'Salisbury State University',
  },
  {
    key: 'salve_regina_university',
    value: 'salve_regina_university',
    label: 'Salve Regina University',
  },
  { key: 'samford_university', value: 'samford_university', label: 'Samford University' },
  {
    key: 'sam_houston_state_university',
    value: 'sam_houston_state_university',
    label: 'Sam Houston State University',
  },
  {
    key: 'samuel_merritt_college',
    value: 'samuel_merritt_college',
    label: 'Samuel Merritt College',
  },
  {
    key: 'san_diego_state_university',
    value: 'san_diego_state_university',
    label: 'San Diego State University',
  },
  {
    key: 'san_diego_university_for_integrative_studies',
    value: 'san_diego_university_for_integrative_studies',
    label: 'San Diego University for Integrative Studies',
  },
  {
    key: 'sanford_brown_institute',
    value: 'sanford_brown_institute',
    label: 'Sanford-Brown Institute',
  },
  {
    key: 'san_francisco_art_institute',
    value: 'san_francisco_art_institute',
    label: 'San Francisco Art Institute',
  },
  {
    key: 'san_francisco_conservatory_of_music',
    value: 'san_francisco_conservatory_of_music',
    label: 'San Francisco Conservatory of Music',
  },
  {
    key: 'san_francisco_state_university',
    value: 'san_francisco_state_university',
    label: 'San Francisco State University',
  },
  {
    key: 'san_joaquin_college_of_law',
    value: 'san_joaquin_college_of_law',
    label: 'San Joaquin College of Law',
  },
  {
    key: 'san_jose_christian_college',
    value: 'san_jose_christian_college',
    label: 'San Jose Christian College',
  },
  {
    key: 'san_jose_state_university',
    value: 'san_jose_state_university',
    label: 'San Jose State University',
  },
  {
    key: 'santa_clara_university',
    value: 'santa_clara_university',
    label: 'Santa Clara University',
  },
  {
    key: 'sarah_lawrence_college',
    value: 'sarah_lawrence_college',
    label: 'Sarah Lawrence College',
  },
  {
    key: 'savannah_college_of_art_and_design',
    value: 'savannah_college_of_art_and_design',
    label: 'Savannah College of Art and Design',
  },
  {
    key: 'savannah_state_university',
    value: 'savannah_state_university',
    label: 'Savannah State University',
  },
  { key: 'saybrook_institute', value: 'saybrook_institute', label: 'Saybrook Institute' },
  {
    key: 'schiller_international_university',
    value: 'schiller_international_university',
    label: 'Schiller International University',
  },
  {
    key: 'scholl_college_of_podiatric_medicine',
    value: 'scholl_college_of_podiatric_medicine',
    label: 'Scholl College of Podiatric Medicine',
  },
  {
    key: 'school_for_international_training',
    value: 'school_for_international_training',
    label: 'School for International Training',
  },
  {
    key: 'school_of_the_museum_of_fine_arts',
    value: 'school_of_the_museum_of_fine_arts',
    label: 'School of the Museum of Fine Arts',
  },
  {
    key: 'school_of_the_visual_arts',
    value: 'school_of_the_visual_arts',
    label: 'School of the Visual Arts',
  },
  { key: 'schreiner_college', value: 'schreiner_college', label: 'Schreiner College' },
  { key: 'scripps_college', value: 'scripps_college', label: 'Scripps College' },
  {
    key: 'seattle_pacific_university',
    value: 'seattle_pacific_university',
    label: 'Seattle Pacific University',
  },
  { key: 'seattle_university', value: 'seattle_university', label: 'Seattle University' },
  { key: 'seton_hall_university', value: 'seton_hall_university', label: 'Seton Hall University' },
  { key: 'seton_hill_college', value: 'seton_hill_college', label: 'Seton Hill College' },
  {
    key: 'shawnee_community_college',
    value: 'shawnee_community_college',
    label: 'Shawnee Community College',
  },
  {
    key: 'shawnee_state_university',
    value: 'shawnee_state_university',
    label: 'Shawnee State University',
  },
  { key: 'shaw_university', value: 'shaw_university', label: 'Shaw University' },
  {
    key: 'sheldon_jackson_college',
    value: 'sheldon_jackson_college',
    label: 'Sheldon Jackson College',
  },
  { key: 'shenandoah_university', value: 'shenandoah_university', label: 'Shenandoah University' },
  { key: 'shepherd_college', value: 'shepherd_college', label: 'Shepherd College' },
  {
    key: 'sherman_college_of_straight_chiropractic',
    value: 'sherman_college_of_straight_chiropractic',
    label: 'Sherman College of Straight Chiropractic',
  },
  { key: 'shimer_college', value: 'shimer_college', label: 'Shimer College' },
  {
    key: 'shippensburg_university_of_pennsylvania',
    value: 'shippensburg_university_of_pennsylvania',
    label: 'Shippensburg University of Pennsylvania',
  },
  {
    key: 'shoreline_community_college',
    value: 'shoreline_community_college',
    label: 'Shoreline Community College',
  },
  { key: 'shorter_college', value: 'shorter_college', label: 'Shorter College' },
  { key: 'siena_college', value: 'siena_college', label: 'Siena College' },
  {
    key: 'siena_heights_university',
    value: 'siena_heights_university',
    label: 'Siena Heights University',
  },
  { key: 'sierra_nevada_college', value: 'sierra_nevada_college', label: 'Sierra Nevada College' },
  { key: 'silver_lake_college', value: 'silver_lake_college', label: 'Silver Lake College' },
  { key: 'simmons_college', value: 'simmons_college', label: 'Simmons College' },
  { key: 'simon_s_rock_college', value: 'simon_s_rock_college', label: "Simon's Rock College" },
  { key: 'simpson_college', value: 'simpson_college', label: 'Simpson College' },
  { key: 'simpson_college_iowa', value: 'simpson_college_iowa', label: 'Simpson College Iowa' },
  {
    key: 'sinte_gleska_university',
    value: 'sinte_gleska_university',
    label: 'Sinte Gleska University',
  },
  { key: 'skadron_college', value: 'skadron_college', label: 'Skadron College' },
  { key: 'skidmore_college', value: 'skidmore_college', label: 'Skidmore College' },
  {
    key: 'slippery_rock_university',
    value: 'slippery_rock_university',
    label: 'Slippery Rock University',
  },
  { key: 'smith_college', value: 'smith_college', label: 'Smith College' },
  {
    key: 'sojourner_douglass_college',
    value: 'sojourner_douglass_college',
    label: 'Sojourner-Douglass College',
  },
  {
    key: 'soka_university_of_america',
    value: 'soka_university_of_america',
    label: 'Soka University of America',
  },
  {
    key: 'sonoma_state_university',
    value: 'sonoma_state_university',
    label: 'Sonoma State University',
  },
  {
    key: 'south_carolina_state_university',
    value: 'south_carolina_state_university',
    label: 'South Carolina State University',
  },
  {
    key: 'south_dakota_school_of_mines_and_technology',
    value: 'south_dakota_school_of_mines_and_technology',
    label: 'South Dakota School of Mines and Technology',
  },
  {
    key: 'south_dakota_state_university',
    value: 'south_dakota_state_university',
    label: 'South Dakota State University',
  },
  {
    key: 'southeastern_baptist_college',
    value: 'southeastern_baptist_college',
    label: 'Southeastern Baptist College',
  },
  {
    key: 'southeastern_bible_college',
    value: 'southeastern_bible_college',
    label: 'Southeastern Bible College',
  },
  {
    key: 'southeastern_college_of_the_assemblies_of_god',
    value: 'southeastern_college_of_the_assemblies_of_god',
    label: 'Southeastern College of the Assemblies of God',
  },
  {
    key: 'southeastern_louisiana_university',
    value: 'southeastern_louisiana_university',
    label: 'Southeastern Louisiana University',
  },
  {
    key: 'southeastern_oklahoma_state_university',
    value: 'southeastern_oklahoma_state_university',
    label: 'Southeastern Oklahoma State University',
  },
  {
    key: 'southeastern_university',
    value: 'southeastern_university',
    label: 'Southeastern University',
  },
  {
    key: 'southeast_missouri_state_university',
    value: 'southeast_missouri_state_university',
    label: 'Southeast Missouri State University',
  },
  {
    key: 'southern_adventist_university',
    value: 'southern_adventist_university',
    label: 'Southern Adventist University',
  },
  {
    key: 'southern_arkansas_university',
    value: 'southern_arkansas_university',
    label: 'Southern Arkansas University',
  },
  {
    key: 'southern_california_college',
    value: 'southern_california_college',
    label: 'Southern California College',
  },
  {
    key: 'southern_california_college_of_optometry',
    value: 'southern_california_college_of_optometry',
    label: 'Southern California College of Optometry',
  },
  {
    key: 'southern_california_institute_of_architecture',
    value: 'southern_california_institute_of_architecture',
    label: 'Southern California Institute of Architecture',
  },
  {
    key: 'southern_college_of_optometry',
    value: 'southern_college_of_optometry',
    label: 'Southern College of Optometry',
  },
  {
    key: 'southern_connecticut_state_university',
    value: 'southern_connecticut_state_university',
    label: 'Southern Connecticut State University',
  },
  {
    key: 'southern_illinois_university_at_carbondale',
    value: 'southern_illinois_university_at_carbondale',
    label: 'Southern Illinois University at Carbondale',
  },
  {
    key: 'southern_illinois_university_at_edwardsville',
    value: 'southern_illinois_university_at_edwardsville',
    label: 'Southern Illinois University at Edwardsville',
  },
  {
    key: 'southern_methodist_university',
    value: 'southern_methodist_university',
    label: 'Southern Methodist University',
  },
  {
    key: 'southern_nazarene_university',
    value: 'southern_nazarene_university',
    label: 'Southern Nazarene University',
  },
  {
    key: 'southern_new_hampshire_university',
    value: 'southern_new_hampshire_university',
    label: 'Southern New Hampshire University',
  },
  {
    key: 'southern_oregon_university',
    value: 'southern_oregon_university',
    label: 'Southern Oregon University',
  },
  {
    key: 'southern_polytechnic_state_univerisity',
    value: 'southern_polytechnic_state_univerisity',
    label: 'Southern Polytechnic State Univerisity',
  },
  {
    key: 'southern_university_baton_rouge',
    value: 'southern_university_baton_rouge',
    label: 'Southern University - Baton Rouge',
  },
  {
    key: 'southern_university_new_orleans',
    value: 'southern_university_new_orleans',
    label: 'Southern University - New Orleans',
  },
  {
    key: 'southern_university_shreveport',
    value: 'southern_university_shreveport',
    label: 'Southern University - Shreveport',
  },
  {
    key: 'southern_utah_university',
    value: 'southern_utah_university',
    label: 'Southern Utah University',
  },
  {
    key: 'southern_vermont_college',
    value: 'southern_vermont_college',
    label: 'Southern Vermont College',
  },
  {
    key: 'southern_wesleyan_university',
    value: 'southern_wesleyan_university',
    label: 'Southern Wesleyan University',
  },
  {
    key: 'south_florida_bible_college_theological_seminary',
    value: 'south_florida_bible_college_theological_seminary',
    label: 'South Florida Bible College & Theological Seminary',
  },
  {
    key: 'south_texas_college_of_law',
    value: 'south_texas_college_of_law',
    label: 'South Texas College of Law',
  },
  {
    key: 'southwest_baptist_university',
    value: 'southwest_baptist_university',
    label: 'Southwest Baptist University',
  },
  {
    key: 'southwestern_adventist_university',
    value: 'southwestern_adventist_university',
    label: 'Southwestern Adventist University',
  },
  {
    key: 'southwestern_assemblies_of_god_university',
    value: 'southwestern_assemblies_of_god_university',
    label: 'Southwestern Assemblies of God University',
  },
  {
    key: 'southwestern_baptist_theological_seminary',
    value: 'southwestern_baptist_theological_seminary',
    label: 'Southwestern Baptist Theological Seminary',
  },
  {
    key: 'southwestern_christian_college',
    value: 'southwestern_christian_college',
    label: 'Southwestern Christian College',
  },
  {
    key: 'southwestern_christian_university',
    value: 'southwestern_christian_university',
    label: 'Southwestern Christian University',
  },
  {
    key: 'southwestern_college_kansas',
    value: 'southwestern_college_kansas',
    label: 'Southwestern College Kansas',
  },
  {
    key: 'southwestern_college_santa_fe',
    value: 'southwestern_college_santa_fe',
    label: 'Southwestern College Santa Fe',
  },
  {
    key: 'southwestern_oklahoma_state_university',
    value: 'southwestern_oklahoma_state_university',
    label: 'Southwestern Oklahoma State University',
  },
  {
    key: 'southwestern_university',
    value: 'southwestern_university',
    label: 'Southwestern University',
  },
  {
    key: 'southwestern_university_school_of_law',
    value: 'southwestern_university_school_of_law',
    label: 'Southwestern University School of Law',
  },
  {
    key: 'southwest_missouri_state_university',
    value: 'southwest_missouri_state_university',
    label: 'Southwest Missouri State University',
  },
  {
    key: 'southwest_missouri_state_university_west_plains',
    value: 'southwest_missouri_state_university_west_plains',
    label: 'Southwest Missouri State University - West Plains',
  },
  {
    key: 'southwest_state_university',
    value: 'southwest_state_university',
    label: 'Southwest State University',
  },
  {
    key: 'southwest_texas_state_university',
    value: 'southwest_texas_state_university',
    label: 'Southwest Texas State University',
  },
  { key: 'southwest_university', value: 'southwest_university', label: 'Southwest University' },
  { key: 'spalding_university', value: 'spalding_university', label: 'Spalding University' },
  { key: 'spelman_college', value: 'spelman_college', label: 'Spelman College' },
  {
    key: 'spertus_institute_of_jewish_studies',
    value: 'spertus_institute_of_jewish_studies',
    label: 'Spertus Institute of Jewish Studies',
  },
  { key: 'spring_arbor_college', value: 'spring_arbor_college', label: 'Spring Arbor College' },
  { key: 'springfield_college', value: 'springfield_college', label: 'Springfield College' },
  { key: 'spring_hill_college', value: 'spring_hill_college', label: 'Spring Hill College' },
  { key: 'st_ambrose_university', value: 'st_ambrose_university', label: 'St. Ambrose University' },
  {
    key: 'standford_online_university',
    value: 'standford_online_university',
    label: 'Standford Online University',
  },
  { key: 'standford_university', value: 'standford_university', label: 'Standford University' },
  {
    key: 'st_andrews_presbyterian_college',
    value: 'st_andrews_presbyterian_college',
    label: 'St. Andrews Presbyterian College',
  },
  { key: 'stanford_university', value: 'stanford_university', label: 'Stanford University' },
  { key: 'st_anselm_college', value: 'st_anselm_college', label: 'St. Anselm College' },
  {
    key: 'st_anthony_college_of_nursing',
    value: 'st_anthony_college_of_nursing',
    label: 'St. Anthony College of Nursing',
  },
  {
    key: 'state_university_of_new_york_at_albany',
    value: 'state_university_of_new_york_at_albany',
    label: 'State University of New York at Albany',
  },
  {
    key: 'state_university_of_new_york_at_binghamton',
    value: 'state_university_of_new_york_at_binghamton',
    label: 'State University of New York at Binghamton',
  },
  {
    key: 'state_university_of_new_york_at_buffalo',
    value: 'state_university_of_new_york_at_buffalo',
    label: 'State University of New York at Buffalo',
  },
  {
    key: 'state_university_of_new_york_at_new_paltz',
    value: 'state_university_of_new_york_at_new_paltz',
    label: 'State University of New York at New Paltz',
  },
  {
    key: 'state_university_of_new_york_at_oswego',
    value: 'state_university_of_new_york_at_oswego',
    label: 'State University of New York at Oswego',
  },
  {
    key: 'state_university_of_new_york_at_stony_brook',
    value: 'state_university_of_new_york_at_stony_brook',
    label: 'State University of New York at Stony Brook',
  },
  {
    key: 'state_university_of_new_york_college_at_brockport',
    value: 'state_university_of_new_york_college_at_brockport',
    label: 'State University of New York College at Brockport',
  },
  {
    key: 'state_university_of_new_york_college_at_cortland',
    value: 'state_university_of_new_york_college_at_cortland',
    label: 'State University of New York College at Cortland',
  },
  {
    key: 'state_university_of_new_york_college_at_fredonia',
    value: 'state_university_of_new_york_college_at_fredonia',
    label: 'State University of New York College at Fredonia',
  },
  {
    key: 'state_university_of_new_york_college_at_geneseo',
    value: 'state_university_of_new_york_college_at_geneseo',
    label: 'State University of New York College at Geneseo',
  },
  {
    key: 'state_university_of_new_york_college_at_old_westbury',
    value: 'state_university_of_new_york_college_at_old_westbury',
    label: 'State University of New York College at Old Westbury',
  },
  {
    key: 'state_university_of_new_york_college_at_oneonta',
    value: 'state_university_of_new_york_college_at_oneonta',
    label: 'State University of New York College at Oneonta',
  },
  {
    key: 'state_university_of_new_york_college_at_plattsburgh',
    value: 'state_university_of_new_york_college_at_plattsburgh',
    label: 'State University of New York College at Plattsburgh',
  },
  {
    key: 'state_university_of_new_york_college_at_potsdam',
    value: 'state_university_of_new_york_college_at_potsdam',
    label: 'State University of New York College at Potsdam',
  },
  {
    key: 'state_university_of_new_york_college_at_purchase',
    value: 'state_university_of_new_york_college_at_purchase',
    label: 'State University of New York College at Purchase',
  },
  {
    key: 'state_university_of_new_york_college_of_agriculture_and_technology_at_cobleskill',
    value: 'state_university_of_new_york_college_of_agriculture_and_technology_at_cobleskill',
    label: 'State University of New York College of Agriculture and Technology at Cobleskill',
  },
  {
    key: 'state_university_of_new_york_college_of_environmental_science_and_forestry',
    value: 'state_university_of_new_york_college_of_environmental_science_and_forestry',
    label: 'State University of New York College of Environmental Science and Forestry',
  },
  {
    key: 'state_university_of_new_york_college_of_optometry',
    value: 'state_university_of_new_york_college_of_optometry',
    label: 'State University of New York College of Optometry',
  },
  {
    key: 'state_university_of_new_york_college_of_technology_at_alfred',
    value: 'state_university_of_new_york_college_of_technology_at_alfred',
    label: 'State University of New York College of Technology at Alfred',
  },
  {
    key: 'state_university_of_new_york_college_of_technology_at_farmingdale',
    value: 'state_university_of_new_york_college_of_technology_at_farmingdale',
    label: 'State University of New York College of Technology at Farmingdale',
  },
  {
    key: 'state_university_of_new_york_downstate_medical_center',
    value: 'state_university_of_new_york_downstate_medical_center',
    label: 'State University of New York Downstate Medical Center',
  },
  {
    key: 'state_university_of_new_york_empire_state_college',
    value: 'state_university_of_new_york_empire_state_college',
    label: 'State University of New York Empire State College',
  },
  {
    key: 'state_university_of_new_york_health_sience_centre_syracuse',
    value: 'state_university_of_new_york_health_sience_centre_syracuse',
    label: 'State University of New York Health Sience Centre Syracuse',
  },
  {
    key: 'state_university_of_new_york_institute_of_technology_at_utica_rome',
    value: 'state_university_of_new_york_institute_of_technology_at_utica_rome',
    label: 'State University of New York Institute of Technology at Utica/Rome',
  },
  {
    key: 'state_university_of_new_york_maritime_college',
    value: 'state_university_of_new_york_maritime_college',
    label: 'State University of New York Maritime College',
  },
  {
    key: 'state_university_of_new_york_school_of_engineering_and_applied_sciences',
    value: 'state_university_of_new_york_school_of_engineering_and_applied_sciences',
    label: 'State University of New York School of Engineering and Applied Sciences',
  },
  {
    key: 'state_university_of_new_york_suny',
    value: 'state_university_of_new_york_suny',
    label: 'State University of New York (SUNY)',
  },
  {
    key: 'state_university_of_new_york_upstate_medical_university',
    value: 'state_university_of_new_york_upstate_medical_university',
    label: 'State University of New York Upstate Medical University',
  },
  {
    key: 'state_university_of_west_georgia',
    value: 'state_university_of_west_georgia',
    label: 'State University of West Georgia',
  },
  {
    key: 'st_augustine_s_college_north_carolina',
    value: 'st_augustine_s_college_north_carolina',
    label: "St. Augustine's College North Carolina",
  },
  {
    key: 'st_bernard_s_institute',
    value: 'st_bernard_s_institute',
    label: "St. Bernard's Institute",
  },
  {
    key: 'st_bonaventure_university',
    value: 'st_bonaventure_university',
    label: 'St. Bonaventure University',
  },
  {
    key: 'st_cloud_state_university',
    value: 'st_cloud_state_university',
    label: 'St. Cloud State University',
  },
  { key: 'st_edwards_university', value: 'st_edwards_university', label: 'St. Edwards University' },
  { key: 'stefan_university', value: 'stefan_university', label: 'Stefan University' },
  {
    key: 'stephen_f_austin_state_university',
    value: 'stephen_f_austin_state_university',
    label: 'Stephen F. Austin State University',
  },
  { key: 'stephens_college', value: 'stephens_college', label: 'Stephens College' },
  { key: 'sterling_college', value: 'sterling_college', label: 'Sterling College' },
  { key: 'stetson_university', value: 'stetson_university', label: 'Stetson University' },
  {
    key: 'stevens_institute_of_technology',
    value: 'stevens_institute_of_technology',
    label: 'Stevens Institute of Technology',
  },
  {
    key: 'st_francis_college_brooklyn_heights',
    value: 'st_francis_college_brooklyn_heights',
    label: 'St. Francis College, Brooklyn Heights',
  },
  {
    key: 'st_francis_college_fort_wayne',
    value: 'st_francis_college_fort_wayne',
    label: 'St. Francis College, Fort Wayne',
  },
  {
    key: 'st_francis_college_loretto',
    value: 'st_francis_college_loretto',
    label: 'St. Francis College, Loretto',
  },
  {
    key: 'st_francis_medical_center_college_of_nursing',
    value: 'st_francis_medical_center_college_of_nursing',
    label: 'St. Francis Medical Center College of Nursing',
  },
  {
    key: 'st_george_s_university',
    value: 'st_george_s_university',
    label: "St. George's University",
  },
  { key: 'stillman_college', value: 'stillman_college', label: 'Stillman College' },
  {
    key: 'st_john_fisher_college',
    value: 'st_john_fisher_college',
    label: 'St. John Fisher College',
  },
  {
    key: 'st_john_s_college_maryland',
    value: 'st_john_s_college_maryland',
    label: "St. John's College Maryland",
  },
  {
    key: 'st_john_s_college_new_mexico',
    value: 'st_john_s_college_new_mexico',
    label: "St. John's College New Mexico",
  },
  { key: 'st_john_s_seminary', value: 'st_john_s_seminary', label: "St. John's Seminary" },
  { key: 'st_john_s_university', value: 'st_john_s_university', label: "St. John's University" },
  { key: 'st_joseph_college', value: 'st_joseph_college', label: 'St. Joseph College' },
  {
    key: 'st_joseph_college_of_nursing',
    value: 'st_joseph_college_of_nursing',
    label: 'St. Joseph College of Nursing',
  },
  { key: 'st_joseph_s_college', value: 'st_joseph_s_college', label: "St. Joseph's College" },
  {
    key: 'st_joseph_s_college_new_york',
    value: 'st_joseph_s_college_new_york',
    label: "St. Joseph's College New York",
  },
  {
    key: 'st_joseph_s_college_new_york_suffolk_campus',
    value: 'st_joseph_s_college_new_york_suffolk_campus',
    label: "St. Joseph's College New York, Suffolk Campus",
  },
  {
    key: 'st_joseph_s_college_of_maine',
    value: 'st_joseph_s_college_of_maine',
    label: "St. Joseph's College of Maine",
  },
  {
    key: 'st_joseph_s_university',
    value: 'st_joseph_s_university',
    label: "St. Joseph's University",
  },
  {
    key: 'st_lawrence_university',
    value: 'st_lawrence_university',
    label: 'St. Lawrence University',
  },
  { key: 'st_leo_college', value: 'st_leo_college', label: 'St. Leo College' },
  {
    key: 'st_louis_christian_college',
    value: 'st_louis_christian_college',
    label: 'St. Louis Christian College',
  },
  {
    key: 'st_louis_college_of_pharmacy',
    value: 'st_louis_college_of_pharmacy',
    label: 'St. Louis College of Pharmacy',
  },
  { key: 'st_louis_university', value: 'st_louis_university', label: 'St. Louis University' },
  { key: 'st_luke_s_college', value: 'st_luke_s_college', label: "St. Luke's College" },
  { key: 'st_martin_s_college', value: 'st_martin_s_college', label: "St. Martin's College" },
  { key: 'st_mary_college', value: 'st_mary_college', label: 'St. Mary College' },
  {
    key: 'st_mary_of_the_woods_college',
    value: 'st_mary_of_the_woods_college',
    label: 'St. Mary-of-the-Woods College',
  },
  {
    key: 'st_mary_s_college_indiana',
    value: 'st_mary_s_college_indiana',
    label: "St. Mary's College Indiana",
  },
  {
    key: 'st_mary_s_college_of_california',
    value: 'st_mary_s_college_of_california',
    label: "St. Mary's College of California",
  },
  {
    key: 'st_mary_s_college_of_maryland',
    value: 'st_mary_s_college_of_maryland',
    label: "St. Mary's College of Maryland",
  },
  {
    key: 'st_mary_s_university_of_minnesota',
    value: 'st_mary_s_university_of_minnesota',
    label: "St. Mary's University of Minnesota",
  },
  {
    key: 'st_mary_s_university_of_san_antonio',
    value: 'st_mary_s_university_of_san_antonio',
    label: "St. Mary's University of San Antonio",
  },
  { key: 'st_meinrad_college', value: 'st_meinrad_college', label: 'St. Meinrad College' },
  { key: 'st_michael_s_college', value: 'st_michael_s_college', label: "St. Michael's College" },
  { key: 'st_norbert_college', value: 'st_norbert_college', label: 'St. Norbert College' },
  { key: 'st_olaf_college', value: 'st_olaf_college', label: 'St. Olaf College' },
  { key: 'stonehill_college', value: 'stonehill_college', label: 'Stonehill College' },
  { key: 'st_paul_s_college', value: 'st_paul_s_college', label: "St. Paul's College" },
  { key: 'st_petersburg_college', value: 'st_petersburg_college', label: 'St. Petersburg College' },
  { key: 'st_peter_s_college', value: 'st_peter_s_college', label: "St. Peter's College" },
  { key: 'strayer_university', value: 'strayer_university', label: 'Strayer University' },
  {
    key: 'st_thomas_aquinas_college',
    value: 'st_thomas_aquinas_college',
    label: 'St. Thomas Aquinas College',
  },
  { key: 'st_thomas_university', value: 'st_thomas_university', label: 'St. Thomas University' },
  { key: 'st_vincent_college', value: 'st_vincent_college', label: 'St. Vincent College' },
  { key: 'st_xavier_university', value: 'st_xavier_university', label: 'St. Xavier University' },
  { key: 'suffolk_university', value: 'suffolk_university', label: 'Suffolk University' },
  { key: 'sullivan_college', value: 'sullivan_college', label: 'Sullivan College' },
  {
    key: 'sul_ross_state_university',
    value: 'sul_ross_state_university',
    label: 'Sul Ross State University',
  },
  {
    key: 'susquehanna_university',
    value: 'susquehanna_university',
    label: 'Susquehanna University',
  },
  { key: 'swarthmore_college', value: 'swarthmore_college', label: 'Swarthmore College' },
  { key: 'sweet_briar_college', value: 'sweet_briar_college', label: 'Sweet Briar College' },
  { key: 'syracuse_university', value: 'syracuse_university', label: 'Syracuse University' },
  { key: 'tabor_college', value: 'tabor_college', label: 'Tabor College' },
  { key: 'talladega_college', value: 'talladega_college', label: 'Talladega College' },
  {
    key: 'tarleton_state_university',
    value: 'tarleton_state_university',
    label: 'Tarleton State University',
  },
  { key: 'taylor_university', value: 'taylor_university', label: 'Taylor University' },
  {
    key: 'taylor_university_fort_wayne_campus',
    value: 'taylor_university_fort_wayne_campus',
    label: 'Taylor University, Fort Wayne Campus',
  },
  {
    key: 'teachers_college_columbia_university',
    value: 'teachers_college_columbia_university',
    label: 'Teachers College, Columbia University',
  },
  { key: 'temple_university', value: 'temple_university', label: 'Temple University' },
  {
    key: 'temple_university_school_of_podiatric_medicine',
    value: 'temple_university_school_of_podiatric_medicine',
    label: 'Temple University School of Podiatric Medicine',
  },
  {
    key: 'tennessee_state_university',
    value: 'tennessee_state_university',
    label: 'Tennessee State University',
  },
  {
    key: 'tennessee_technological_university',
    value: 'tennessee_technological_university',
    label: 'Tennessee Technological University',
  },
  {
    key: 'tennessee_temple_university',
    value: 'tennessee_temple_university',
    label: 'Tennessee Temple University',
  },
  {
    key: 'tennessee_wesleyan_college',
    value: 'tennessee_wesleyan_college',
    label: 'Tennessee Wesleyan College',
  },
  {
    key: 'texas_a_m_international_university',
    value: 'texas_a_m_international_university',
    label: 'Texas A&M International University',
  },
  { key: 'texas_a_m_university', value: 'texas_a_m_university', label: 'Texas A&M University' },
  {
    key: 'texas_a_m_university_commerce',
    value: 'texas_a_m_university_commerce',
    label: 'Texas A&M University - Commerce',
  },
  {
    key: 'texas_a_m_university_corpus_christi',
    value: 'texas_a_m_university_corpus_christi',
    label: 'Texas A&M University - Corpus Christi',
  },
  {
    key: 'texas_a_m_university_galveston',
    value: 'texas_a_m_university_galveston',
    label: 'Texas A&M University - Galveston',
  },
  {
    key: 'texas_a_m_university_kingsville',
    value: 'texas_a_m_university_kingsville',
    label: 'Texas A&M University - Kingsville',
  },
  {
    key: 'texas_a_m_university_texarkana',
    value: 'texas_a_m_university_texarkana',
    label: 'Texas A&M University - Texarkana',
  },
  {
    key: 'texas_chiropractic_college',
    value: 'texas_chiropractic_college',
    label: 'Texas Chiropractic College',
  },
  {
    key: 'texas_christian_university',
    value: 'texas_christian_university',
    label: 'Texas Christian University',
  },
  { key: 'texas_college', value: 'texas_college', label: 'Texas College' },
  {
    key: 'texas_college_of_osteopathic_medicine',
    value: 'texas_college_of_osteopathic_medicine',
    label: 'Texas College of Osteopathic Medicine',
  },
  {
    key: 'texas_lutheran_university',
    value: 'texas_lutheran_university',
    label: 'Texas Lutheran University',
  },
  {
    key: 'texas_southern_university',
    value: 'texas_southern_university',
    label: 'Texas Southern University',
  },
  { key: 'texas_tech_university', value: 'texas_tech_university', label: 'Texas Tech University' },
  {
    key: 'texas_tech_university_health_science_center',
    value: 'texas_tech_university_health_science_center',
    label: 'Texas Tech University Health Science Center',
  },
  {
    key: 'texas_wesleyan_university',
    value: 'texas_wesleyan_university',
    label: 'Texas Wesleyan University',
  },
  {
    key: 'texas_woman_s_university',
    value: 'texas_woman_s_university',
    label: "Texas Woman's University",
  },
  { key: 'the_american_college', value: 'the_american_college', label: 'The American College' },
  {
    key: 'the_art_institute_of_boston',
    value: 'the_art_institute_of_boston',
    label: 'The Art Institute of Boston',
  },
  {
    key: 'the_art_institutes_international_portland',
    value: 'the_art_institutes_international_portland',
    label: 'The Art Institutes International Portland',
  },
  {
    key: 'the_art_institutes_international_san_francisco',
    value: 'the_art_institutes_international_san_francisco',
    label: 'The Art Institutes International San Francisco',
  },
  {
    key: 'the_boston_conservatory',
    value: 'the_boston_conservatory',
    label: 'The Boston Conservatory',
  },
  {
    key: 'the_catholic_university_of_america',
    value: 'the_catholic_university_of_america',
    label: 'The Catholic University of America',
  },
  {
    key: 'the_chicago_school_of_professional_psychology',
    value: 'the_chicago_school_of_professional_psychology',
    label: 'The Chicago School of Professional Psychology',
  },
  {
    key: 'the_college_of_insurance',
    value: 'the_college_of_insurance',
    label: 'The College of Insurance',
  },
  {
    key: 'the_college_of_new_jersey',
    value: 'the_college_of_new_jersey',
    label: 'The College of New Jersey',
  },
  {
    key: 'the_college_of_santa_fe',
    value: 'the_college_of_santa_fe',
    label: 'The College of Santa Fe',
  },
  {
    key: 'the_college_of_st_scholastica',
    value: 'the_college_of_st_scholastica',
    label: 'The College of St. Scholastica',
  },
  {
    key: 'the_college_of_westchester',
    value: 'the_college_of_westchester',
    label: 'The College of Westchester',
  },
  {
    key: 'the_college_of_wooster',
    value: 'the_college_of_wooster',
    label: 'The College of Wooster',
  },
  {
    key: 'the_cooper_union_for_the_advancement_of_science_and_art',
    value: 'the_cooper_union_for_the_advancement_of_science_and_art',
    label: 'The Cooper Union for the Advancement of Science and Art',
  },
  {
    key: 'the_corcoran_college_of_art',
    value: 'the_corcoran_college_of_art',
    label: 'The Corcoran College of Art',
  },
  {
    key: 'the_curtis_institute_of_music',
    value: 'the_curtis_institute_of_music',
    label: 'The Curtis Institute of Music',
  },
  { key: 'the_defiance_college', value: 'the_defiance_college', label: 'The Defiance College' },
  {
    key: 'the_dickinson_school_of_law',
    value: 'the_dickinson_school_of_law',
    label: 'The Dickinson School of Law',
  },
  {
    key: 'the_illinois_institute_of_art_chicago',
    value: 'the_illinois_institute_of_art_chicago',
    label: 'The Illinois Institute of Art-Chicago',
  },
  {
    key: 'the_johns_hopkins_university',
    value: 'the_johns_hopkins_university',
    label: 'The Johns Hopkins University',
  },
  { key: 'the_juilliard_school', value: 'the_juilliard_school', label: 'The Juilliard School' },
  {
    key: 'the_leadership_institute_of_seattle',
    value: 'the_leadership_institute_of_seattle',
    label: 'The Leadership Institute of Seattle',
  },
  {
    key: 'the_maryland_institute_college_of_art',
    value: 'the_maryland_institute_college_of_art',
    label: 'The Maryland Institute, College of Art',
  },
  { key: 'the_master_s_college', value: 'the_master_s_college', label: "The Master's College" },
  {
    key: 'the_mc_gregor_school_of_antioch_university',
    value: 'the_mc_gregor_school_of_antioch_university',
    label: 'The McGregor School of Antioch University',
  },
  { key: 'the_naropa_institute', value: 'the_naropa_institute', label: 'The Naropa Institute' },
  { key: 'the_new_school', value: 'the_new_school', label: 'The New School' },
  {
    key: 'the_rockefeller_university',
    value: 'the_rockefeller_university',
    label: 'The Rockefeller University',
  },
  {
    key: 'the_school_of_the_art_institute_of_chicago',
    value: 'the_school_of_the_art_institute_of_chicago',
    label: 'The School of the Art Institute of Chicago',
  },
  {
    key: 'the_scripps_research_institute',
    value: 'the_scripps_research_institute',
    label: 'The Scripps Research Institute',
  },
  {
    key: 'the_southern_christian_university',
    value: 'the_southern_christian_university',
    label: 'The Southern Christian University',
  },
  {
    key: 'the_tulane_university_of_new_orleans',
    value: 'the_tulane_university_of_new_orleans',
    label: 'The Tulane University of New Orleans',
  },
  { key: 'the_union_institute', value: 'the_union_institute', label: 'The Union Institute' },
  { key: 'thiel_college', value: 'thiel_college', label: 'Thiel College' },
  {
    key: 'thomas_a_edison_state_college',
    value: 'thomas_a_edison_state_college',
    label: 'Thomas A. Edison State College',
  },
  {
    key: 'thomas_aquinas_college',
    value: 'thomas_aquinas_college',
    label: 'Thomas Aquinas College',
  },
  { key: 'thomas_college_maine', value: 'thomas_college_maine', label: 'Thomas College Maine' },
  {
    key: 'thomas_jefferson_university',
    value: 'thomas_jefferson_university',
    label: 'Thomas Jefferson University',
  },
  { key: 'thomas_more_college', value: 'thomas_more_college', label: 'Thomas More College' },
  {
    key: 'thomas_more_college_of_liberal_arts',
    value: 'thomas_more_college_of_liberal_arts',
    label: 'Thomas More College of Liberal Arts',
  },
  { key: 'thomas_university', value: 'thomas_university', label: 'Thomas University' },
  {
    key: 'thunderbird_school_of_global_management',
    value: 'thunderbird_school_of_global_management',
    label: 'Thunderbird School of Global Management',
  },
  { key: 'tiffin_university', value: 'tiffin_university', label: 'Tiffin University' },
  { key: 'toccoa_falls_college', value: 'toccoa_falls_college', label: 'Toccoa Falls College' },
  { key: 'tomball_college', value: 'tomball_college', label: 'Tomball College' },
  { key: 'tougaloo_college', value: 'tougaloo_college', label: 'Tougaloo College' },
  { key: 'touro_college', value: 'touro_college', label: 'Touro College' },
  { key: 'touro_university', value: 'touro_university', label: 'Touro University' },
  { key: 'towson_university', value: 'towson_university', label: 'Towson University' },
  {
    key: 'transylvania_university',
    value: 'transylvania_university',
    label: 'Transylvania University',
  },
  {
    key: 'trevecca_nazarene_university',
    value: 'trevecca_nazarene_university',
    label: 'Trevecca Nazarene University',
  },
  {
    key: 'tri_college_university',
    value: 'tri_college_university',
    label: 'Tri-College University',
  },
  { key: 'trident_university', value: 'trident_university', label: 'Trident University' },
  { key: 'trinity_bible_college', value: 'trinity_bible_college', label: 'Trinity Bible College' },
  {
    key: 'trinity_christian_college',
    value: 'trinity_christian_college',
    label: 'Trinity Christian College',
  },
  {
    key: 'trinity_college_connecticut',
    value: 'trinity_college_connecticut',
    label: 'Trinity College Connecticut',
  },
  {
    key: 'trinity_college_of_florida',
    value: 'trinity_college_of_florida',
    label: 'Trinity College of Florida',
  },
  {
    key: 'trinity_college_of_vermont',
    value: 'trinity_college_of_vermont',
    label: 'Trinity College of Vermont',
  },
  {
    key: 'trinity_international_university',
    value: 'trinity_international_university',
    label: 'Trinity International University',
  },
  {
    key: 'trinity_international_university_excel_miami',
    value: 'trinity_international_university_excel_miami',
    label: 'Trinity International University (Excel), Miami',
  },
  { key: 'trinity_university', value: 'trinity_university', label: 'Trinity University' },
  { key: 'trinity_university', value: 'trinity_university', label: 'Trinity University' },
  { key: 'tri_state_university', value: 'tri_state_university', label: 'Tri-State University' },
  { key: 'triton_college', value: 'triton_college', label: 'Triton College' },
  { key: 'troy_university', value: 'troy_university', label: 'Troy University' },
  {
    key: 'troy_university_dothan',
    value: 'troy_university_dothan',
    label: 'Troy University, Dothan',
  },
  {
    key: 'troy_university_montgomery',
    value: 'troy_university_montgomery',
    label: 'Troy University, Montgomery',
  },
  {
    key: 'troy_university_phenix_city',
    value: 'troy_university_phenix_city',
    label: 'Troy University, Phenix City',
  },
  { key: 'troy_university_troy', value: 'troy_university_troy', label: 'Troy University, Troy' },
  { key: 'truman_college', value: 'truman_college', label: 'Truman College' },
  {
    key: 'truman_state_university',
    value: 'truman_state_university',
    label: 'Truman State University',
  },
  { key: 'tufts_university', value: 'tufts_university', label: 'Tufts University' },
  { key: 'tui_online_university', value: 'tui_online_university', label: 'Tui Online University' },
  { key: 'tusculum_college', value: 'tusculum_college', label: 'Tusculum College' },
  { key: 'tuskegee_university', value: 'tuskegee_university', label: 'Tuskegee University' },
  {
    key: 'uniformed_services_universty_of_the_health_sciences',
    value: 'uniformed_services_universty_of_the_health_sciences',
    label: 'Uniformed Services Universty of the Health Sciences',
  },
  { key: 'union_college', value: 'union_college', label: 'Union College' },
  {
    key: 'union_college_kentucky',
    value: 'union_college_kentucky',
    label: 'Union College Kentucky',
  },
  {
    key: 'union_college_nebraska',
    value: 'union_college_nebraska',
    label: 'Union College Nebraska',
  },
  {
    key: 'union_theological_seminary_uts',
    value: 'union_theological_seminary_uts',
    label: 'Union Theological Seminary (UTS)',
  },
  { key: 'union_university', value: 'union_university', label: 'Union University' },
  {
    key: 'united_states_air_force_academy',
    value: 'united_states_air_force_academy',
    label: 'United States Air Force Academy',
  },
  {
    key: 'united_states_coast_guard_academy',
    value: 'united_states_coast_guard_academy',
    label: 'United States Coast Guard Academy',
  },
  {
    key: 'united_states_international_university',
    value: 'united_states_international_university',
    label: 'United States International University',
  },
  {
    key: 'united_states_merchant_marine_academy',
    value: 'united_states_merchant_marine_academy',
    label: 'United States Merchant Marine Academy',
  },
  {
    key: 'united_states_military_academy',
    value: 'united_states_military_academy',
    label: 'United States Military Academy',
  },
  {
    key: 'united_states_naval_academy',
    value: 'united_states_naval_academy',
    label: 'United States Naval Academy',
  },
  {
    key: 'united_states_sports_academy',
    value: 'united_states_sports_academy',
    label: 'United States Sports Academy',
  },
  { key: 'unity_college', value: 'unity_college', label: 'Unity College' },
  {
    key: 'university_of_advancing_technology_uat',
    value: 'university_of_advancing_technology_uat',
    label: 'University of Advancing Technology (UAT)',
  },
  { key: 'university_of_akron', value: 'university_of_akron', label: 'University of Akron' },
  {
    key: 'university_of_alabama_birmingham',
    value: 'university_of_alabama_birmingham',
    label: 'University of Alabama - Birmingham',
  },
  {
    key: 'university_of_alabama_huntsville',
    value: 'university_of_alabama_huntsville',
    label: 'University of Alabama - Huntsville',
  },
  {
    key: 'university_of_alabama_tuscaloosa',
    value: 'university_of_alabama_tuscaloosa',
    label: 'University of Alabama - Tuscaloosa',
  },
  { key: 'university_of_alanta', value: 'university_of_alanta', label: 'University of Alanta' },
  {
    key: 'university_of_alaska_anchorage',
    value: 'university_of_alaska_anchorage',
    label: 'University of Alaska - Anchorage',
  },
  {
    key: 'university_of_alaska_fairbanks',
    value: 'university_of_alaska_fairbanks',
    label: 'University of Alaska - Fairbanks',
  },
  {
    key: 'university_of_alaska_southeast',
    value: 'university_of_alaska_southeast',
    label: 'University of Alaska - Southeast',
  },
  {
    key: 'university_of_alaska_system',
    value: 'university_of_alaska_system',
    label: 'University of Alaska (System)',
  },
  { key: 'university_of_arizona', value: 'university_of_arizona', label: 'University of Arizona' },
  {
    key: 'university_of_arkansas_at_fayetteville',
    value: 'university_of_arkansas_at_fayetteville',
    label: 'University of Arkansas at Fayetteville',
  },
  {
    key: 'university_of_arkansas_at_little_rock',
    value: 'university_of_arkansas_at_little_rock',
    label: 'University of Arkansas at Little Rock',
  },
  {
    key: 'university_of_arkansas_at_monticello',
    value: 'university_of_arkansas_at_monticello',
    label: 'University of Arkansas at Monticello',
  },
  {
    key: 'university_of_arkansas_at_pine_bluff',
    value: 'university_of_arkansas_at_pine_bluff',
    label: 'University of Arkansas at Pine Bluff',
  },
  {
    key: 'university_of_arkansas_for_medical_sciences',
    value: 'university_of_arkansas_for_medical_sciences',
    label: 'University of Arkansas for Medical Sciences',
  },
  {
    key: 'university_of_arkansas_system',
    value: 'university_of_arkansas_system',
    label: 'University of Arkansas (System)',
  },
  {
    key: 'university_of_baltimore',
    value: 'university_of_baltimore',
    label: 'University of Baltimore',
  },
  {
    key: 'university_of_bridgeport',
    value: 'university_of_bridgeport',
    label: 'University of Bridgeport',
  },
  {
    key: 'university_of_california_berkeley',
    value: 'university_of_california_berkeley',
    label: 'University of California, Berkeley',
  },
  {
    key: 'university_of_california_davis',
    value: 'university_of_california_davis',
    label: 'University of California, Davis',
  },
  {
    key: 'university_of_california_hastings_college_of_law',
    value: 'university_of_california_hastings_college_of_law',
    label: 'University of California, Hastings College of Law',
  },
  {
    key: 'university_of_california_irvine',
    value: 'university_of_california_irvine',
    label: 'University of California, Irvine',
  },
  {
    key: 'university_of_california_los_angeles',
    value: 'university_of_california_los_angeles',
    label: 'University of California, Los Angeles',
  },
  {
    key: 'university_of_california_merced',
    value: 'university_of_california_merced',
    label: 'University of California, Merced',
  },
  {
    key: 'university_of_california_oakland',
    value: 'university_of_california_oakland',
    label: 'University of California, Oakland',
  },
  {
    key: 'university_of_california_riverside',
    value: 'university_of_california_riverside',
    label: 'University of California, Riverside',
  },
  {
    key: 'university_of_california_san_diego',
    value: 'university_of_california_san_diego',
    label: 'University of California, San Diego',
  },
  {
    key: 'university_of_california_san_francisco',
    value: 'university_of_california_san_francisco',
    label: 'University of California, San Francisco',
  },
  {
    key: 'university_of_california_santa_barbara',
    value: 'university_of_california_santa_barbara',
    label: 'University of California, Santa Barbara',
  },
  {
    key: 'university_of_california_santa_cruz',
    value: 'university_of_california_santa_cruz',
    label: 'University of California, Santa Cruz',
  },
  {
    key: 'university_of_california_system',
    value: 'university_of_california_system',
    label: 'University of California System',
  },
  {
    key: 'university_of_central_arkansas',
    value: 'university_of_central_arkansas',
    label: 'University of Central Arkansas',
  },
  {
    key: 'university_of_central_florida',
    value: 'university_of_central_florida',
    label: 'University of Central Florida',
  },
  {
    key: 'university_of_central_missouri',
    value: 'university_of_central_missouri',
    label: 'University of Central Missouri',
  },
  {
    key: 'university_of_central_oklahoma',
    value: 'university_of_central_oklahoma',
    label: 'University of Central Oklahoma',
  },
  {
    key: 'university_of_central_texas',
    value: 'university_of_central_texas',
    label: 'University of Central Texas',
  },
  {
    key: 'university_of_charleston',
    value: 'university_of_charleston',
    label: 'University of Charleston',
  },
  {
    key: 'university_of_charleston_south_carolina',
    value: 'university_of_charleston_south_carolina',
    label: 'University of Charleston South Carolina',
  },
  { key: 'university_of_chicago', value: 'university_of_chicago', label: 'University of Chicago' },
  {
    key: 'university_of_cincinnati',
    value: 'university_of_cincinnati',
    label: 'University of Cincinnati',
  },
  {
    key: 'university_of_colorado_at_boulder',
    value: 'university_of_colorado_at_boulder',
    label: 'University of Colorado at Boulder',
  },
  {
    key: 'university_of_colorado_at_colorado_springs',
    value: 'university_of_colorado_at_colorado_springs',
    label: 'University of Colorado at Colorado Springs',
  },
  {
    key: 'university_of_colorado_at_denver',
    value: 'university_of_colorado_at_denver',
    label: 'University of Colorado at Denver',
  },
  {
    key: 'university_of_colorado_health_sciences_center',
    value: 'university_of_colorado_health_sciences_center',
    label: 'University of Colorado Health Sciences Center',
  },
  {
    key: 'university_of_connecticut',
    value: 'university_of_connecticut',
    label: 'University of Connecticut',
  },
  {
    key: 'university_of_connecticut_at_avery_point',
    value: 'university_of_connecticut_at_avery_point',
    label: 'University of Connecticut at Avery Point',
  },
  {
    key: 'university_of_connecticut_at_hartford',
    value: 'university_of_connecticut_at_hartford',
    label: 'University of Connecticut at Hartford',
  },
  {
    key: 'university_of_connecticut_at_stamford',
    value: 'university_of_connecticut_at_stamford',
    label: 'University of Connecticut at Stamford',
  },
  {
    key: 'university_of_connecticut_at_waterbury',
    value: 'university_of_connecticut_at_waterbury',
    label: 'University of Connecticut at Waterbury',
  },
  {
    key: 'university_of_connecticut_health_center',
    value: 'university_of_connecticut_health_center',
    label: 'University of Connecticut Health Center',
  },
  { key: 'university_of_dallas', value: 'university_of_dallas', label: 'University of Dallas' },
  { key: 'university_of_dayton', value: 'university_of_dayton', label: 'University of Dayton' },
  {
    key: 'university_of_delaware',
    value: 'university_of_delaware',
    label: 'University of Delaware',
  },
  { key: 'university_of_denver', value: 'university_of_denver', label: 'University of Denver' },
  {
    key: 'university_of_detroit_mercy',
    value: 'university_of_detroit_mercy',
    label: 'University of Detroit Mercy',
  },
  { key: 'university_of_dubuque', value: 'university_of_dubuque', label: 'University of Dubuque' },
  {
    key: 'university_of_evansville',
    value: 'university_of_evansville',
    label: 'University of Evansville',
  },
  { key: 'university_of_findlay', value: 'university_of_findlay', label: 'University of Findlay' },
  { key: 'university_of_florida', value: 'university_of_florida', label: 'University of Florida' },
  { key: 'university_of_georgia', value: 'university_of_georgia', label: 'University of Georgia' },
  {
    key: 'university_of_great_falls',
    value: 'university_of_great_falls',
    label: 'University of Great Falls',
  },
  {
    key: 'university_of_hartford',
    value: 'university_of_hartford',
    label: 'University of Hartford',
  },
  {
    key: 'university_of_hawaii_hilo',
    value: 'university_of_hawaii_hilo',
    label: 'University of Hawaii - Hilo',
  },
  {
    key: 'university_of_hawaii_manoa',
    value: 'university_of_hawaii_manoa',
    label: 'University of Hawaii - Manoa',
  },
  {
    key: 'university_of_hawaii_system',
    value: 'university_of_hawaii_system',
    label: 'University Of Hawaii - System',
  },
  {
    key: 'university_of_hawaii_west_oahu',
    value: 'university_of_hawaii_west_oahu',
    label: 'University of Hawaii - West Oahu',
  },
  {
    key: 'university_of_health_sciences',
    value: 'university_of_health_sciences',
    label: 'University of Health Sciences',
  },
  { key: 'university_of_houston', value: 'university_of_houston', label: 'University of Houston' },
  {
    key: 'university_of_houston_clear_lake',
    value: 'university_of_houston_clear_lake',
    label: 'University of Houston, Clear Lake',
  },
  {
    key: 'university_of_houston_downtown',
    value: 'university_of_houston_downtown',
    label: 'University of Houston, Downtown',
  },
  {
    key: 'university_of_houston_victoria',
    value: 'university_of_houston_victoria',
    label: 'University of Houston, Victoria',
  },
  { key: 'university_of_idaho', value: 'university_of_idaho', label: 'University of Idaho' },
  {
    key: 'university_of_illinois',
    value: 'university_of_illinois',
    label: 'University of Illinois',
  },
  {
    key: 'university_of_illinois_at_chicago',
    value: 'university_of_illinois_at_chicago',
    label: 'University of Illinois at Chicago',
  },
  {
    key: 'university_of_illinois_at_springfield',
    value: 'university_of_illinois_at_springfield',
    label: 'University of Illinois at Springfield',
  },
  {
    key: 'university_of_illinois_at_urbana_champaign',
    value: 'university_of_illinois_at_urbana_champaign',
    label: 'University of Illinois at Urbana-Champaign',
  },
  {
    key: 'university_of_indianapolis',
    value: 'university_of_indianapolis',
    label: 'University of Indianapolis',
  },
  { key: 'university_of_iowa', value: 'university_of_iowa', label: 'University of Iowa' },
  { key: 'university_of_kansas', value: 'university_of_kansas', label: 'University of Kansas' },
  {
    key: 'university_of_kentucky',
    value: 'university_of_kentucky',
    label: 'University of Kentucky',
  },
  {
    key: 'university_of_la_verne',
    value: 'university_of_la_verne',
    label: 'University of La Verne',
  },
  {
    key: 'university_of_louisiana_at_lafayette',
    value: 'university_of_louisiana_at_lafayette',
    label: 'University of Louisiana at Lafayette',
  },
  {
    key: 'university_of_louisiana_at_monroe',
    value: 'university_of_louisiana_at_monroe',
    label: 'University of Louisiana at Monroe',
  },
  {
    key: 'university_of_louisville',
    value: 'university_of_louisville',
    label: 'University of Louisville',
  },
  {
    key: 'university_of_maine_augusta',
    value: 'university_of_maine_augusta',
    label: 'University of Maine, Augusta',
  },
  {
    key: 'university_of_maine_farmington',
    value: 'university_of_maine_farmington',
    label: 'University of Maine, Farmington',
  },
  {
    key: 'university_of_maine_fort_kent',
    value: 'university_of_maine_fort_kent',
    label: 'University of Maine, Fort Kent',
  },
  {
    key: 'university_of_maine_machias',
    value: 'university_of_maine_machias',
    label: 'University of Maine, Machias',
  },
  {
    key: 'university_of_maine_orono',
    value: 'university_of_maine_orono',
    label: 'University of Maine, Orono',
  },
  {
    key: 'university_of_maine_presque_isle',
    value: 'university_of_maine_presque_isle',
    label: 'University of Maine, Presque Isle',
  },
  {
    key: 'university_of_maine_system',
    value: 'university_of_maine_system',
    label: 'University of Maine (System)',
  },
  {
    key: 'university_of_management_technology',
    value: 'university_of_management_technology',
    label: 'University of Management & Technology',
  },
  { key: 'university_of_mary', value: 'university_of_mary', label: 'University of Mary' },
  {
    key: 'university_of_mary_hardin_baylor',
    value: 'university_of_mary_hardin_baylor',
    label: 'University of Mary Hardin-Baylor',
  },
  {
    key: 'university_of_maryland_at_baltimore',
    value: 'university_of_maryland_at_baltimore',
    label: 'University of Maryland at Baltimore',
  },
  {
    key: 'university_of_maryland_at_college_park',
    value: 'university_of_maryland_at_college_park',
    label: 'University of Maryland at College Park',
  },
  {
    key: 'university_of_maryland_baltimore_county',
    value: 'university_of_maryland_baltimore_county',
    label: 'University of Maryland Baltimore County',
  },
  {
    key: 'university_of_maryland_eastern_shore',
    value: 'university_of_maryland_eastern_shore',
    label: 'University of Maryland Eastern Shore',
  },
  {
    key: 'university_of_maryland_medicine',
    value: 'university_of_maryland_medicine',
    label: 'University of Maryland Medicine',
  },
  {
    key: 'university_of_maryland_system',
    value: 'university_of_maryland_system',
    label: 'University of Maryland (System)',
  },
  {
    key: 'university_of_maryland_university_college',
    value: 'university_of_maryland_university_college',
    label: 'University of Maryland University College',
  },
  {
    key: 'university_of_massachusetts_at_amherst',
    value: 'university_of_massachusetts_at_amherst',
    label: 'University of Massachusetts at Amherst',
  },
  {
    key: 'university_of_massachusetts_at_boston',
    value: 'university_of_massachusetts_at_boston',
    label: 'University of Massachusetts at Boston',
  },
  {
    key: 'university_of_massachusetts_at_dartmouth',
    value: 'university_of_massachusetts_at_dartmouth',
    label: 'University of Massachusetts at Dartmouth',
  },
  {
    key: 'university_of_massachusetts_at_lowell',
    value: 'university_of_massachusetts_at_lowell',
    label: 'University of Massachusetts at Lowell',
  },
  {
    key: 'university_of_massachusetts_medical_center_at_worcester',
    value: 'university_of_massachusetts_medical_center_at_worcester',
    label: 'University of Massachusetts Medical Center at Worcester',
  },
  {
    key: 'university_of_massachusetts_system',
    value: 'university_of_massachusetts_system',
    label: 'University of Massachusetts (System)',
  },
  {
    key: 'university_of_medicine_and_dentistry_of_new_jersey',
    value: 'university_of_medicine_and_dentistry_of_new_jersey',
    label: 'University of Medicine and Dentistry of New Jersey',
  },
  { key: 'university_of_memphis', value: 'university_of_memphis', label: 'University of Memphis' },
  { key: 'university_of_miami', value: 'university_of_miami', label: 'University of Miami' },
  {
    key: 'university_of_michigan_ann_arbor',
    value: 'university_of_michigan_ann_arbor',
    label: 'University of Michigan - Ann Arbor',
  },
  {
    key: 'university_of_michigan_dearborn',
    value: 'university_of_michigan_dearborn',
    label: 'University of Michigan - Dearborn',
  },
  {
    key: 'university_of_michigan_flint',
    value: 'university_of_michigan_flint',
    label: 'University of Michigan - Flint',
  },
  {
    key: 'university_of_minnesota_crookston',
    value: 'university_of_minnesota_crookston',
    label: 'University of Minnesota - Crookston',
  },
  {
    key: 'university_of_minnesota_duluth',
    value: 'university_of_minnesota_duluth',
    label: 'University of Minnesota - Duluth',
  },
  {
    key: 'university_of_minnesota_morris',
    value: 'university_of_minnesota_morris',
    label: 'University of Minnesota - Morris',
  },
  {
    key: 'university_of_minnesota_twin_cities_campus',
    value: 'university_of_minnesota_twin_cities_campus',
    label: 'University of Minnesota - Twin Cities Campus',
  },
  {
    key: 'university_of_mississippi',
    value: 'university_of_mississippi',
    label: 'University of Mississippi',
  },
  {
    key: 'university_of_mississippi_medical_center',
    value: 'university_of_mississippi_medical_center',
    label: 'University of Mississippi Medical Center',
  },
  {
    key: 'university_of_missouri_columbia',
    value: 'university_of_missouri_columbia',
    label: 'University of Missouri - Columbia',
  },
  {
    key: 'university_of_missouri_kansas_city',
    value: 'university_of_missouri_kansas_city',
    label: 'University of Missouri - Kansas City',
  },
  {
    key: 'university_of_missouri_saint_louis',
    value: 'university_of_missouri_saint_louis',
    label: 'University of Missouri - Saint Louis',
  },
  { key: 'university_of_mobile', value: 'university_of_mobile', label: 'University of Mobile' },
  { key: 'university_of_montana', value: 'university_of_montana', label: 'University of Montana' },
  {
    key: 'university_of_montana_western',
    value: 'university_of_montana_western',
    label: 'University of Montana Western',
  },
  {
    key: 'university_of_montevallo',
    value: 'university_of_montevallo',
    label: 'University of Montevallo',
  },
  {
    key: 'university_of_nebraska_kearney',
    value: 'university_of_nebraska_kearney',
    label: 'University of Nebraska - Kearney',
  },
  {
    key: 'university_of_nebraska_lincoln',
    value: 'university_of_nebraska_lincoln',
    label: 'University of Nebraska - Lincoln',
  },
  {
    key: 'university_of_nebraska_medical_center',
    value: 'university_of_nebraska_medical_center',
    label: 'University of Nebraska Medical Center',
  },
  {
    key: 'university_of_nebraska_omaha',
    value: 'university_of_nebraska_omaha',
    label: 'University of Nebraska - Omaha',
  },
  {
    key: 'university_of_nebraska_system',
    value: 'university_of_nebraska_system',
    label: 'University of Nebraska (System)',
  },
  {
    key: 'university_of_nevada_las_vegas',
    value: 'university_of_nevada_las_vegas',
    label: 'University of Nevada - Las Vegas',
  },
  {
    key: 'university_of_nevada_reno',
    value: 'university_of_nevada_reno',
    label: 'University of Nevada - Reno',
  },
  {
    key: 'university_of_new_england',
    value: 'university_of_new_england',
    label: 'University of New England',
  },
  {
    key: 'university_of_new_england_westbrook_college_campus',
    value: 'university_of_new_england_westbrook_college_campus',
    label: 'University of New England, Westbrook College Campus',
  },
  {
    key: 'university_of_new_hampshire',
    value: 'university_of_new_hampshire',
    label: 'University of New Hampshire',
  },
  {
    key: 'university_of_new_hampshire_manchester',
    value: 'university_of_new_hampshire_manchester',
    label: 'University of New Hampshire - Manchester',
  },
  {
    key: 'university_of_new_haven',
    value: 'university_of_new_haven',
    label: 'University of New Haven',
  },
  {
    key: 'university_of_new_mexico',
    value: 'university_of_new_mexico',
    label: 'University of New Mexico',
  },
  {
    key: 'university_of_new_orleans',
    value: 'university_of_new_orleans',
    label: 'University of New Orleans',
  },
  {
    key: 'university_of_north_alabama',
    value: 'university_of_north_alabama',
    label: 'University of North Alabama',
  },
  {
    key: 'university_of_north_america',
    value: 'university_of_north_america',
    label: 'University of North America',
  },
  {
    key: 'university_of_north_carolina_at_asheville',
    value: 'university_of_north_carolina_at_asheville',
    label: 'University of North Carolina at Asheville',
  },
  {
    key: 'university_of_north_carolina_at_chapel_hill',
    value: 'university_of_north_carolina_at_chapel_hill',
    label: 'University of North Carolina at Chapel Hill',
  },
  {
    key: 'university_of_north_carolina_at_charlotte',
    value: 'university_of_north_carolina_at_charlotte',
    label: 'University of North Carolina at Charlotte',
  },
  {
    key: 'university_of_north_carolina_at_greensboro',
    value: 'university_of_north_carolina_at_greensboro',
    label: 'University of North Carolina at Greensboro',
  },
  {
    key: 'university_of_north_carolina_at_pembroke',
    value: 'university_of_north_carolina_at_pembroke',
    label: 'University of North Carolina at Pembroke',
  },
  {
    key: 'university_of_north_carolina_at_wilmington',
    value: 'university_of_north_carolina_at_wilmington',
    label: 'University of North Carolina at Wilmington',
  },
  {
    key: 'university_of_north_dakota',
    value: 'university_of_north_dakota',
    label: 'University of North Dakota',
  },
  {
    key: 'university_of_northern_colorado',
    value: 'university_of_northern_colorado',
    label: 'University of Northern Colorado',
  },
  {
    key: 'university_of_northern_iowa',
    value: 'university_of_northern_iowa',
    label: 'University of Northern Iowa',
  },
  {
    key: 'university_of_northern_virginia',
    value: 'university_of_northern_virginia',
    label: 'University of Northern Virginia',
  },
  {
    key: 'university_of_northern_washington',
    value: 'university_of_northern_washington',
    label: 'University of Northern Washington',
  },
  {
    key: 'university_of_north_florida',
    value: 'university_of_north_florida',
    label: 'University of North Florida',
  },
  {
    key: 'university_of_north_texas',
    value: 'university_of_north_texas',
    label: 'University of North Texas',
  },
  {
    key: 'university_of_north_texas_health_science_center_at_fort_worth',
    value: 'university_of_north_texas_health_science_center_at_fort_worth',
    label: 'University of North Texas Health Science Center at Fort Worth',
  },
  {
    key: 'university_of_north_west',
    value: 'university_of_north_west',
    label: 'University of NorthWest',
  },
  {
    key: 'university_of_notre_dame',
    value: 'university_of_notre_dame',
    label: 'University of Notre Dame',
  },
  {
    key: 'university_of_oklahoma',
    value: 'university_of_oklahoma',
    label: 'University of Oklahoma',
  },
  {
    key: 'university_of_oklahoma_health_sciences_center',
    value: 'university_of_oklahoma_health_sciences_center',
    label: 'University of Oklahoma Health Sciences Center',
  },
  { key: 'university_of_oregon', value: 'university_of_oregon', label: 'University of Oregon' },
  {
    key: 'university_of_osteopathic_medicine_and_health_science',
    value: 'university_of_osteopathic_medicine_and_health_science',
    label: 'University of Osteopathic Medicine and Health Science',
  },
  {
    key: 'university_of_pennsylvania',
    value: 'university_of_pennsylvania',
    label: 'University of Pennsylvania',
  },
  { key: 'university_of_phoenix', value: 'university_of_phoenix', label: 'University of Phoenix' },
  {
    key: 'university_of_pittsburgh',
    value: 'university_of_pittsburgh',
    label: 'University of Pittsburgh',
  },
  {
    key: 'university_of_pittsburgh_at_bradford',
    value: 'university_of_pittsburgh_at_bradford',
    label: 'University of Pittsburgh at Bradford',
  },
  {
    key: 'university_of_pittsburgh_at_greensburg',
    value: 'university_of_pittsburgh_at_greensburg',
    label: 'University of Pittsburgh at Greensburg',
  },
  {
    key: 'university_of_pittsburgh_at_johnstown',
    value: 'university_of_pittsburgh_at_johnstown',
    label: 'University of Pittsburgh at Johnstown',
  },
  {
    key: 'university_of_portland',
    value: 'university_of_portland',
    label: 'University of Portland',
  },
  {
    key: 'university_of_puget_sound',
    value: 'university_of_puget_sound',
    label: 'University of Puget Sound',
  },
  {
    key: 'university_of_redlands',
    value: 'university_of_redlands',
    label: 'University of Redlands',
  },
  {
    key: 'university_of_rhode_island',
    value: 'university_of_rhode_island',
    label: 'University of Rhode Island',
  },
  {
    key: 'university_of_richmond',
    value: 'university_of_richmond',
    label: 'University of Richmond',
  },
  {
    key: 'university_of_rio_grande',
    value: 'university_of_rio_grande',
    label: 'University of Rio Grande',
  },
  {
    key: 'university_of_rochester',
    value: 'university_of_rochester',
    label: 'University of Rochester',
  },
  {
    key: 'university_of_san_diego',
    value: 'university_of_san_diego',
    label: 'University of San Diego',
  },
  {
    key: 'university_of_san_francisco',
    value: 'university_of_san_francisco',
    label: 'University of San Francisco',
  },
  {
    key: 'university_of_science_and_arts_of_oklahoma',
    value: 'university_of_science_and_arts_of_oklahoma',
    label: 'University of Science and Arts of Oklahoma',
  },
  {
    key: 'university_of_scranton',
    value: 'university_of_scranton',
    label: 'University of Scranton',
  },
  {
    key: 'university_of_sioux_falls',
    value: 'university_of_sioux_falls',
    label: 'University of Sioux Falls',
  },
  {
    key: 'university_of_south_alabama',
    value: 'university_of_south_alabama',
    label: 'University of South Alabama',
  },
  {
    key: 'university_of_south_carolina',
    value: 'university_of_south_carolina',
    label: 'University of South Carolina',
  },
  {
    key: 'university_of_south_carolina_aiken',
    value: 'university_of_south_carolina_aiken',
    label: 'University of South Carolina - Aiken',
  },
  {
    key: 'university_of_south_carolina_beaufort',
    value: 'university_of_south_carolina_beaufort',
    label: 'University of South Carolina - Beaufort',
  },
  {
    key: 'university_of_south_carolina_lancaster',
    value: 'university_of_south_carolina_lancaster',
    label: 'University of South Carolina - Lancaster',
  },
  {
    key: 'university_of_south_carolina_salkehatchie',
    value: 'university_of_south_carolina_salkehatchie',
    label: 'University of South Carolina - Salkehatchie',
  },
  {
    key: 'university_of_south_carolina_spartanburg',
    value: 'university_of_south_carolina_spartanburg',
    label: 'University of South Carolina - Spartanburg',
  },
  {
    key: 'university_of_south_carolina_sumter',
    value: 'university_of_south_carolina_sumter',
    label: 'University of South Carolina - Sumter',
  },
  {
    key: 'university_of_south_carolina_union',
    value: 'university_of_south_carolina_union',
    label: 'University of South Carolina - Union',
  },
  {
    key: 'university_of_south_dakota',
    value: 'university_of_south_dakota',
    label: 'University of South Dakota',
  },
  {
    key: 'university_of_southern_california',
    value: 'university_of_southern_california',
    label: 'University of Southern California',
  },
  {
    key: 'university_of_southern_indiana',
    value: 'university_of_southern_indiana',
    label: 'University of Southern Indiana',
  },
  {
    key: 'university_of_southern_maine',
    value: 'university_of_southern_maine',
    label: 'University of Southern Maine',
  },
  {
    key: 'university_of_southern_mississippi',
    value: 'university_of_southern_mississippi',
    label: 'University of Southern Mississippi',
  },
  {
    key: 'university_of_south_florida',
    value: 'university_of_south_florida',
    label: 'University of South Florida',
  },
  {
    key: 'university_of_st_francis',
    value: 'university_of_st_francis',
    label: 'University of St. Francis',
  },
  {
    key: 'university_of_st_thomas_houston',
    value: 'university_of_st_thomas_houston',
    label: 'University of St. Thomas, Houston',
  },
  {
    key: 'university_of_st_thomas_st_paul',
    value: 'university_of_st_thomas_st_paul',
    label: 'University of St. Thomas, St. Paul',
  },
  { key: 'university_of_tampa', value: 'university_of_tampa', label: 'University of Tampa' },
  {
    key: 'university_of_tennessee_chattanooga',
    value: 'university_of_tennessee_chattanooga',
    label: 'University of Tennessee - Chattanooga',
  },
  {
    key: 'university_of_tennessee_knoxville',
    value: 'university_of_tennessee_knoxville',
    label: 'University of Tennessee - Knoxville',
  },
  {
    key: 'university_of_tennessee_martin',
    value: 'university_of_tennessee_martin',
    label: 'University of Tennessee - Martin',
  },
  {
    key: 'university_of_tennessee_memphis',
    value: 'university_of_tennessee_memphis',
    label: 'University of Tennessee - Memphis',
  },
  {
    key: 'university_of_tennessee_space_institute',
    value: 'university_of_tennessee_space_institute',
    label: 'University of Tennessee Space Institute',
  },
  { key: 'university_of_texas', value: 'university_of_texas', label: 'University of Texas' },
  {
    key: 'university_of_texas_at_arlington',
    value: 'university_of_texas_at_arlington',
    label: 'University of Texas at Arlington',
  },
  {
    key: 'university_of_texas_at_austin',
    value: 'university_of_texas_at_austin',
    label: 'University of Texas at Austin',
  },
  {
    key: 'university_of_texas_at_brownsville',
    value: 'university_of_texas_at_brownsville',
    label: 'University of Texas at Brownsville',
  },
  {
    key: 'university_of_texas_at_dallas',
    value: 'university_of_texas_at_dallas',
    label: 'University of Texas at Dallas',
  },
  {
    key: 'university_of_texas_at_el_paso',
    value: 'university_of_texas_at_el_paso',
    label: 'University of Texas at El Paso',
  },
  {
    key: 'university_of_texas_at_san_antonio',
    value: 'university_of_texas_at_san_antonio',
    label: 'University of Texas at San Antonio',
  },
  {
    key: 'university_of_texas_at_tyler',
    value: 'university_of_texas_at_tyler',
    label: 'University of Texas at Tyler',
  },
  {
    key: 'university_of_texas_health_center_at_houston',
    value: 'university_of_texas_health_center_at_houston',
    label: 'University of Texas Health Center at Houston',
  },
  {
    key: 'university_of_texas_health_center_at_tyler',
    value: 'university_of_texas_health_center_at_tyler',
    label: 'University of Texas Health Center at Tyler',
  },
  {
    key: 'university_of_texas_health_science_center_at_san_antonio',
    value: 'university_of_texas_health_science_center_at_san_antonio',
    label: 'University of Texas Health Science Center at San Antonio',
  },
  {
    key: 'university_of_texas_m_d_anderson_cancer_center',
    value: 'university_of_texas_m_d_anderson_cancer_center',
    label: 'University of Texas M.D. Anderson Cancer Center',
  },
  {
    key: 'university_of_texas_medical_branch_galveston',
    value: 'university_of_texas_medical_branch_galveston',
    label: 'University of Texas Medical Branch Galveston',
  },
  {
    key: 'university_of_texas_of_the_permian_basin',
    value: 'university_of_texas_of_the_permian_basin',
    label: 'University of Texas of the Permian Basin',
  },
  {
    key: 'university_of_texas_pan_american',
    value: 'university_of_texas_pan_american',
    label: 'University of Texas Pan American',
  },
  {
    key: 'university_of_texas_southwestern_medical_center_at_dallas',
    value: 'university_of_texas_southwestern_medical_center_at_dallas',
    label: 'University of Texas Southwestern Medical Center at Dallas',
  },
  {
    key: 'university_of_the_arts',
    value: 'university_of_the_arts',
    label: 'University of the Arts',
  },
  {
    key: 'university_of_the_district_of_columbia',
    value: 'university_of_the_district_of_columbia',
    label: 'University of the District of Columbia',
  },
  {
    key: 'university_of_the_incarnate_world',
    value: 'university_of_the_incarnate_world',
    label: 'University of the Incarnate World',
  },
  {
    key: 'university_of_the_ozarks',
    value: 'university_of_the_ozarks',
    label: 'University of the Ozarks',
  },
  {
    key: 'university_of_the_pacific',
    value: 'university_of_the_pacific',
    label: 'University of the Pacific',
  },
  {
    key: 'university_of_the_sciences_in_philadelphia',
    value: 'university_of_the_sciences_in_philadelphia',
    label: 'University of the Sciences in Philadelphia',
  },
  {
    key: 'university_of_the_south',
    value: 'university_of_the_south',
    label: 'University of the South',
  },
  {
    key: 'university_of_the_southwest',
    value: 'university_of_the_southwest',
    label: 'University of the Southwest',
  },
  { key: 'university_of_toledo', value: 'university_of_toledo', label: 'University of Toledo' },
  { key: 'university_of_tulsa', value: 'university_of_tulsa', label: 'University of Tulsa' },
  { key: 'university_of_utah', value: 'university_of_utah', label: 'University of Utah' },
  { key: 'university_of_vermont', value: 'university_of_vermont', label: 'University of Vermont' },
  {
    key: 'university_of_virginia',
    value: 'university_of_virginia',
    label: 'University of Virginia',
  },
  {
    key: 'university_of_virginia_college_at_wise',
    value: 'university_of_virginia_college_at_wise',
    label: 'University of Virginia, College at Wise',
  },
  {
    key: 'university_of_washington',
    value: 'university_of_washington',
    label: 'University of Washington',
  },
  {
    key: 'university_of_washington_tacoma',
    value: 'university_of_washington_tacoma',
    label: 'University of Washington, Tacoma',
  },
  {
    key: 'university_of_west_alabama',
    value: 'university_of_west_alabama',
    label: 'University of West Alabama',
  },
  {
    key: 'university_of_west_florida',
    value: 'university_of_west_florida',
    label: 'University of West Florida',
  },
  {
    key: 'university_of_west_los_angeles',
    value: 'university_of_west_los_angeles',
    label: 'University of West Los Angeles',
  },
  {
    key: 'university_of_wisconsin_eau_claire',
    value: 'university_of_wisconsin_eau_claire',
    label: 'University of Wisconsin - Eau Claire',
  },
  {
    key: 'university_of_wisconsin_green_bay',
    value: 'university_of_wisconsin_green_bay',
    label: 'University of Wisconsin - Green Bay',
  },
  {
    key: 'university_of_wisconsin_la_crosse',
    value: 'university_of_wisconsin_la_crosse',
    label: 'University of Wisconsin - La Crosse',
  },
  {
    key: 'university_of_wisconsin_madison',
    value: 'university_of_wisconsin_madison',
    label: 'University of Wisconsin - Madison',
  },
  {
    key: 'university_of_wisconsin_milwaukee',
    value: 'university_of_wisconsin_milwaukee',
    label: 'University of Wisconsin - Milwaukee',
  },
  {
    key: 'university_of_wisconsin_oshkosh',
    value: 'university_of_wisconsin_oshkosh',
    label: 'University of Wisconsin - Oshkosh',
  },
  {
    key: 'university_of_wisconsin_parkside',
    value: 'university_of_wisconsin_parkside',
    label: 'University of Wisconsin - Parkside',
  },
  {
    key: 'university_of_wisconsin_platteville',
    value: 'university_of_wisconsin_platteville',
    label: 'University of Wisconsin - Platteville',
  },
  {
    key: 'university_of_wisconsin_river_falls',
    value: 'university_of_wisconsin_river_falls',
    label: 'University of Wisconsin - River Falls',
  },
  {
    key: 'university_of_wisconsin_stevens_point',
    value: 'university_of_wisconsin_stevens_point',
    label: 'University of Wisconsin - Stevens Point',
  },
  {
    key: 'university_of_wisconsin_stout',
    value: 'university_of_wisconsin_stout',
    label: 'University of Wisconsin - Stout',
  },
  {
    key: 'university_of_wisconsin_superior',
    value: 'university_of_wisconsin_superior',
    label: 'University of Wisconsin - Superior',
  },
  {
    key: 'university_of_wisconsin_whitewater',
    value: 'university_of_wisconsin_whitewater',
    label: 'University of Wisconsin - Whitewater',
  },
  { key: 'university_of_wyoming', value: 'university_of_wyoming', label: 'University of Wyoming' },
  { key: 'upper_iowa_university', value: 'upper_iowa_university', label: 'Upper Iowa University' },
  { key: 'urbana_university', value: 'urbana_university', label: 'Urbana University' },
  { key: 'ursinus_college', value: 'ursinus_college', label: 'Ursinus College' },
  { key: 'ursuline_college', value: 'ursuline_college', label: 'Ursuline College' },
  { key: 'utah_state_university', value: 'utah_state_university', label: 'Utah State University' },
  {
    key: 'utah_valley_state_college',
    value: 'utah_valley_state_college',
    label: 'Utah Valley State College',
  },
  { key: 'utica_college', value: 'utica_college', label: 'Utica College' },
  {
    key: 'valdosta_state_university',
    value: 'valdosta_state_university',
    label: 'Valdosta State University',
  },
  {
    key: 'valley_city_state_university',
    value: 'valley_city_state_university',
    label: 'Valley City State University',
  },
  {
    key: 'valley_forge_christian_college',
    value: 'valley_forge_christian_college',
    label: 'Valley Forge Christian College',
  },
  { key: 'valparaiso_university', value: 'valparaiso_university', label: 'Valparaiso University' },
  { key: 'vanderbilt_university', value: 'vanderbilt_university', label: 'Vanderbilt University' },
  {
    key: 'vander_cook_college_of_music',
    value: 'vander_cook_college_of_music',
    label: 'VanderCook College of Music',
  },
  {
    key: 'vanguard_university_of_southern_california',
    value: 'vanguard_university_of_southern_california',
    label: 'Vanguard University of Southern California',
  },
  { key: 'vassar_college', value: 'vassar_college', label: 'Vassar College' },
  { key: 'vennard_college', value: 'vennard_college', label: 'Vennard College' },
  { key: 'vermont_law_school', value: 'vermont_law_school', label: 'Vermont Law School' },
  {
    key: 'vermont_technical_college',
    value: 'vermont_technical_college',
    label: 'Vermont Technical College',
  },
  { key: 'villa_julie_college', value: 'villa_julie_college', label: 'Villa Julie College' },
  { key: 'villanova_university', value: 'villanova_university', label: 'Villanova University' },
  { key: 'virginia_college', value: 'virginia_college', label: 'Virginia College' },
  {
    key: 'virginia_commonwealth_university',
    value: 'virginia_commonwealth_university',
    label: 'Virginia Commonwealth University',
  },
  {
    key: 'virginia_intermont_college',
    value: 'virginia_intermont_college',
    label: 'Virginia Intermont College',
  },
  {
    key: 'virginia_international_university',
    value: 'virginia_international_university',
    label: 'Virginia International University',
  },
  {
    key: 'virginia_military_institute',
    value: 'virginia_military_institute',
    label: 'Virginia Military Institute',
  },
  {
    key: 'virginia_polytechnic_institute_and_state_university_virginia_tech',
    value: 'virginia_polytechnic_institute_and_state_university_virginia_tech',
    label: 'Virginia Polytechnic Institute and State University (Virginia Tech)',
  },
  {
    key: 'virginia_state_university',
    value: 'virginia_state_university',
    label: 'Virginia State University',
  },
  {
    key: 'virginia_union_university',
    value: 'virginia_union_university',
    label: 'Virginia Union University',
  },
  {
    key: 'virginia_wesleyan_college',
    value: 'virginia_wesleyan_college',
    label: 'Virginia Wesleyan College',
  },
  { key: 'viterbo_college', value: 'viterbo_college', label: 'Viterbo College' },
  { key: 'voorhees_college', value: 'voorhees_college', label: 'Voorhees College' },
  { key: 'wabash_college', value: 'wabash_college', label: 'Wabash College' },
  { key: 'wagner_college', value: 'wagner_college', label: 'Wagner College' },
  {
    key: 'wake_forest_university',
    value: 'wake_forest_university',
    label: 'Wake Forest University',
  },
  { key: 'walden_university', value: 'walden_university', label: 'Walden University' },
  { key: 'walla_walla_college', value: 'walla_walla_college', label: 'Walla Walla College' },
  {
    key: 'walsh_college_of_accountancy_and_business_administration',
    value: 'walsh_college_of_accountancy_and_business_administration',
    label: 'Walsh College of Accountancy and Business Administration',
  },
  { key: 'walsh_university', value: 'walsh_university', label: 'Walsh University' },
  {
    key: 'warner_pacific_college',
    value: 'warner_pacific_college',
    label: 'Warner Pacific College',
  },
  {
    key: 'warner_southern_college',
    value: 'warner_southern_college',
    label: 'Warner Southern College',
  },
  { key: 'warren_wilson_college', value: 'warren_wilson_college', label: 'Warren Wilson College' },
  { key: 'wartburg_college', value: 'wartburg_college', label: 'Wartburg College' },
  { key: 'washburn_university', value: 'washburn_university', label: 'Washburn University' },
  {
    key: 'washington_and_lee_university',
    value: 'washington_and_lee_university',
    label: 'Washington and Lee University',
  },
  {
    key: 'washington_bible_college',
    value: 'washington_bible_college',
    label: 'Washington Bible College',
  },
  { key: 'washington_college', value: 'washington_college', label: 'Washington College' },
  {
    key: 'washington_state_university',
    value: 'washington_state_university',
    label: 'Washington State University',
  },
  {
    key: 'washington_state_university_spokane',
    value: 'washington_state_university_spokane',
    label: 'Washington State University, Spokane',
  },
  {
    key: 'washington_state_university_tri_cities',
    value: 'washington_state_university_tri_cities',
    label: 'Washington State University, Tri-Cities',
  },
  {
    key: 'washington_state_university_vancouver',
    value: 'washington_state_university_vancouver',
    label: 'Washington State University, Vancouver',
  },
  {
    key: 'washington_university_in_st_louis',
    value: 'washington_university_in_st_louis',
    label: 'Washington University in St. Louis',
  },
  {
    key: 'wayland_baptist_university',
    value: 'wayland_baptist_university',
    label: 'Wayland Baptist University',
  },
  { key: 'waynesburg_college', value: 'waynesburg_college', label: 'Waynesburg College' },
  { key: 'wayne_state_college', value: 'wayne_state_college', label: 'Wayne State College' },
  {
    key: 'wayne_state_university',
    value: 'wayne_state_university',
    label: 'Wayne State University',
  },
  { key: 'webber_college', value: 'webber_college', label: 'Webber College' },
  { key: 'webb_institute', value: 'webb_institute', label: 'Webb Institute' },
  {
    key: 'weber_state_university',
    value: 'weber_state_university',
    label: 'Weber State University',
  },
  { key: 'webster_university', value: 'webster_university', label: 'Webster University' },
  {
    key: 'webster_university_north_florida',
    value: 'webster_university_north_florida',
    label: 'Webster University North Florida',
  },
  {
    key: 'weill_medical_college_of_cornell_university',
    value: 'weill_medical_college_of_cornell_university',
    label: 'Weill Medical College of Cornell University',
  },
  { key: 'wellesley_college', value: 'wellesley_college', label: 'Wellesley College' },
  { key: 'wells_college', value: 'wells_college', label: 'Wells College' },
  {
    key: 'wentworth_institute_of_technology',
    value: 'wentworth_institute_of_technology',
    label: 'Wentworth Institute of Technology',
  },
  { key: 'wesleyan_college', value: 'wesleyan_college', label: 'Wesleyan College' },
  { key: 'wesleyan_university', value: 'wesleyan_university', label: 'Wesleyan University' },
  { key: 'wesley_college', value: 'wesley_college', label: 'Wesley College' },
  {
    key: 'wesley_college_mississippi',
    value: 'wesley_college_mississippi',
    label: 'Wesley College Mississippi',
  },
  { key: 'westbrook_university', value: 'westbrook_university', label: 'Westbrook University' },
  {
    key: 'west_chester_university_of_pennsylvania',
    value: 'west_chester_university_of_pennsylvania',
    label: 'West Chester University of Pennsylvania',
  },
  { key: 'west_coast_university', value: 'west_coast_university', label: 'West Coast University' },
  {
    key: 'western_baptist_college',
    value: 'western_baptist_college',
    label: 'Western Baptist College',
  },
  { key: 'western_bible_college', value: 'western_bible_college', label: 'Western Bible College' },
  {
    key: 'western_carolina_university',
    value: 'western_carolina_university',
    label: 'Western Carolina University',
  },
  {
    key: 'western_connecticut_state_university',
    value: 'western_connecticut_state_university',
    label: 'Western Connecticut State University',
  },
  {
    key: 'western_governors_university',
    value: 'western_governors_university',
    label: 'Western Governors University',
  },
  {
    key: 'western_illinois_university',
    value: 'western_illinois_university',
    label: 'Western Illinois University',
  },
  {
    key: 'western_international_university',
    value: 'western_international_university',
    label: 'Western International University',
  },
  {
    key: 'western_kentucky_university',
    value: 'western_kentucky_university',
    label: 'Western Kentucky University',
  },
  {
    key: 'western_maryland_college',
    value: 'western_maryland_college',
    label: 'Western Maryland College',
  },
  {
    key: 'western_michigan_university',
    value: 'western_michigan_university',
    label: 'Western Michigan University',
  },
  {
    key: 'western_new_england_college',
    value: 'western_new_england_college',
    label: 'Western New England College',
  },
  {
    key: 'western_new_mexico_university',
    value: 'western_new_mexico_university',
    label: 'Western New Mexico University',
  },
  {
    key: 'western_oregon_university',
    value: 'western_oregon_university',
    label: 'Western Oregon University',
  },
  { key: 'western_state_college', value: 'western_state_college', label: 'Western State College' },
  {
    key: 'western_states_chiropractic_college',
    value: 'western_states_chiropractic_college',
    label: 'Western States Chiropractic College',
  },
  {
    key: 'western_state_university_college_of_law',
    value: 'western_state_university_college_of_law',
    label: 'Western State University College of Law',
  },
  {
    key: 'western_state_university_college_of_law_orange_county',
    value: 'western_state_university_college_of_law_orange_county',
    label: 'Western State University College of Law - Orange County',
  },
  {
    key: 'western_washington_university',
    value: 'western_washington_university',
    label: 'Western Washington University',
  },
  {
    key: 'westfield_state_college',
    value: 'westfield_state_college',
    label: 'Westfield State College',
  },
  {
    key: 'west_liberty_state_college',
    value: 'west_liberty_state_college',
    label: 'West Liberty State College',
  },
  {
    key: 'westminster_college_fulton',
    value: 'westminster_college_fulton',
    label: 'Westminster College Fulton',
  },
  {
    key: 'westminster_college_new_wilmington',
    value: 'westminster_college_new_wilmington',
    label: 'Westminster College New Wilmington',
  },
  {
    key: 'westminster_college_of_salt_lake_city',
    value: 'westminster_college_of_salt_lake_city',
    label: 'Westminster College of Salt Lake City',
  },
  { key: 'westmont_college', value: 'westmont_college', label: 'Westmont College' },
  {
    key: 'west_suburban_college_of_nursing',
    value: 'west_suburban_college_of_nursing',
    label: 'West Suburban College of Nursing',
  },
  {
    key: 'west_texas_a_m_university',
    value: 'west_texas_a_m_university',
    label: 'West Texas A&M University',
  },
  {
    key: 'west_virginia_school_of_osteopathic_medicine',
    value: 'west_virginia_school_of_osteopathic_medicine',
    label: 'West Virginia School of Osteopathic Medicine',
  },
  {
    key: 'west_virginia_state_college',
    value: 'west_virginia_state_college',
    label: 'West Virginia State College',
  },
  {
    key: 'west_virginia_university',
    value: 'west_virginia_university',
    label: 'West Virginia University',
  },
  {
    key: 'west_virginia_university_institute_of_technology',
    value: 'west_virginia_university_institute_of_technology',
    label: 'West Virginia University Institute of Technology',
  },
  {
    key: 'west_virginia_wesleyan_college',
    value: 'west_virginia_wesleyan_college',
    label: 'West Virginia Wesleyan College',
  },
  { key: 'westwood_college', value: 'westwood_college', label: 'Westwood College' },
  {
    key: 'wheaton_college_massachusetts',
    value: 'wheaton_college_massachusetts',
    label: 'Wheaton College Massachusetts',
  },
  {
    key: 'wheeling_jesuit_university',
    value: 'wheeling_jesuit_university',
    label: 'Wheeling Jesuit University',
  },
  { key: 'wheelock_college', value: 'wheelock_college', label: 'Wheelock College' },
  { key: 'whitman_college', value: 'whitman_college', label: 'Whitman College' },
  { key: 'whittier_college', value: 'whittier_college', label: 'Whittier College' },
  { key: 'whitworth_college', value: 'whitworth_college', label: 'Whitworth College' },
  {
    key: 'wichita_state_university',
    value: 'wichita_state_university',
    label: 'Wichita State University',
  },
  { key: 'widener_university', value: 'widener_university', label: 'Widener University' },
  {
    key: 'wilberforce_university',
    value: 'wilberforce_university',
    label: 'Wilberforce University',
  },
  { key: 'wilbur_wright_college', value: 'wilbur_wright_college', label: 'Wilbur Wright College' },
  { key: 'wiley_college', value: 'wiley_college', label: 'Wiley College' },
  { key: 'wilkes_university', value: 'wilkes_university', label: 'Wilkes University' },
  { key: 'willamette_university', value: 'willamette_university', label: 'Willamette University' },
  { key: 'william_carey_college', value: 'william_carey_college', label: 'William Carey College' },
  {
    key: 'william_jewell_college',
    value: 'william_jewell_college',
    label: 'William Jewell College',
  },
  {
    key: 'william_mitchell_college_of_law',
    value: 'william_mitchell_college_of_law',
    label: 'William Mitchell College of Law',
  },
  {
    key: 'william_paterson_university',
    value: 'william_paterson_university',
    label: 'William Paterson University',
  },
  { key: 'william_penn_college', value: 'william_penn_college', label: 'William Penn College' },
  {
    key: 'williams_baptist_college',
    value: 'williams_baptist_college',
    label: 'Williams Baptist College',
  },
  { key: 'williams_college', value: 'williams_college', label: 'Williams College' },
  {
    key: 'william_tyndale_college',
    value: 'william_tyndale_college',
    label: 'William Tyndale College',
  },
  {
    key: 'william_woods_university',
    value: 'william_woods_university',
    label: 'William Woods University',
  },
  { key: 'wilmington_college', value: 'wilmington_college', label: 'Wilmington College' },
  { key: 'wilmington_college', value: 'wilmington_college', label: 'Wilmington College' },
  { key: 'wilson_college', value: 'wilson_college', label: 'Wilson College' },
  { key: 'wingate_university', value: 'wingate_university', label: 'Wingate University' },
  {
    key: 'winona_state_university',
    value: 'winona_state_university',
    label: 'Winona State University',
  },
  {
    key: 'winston_salem_state_university',
    value: 'winston_salem_state_university',
    label: 'Winston-Salem State University',
  },
  { key: 'winthrop_university', value: 'winthrop_university', label: 'Winthrop University' },
  {
    key: 'wisconsin_lutheran_college',
    value: 'wisconsin_lutheran_college',
    label: 'Wisconsin Lutheran College',
  },
  {
    key: 'wisconsin_school_of_professional_psychology',
    value: 'wisconsin_school_of_professional_psychology',
    label: 'Wisconsin School of Professional Psychology',
  },
  { key: 'wittenberg_university', value: 'wittenberg_university', label: 'Wittenberg University' },
  { key: 'wofford_college', value: 'wofford_college', label: 'Wofford College' },
  { key: 'woodbury_university', value: 'woodbury_university', label: 'Woodbury University' },
  {
    key: 'worcester_polytechnic_institute',
    value: 'worcester_polytechnic_institute',
    label: 'Worcester Polytechnic Institute',
  },
  {
    key: 'worcester_state_college',
    value: 'worcester_state_college',
    label: 'Worcester State College',
  },
  { key: 'wright_institute', value: 'wright_institute', label: 'Wright Institute' },
  {
    key: 'wright_state_university',
    value: 'wright_state_university',
    label: 'Wright State University',
  },
  { key: 'xavier_university', value: 'xavier_university', label: 'Xavier University' },
  {
    key: 'xavier_university_of_louisiana',
    value: 'xavier_university_of_louisiana',
    label: 'Xavier University of Louisiana',
  },
  { key: 'yale_university', value: 'yale_university', label: 'Yale University' },
  { key: 'yeshiva_university', value: 'yeshiva_university', label: 'Yeshiva University' },
  { key: 'york_college_nebraska', value: 'york_college_nebraska', label: 'York College Nebraska' },
  {
    key: 'york_college_of_pennsylvania',
    value: 'york_college_of_pennsylvania',
    label: 'York College of Pennsylvania',
  },
  {
    key: 'yorker_international_university',
    value: 'yorker_international_university',
    label: 'Yorker International University',
  },
  { key: 'york_university', value: 'york_university', label: 'York University' },
  {
    key: 'youngstown_state_university',
    value: 'youngstown_state_university',
    label: 'Youngstown State University',
  },
];
