import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      {/* <p className={css.lastUpdated}>Last updated: November 22, 2019</p> */}

      <p>
        By using our website or services, you agree to the terms of this agreement. If you do not
        agree to these terms, you may not use our website or services.
      </p>

      <h2>Services</h2>
      <hr />
      <p>
        We provide a marketplace where users can hire testers to conduct loyalty tests on
        individuals of their choosing. We do not provide the testing services ourselves and are not
        responsible for the actions of the testers.
      </p>
      <hr />

      <h2>Legal Compliance</h2>
      <hr />
      <p>
        You agree to use our website and services only for lawful purposes and in compliance with
        all applicable laws and regulations. You will not use our website or services for any
        illegal or unethical purpose, including but not limited to deceiving or defrauding others.
      </p>
      <hr />
      <h2>Privacy</h2>
      <hr />
      <p>
        We respect the privacy of our users and test subjects. You agree to our Privacy Policy,
        which can be found on our website.
      </p>
      <hr />
      <h2>User Responsibility</h2>
      <hr />
      <p>
        You are responsible for any legal or financial repercussions that may arise from the use of
        our services. We will cooperate with law enforcement agencies as required by law.
      </p>
      <hr />
      <h2>Refund and Cancellation Policy</h2>
      <hr />
      <p>
        You may cancel the service before the tester accepts your purchase. You can request a full refund
        if your tester does not do what is outlined in their package. Please note if your significant other
        does not respond it is considered a successful test!
      </p>
      <hr />
      <h2>Modification of Terms</h2>
      <hr />
      <p>
        We reserve the right to modify these terms of service at any time. Your continued use of our
        website or services after any changes are made constitutes your acceptance of the new terms.
      </p>
      <hr />
      <h2>Contact Information</h2>
      <hr />
      <p>
        If you have any questions or concerns about these terms of service, please contact us at {' '}
        <a href="mailto:contact@loyalty-test.com">contact@loyalty-test.com</a>
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
